<template>
<div class="row rounded-lg  " style="font-size:9px;" :class="active? variantBorder+' bg-white ' : 'border-light bg-light'" v-on:mouseover="active = true" v-on:mouseleave="active = false" @click="item.ocorrencia_id? $emit('handleSelectItem', item): ''" >
    <div class="col-md-1 ">
        <p class="mr-1" :class="variantIcon" v-if="item.ocorrencia_id" >
            <i class="fa fa-eye" ></i>
        </p>


    </div>
    <div class="col-md-3 pt-1 pl-2" v-b-tooltip.hover :title="$t('Description')">
        <type-image :especialidade="item.especialidade_veiculo_sigla" :gps="item.veiculo_situacao == 'A'? true:false" />
        <p v-text="item.especialidade_veiculo_sigla"></p>
    </div>
    <div class="col-md-5 text-center mt-2">
        <div class="badge bg-warning  text-wrap mb-1" v-if="!item.ocorrencia_id">Sem Ocorrência </div>
        <div class="badge bg-primary  text-wrap mb-1" v-else="item.ocorrencia_id ">
            <a @click="openSearch()">
                Ocor: {{item.ocorrencia_id}}
            </a> </div>
        <div v-text="item.veiculo_numero+ ' / '+item.veiculo_placa"></div>
        <div v-text="item.unidade_nome"></div>
        <div v-text="item.especialidade_veiculo_nome"></div>
    </div>

    <div class="col-md-2 " v-if="item.ocorrencia_id">

        <time-ball :variant="variant" :title="title" :timeText="timeText" />

    </div>
</div>
</template>

<script>
import TimeBall from './TimeBall';
import LineJoin from './TimeLineJoin';
import TypeImage from './TypeImage';



export default {
    components: {
        TimeBall,
        LineJoin,
        TypeImage,
        
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            active: false,
            variant: 'bg-success',
            variantIcon: 'text-success',
            title: 'Retorno',
            timeText: '',
            variantBorder: 'border-success',
        }
    },
    mounted: function () {
        this.showStatus()
    },
    methods: {
        showStatus() {

            if (this.item.extretornodestinodt) {
                // 'Retorno':
                this.title = 'Retorno';
                this.variant = 'bg-success';
                this.variantIcon = 'text-success';
                this.timeText = this.item.extretornodestinodt;
                this.variantBorder = 'border-success';
                return;
            }

            if (this.item.extchegadadestinodt) {
                // 'Destino':

                this.title = 'Destino';
                this.variant = 'bg-warning';
                this.variantIcon = 'text-warning';
                this.timeText = this.item.extchegadadestinodt;
                this.variantBorder = 'border-warning';

                return;
            }
            if (this.item.extchegadadestinodt) {
                // 'Saída Ocor.':
                this.title = 'Saída Ocor.';
                this.variant = 'bg-warning';
                this.variantIcon = 'text-warning';
                this.timeText = this.item.extsaidalocaldt;
                this.variantBorder = 'border-warning';

                return;
            }
            if (this.item.extchegadalocaldt) {
                // 'Ocorrência':
                this.title = 'Ocorrência';
                this.variant = 'bg-warning';
                this.variantIcon = 'text-warning';
                this.timeText = this.item.extchegadalocaldt;
                this.variantBorder = 'border-warning';

                return;
            }

            if (this.item.extsaidabasedt) {
                // 'Cam. Ocor.':
                this.title = 'Cam. Ocor.';
                this.variant = 'bg-warning';
                this.variantIcon = 'text-warning';
                this.timeText = this.item.extsaidabasedt;
                this.variantBorder = 'border-warning';

                return;
            }
            if (this.item.extenvioequipedt) {

                // 'Empenhado':
                this.title = 'Empenhado';
                this.variant = 'bg-warning';
                this.timeText = this.item.extenvioequipedt;
                this.variantBorder = 'border-warning';
                return;
            }

            // 'Base':

            this.title = 'Base';
            this.variant = 'bg-success';
            this.variantIcon = 'text-success';
            this.timeText = this.item.extchegadabasedt;
            this.variantBorder = 'border-success';

        },
        openSearch() {
            //ate fazer tela de rastearmento
            window.open("http://localhost:8888/operational_panel");
        }

    }
}
</script>

<style scoped>
/* Estilos específicos do componente */
</style>
