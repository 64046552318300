<template>
    <div>
      <div class="main-content">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <ValidationObserver ref="formObserver" v-slot="{ invalid, validate }">
                      <b-form @submit.prevent="handleSubmit(validate)">
                        <div class="form-row">
                          <div class="form-group col-md-4">
                            <ValidationProvider name="Vehicle" rules="required" v-slot="{ errors }">
                                <label for="vehicles">
                                  <span class="required">*</span> {{ $t('Vehicle') }} :
                                </label>
                                <v-select :options="vehicles" :reduce="(vehicle) => vehicle.id" :label="'name'" v-model="auto_insurance.vehicle_id" class="vselect" 
                                  :class="{ 'is-invalid': errors.length }"
                                  />
                                  <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-4">
                            <ValidationProvider name="Policy" rules="required" v-slot="{ errors }">
                                <label for="policy">
                                  <span class="required">*</span> {{ $t('Policy') }} :
                                </label>
                                
                                <b-form-input type="text" id="policy" v-model="auto_insurance.policy" :state="errors.length ? false : null" />
                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-4">
                            <ValidationProvider name="CI" rules="required" v-slot="{ errors }">
                                <label for="ci">
                                  <span class="required">*</span> {{ $t('Identification Code') }} :
                                </label>
                                <b-form-input type="text" id="ci" v-model="auto_insurance.ci" :state="errors.length ? false : null" />
                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-3">
                            <ValidationProvider name="Value" rules="required" v-slot="{ errors }">
                                <label for="value">
                                  <span class="required">*</span> {{ $t('Value') }} :
                                </label>
                                <b-form-input type="text" id="value" v-model="auto_insurance.value" :state="errors.length ? false : null" />
                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-3">
                            <ValidationProvider name="Hiring date" rules="required" v-slot="{ errors }">
                                <label for="hiring_date">
                                  <span class="required">*</span> {{ $t('Hiring date') }} :
                                </label>
                                <b-form-input type="date" id="hiring_date" v-model="auto_insurance.hiring_date" :state="errors.length ? false : null" />
                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-3">
                            <ValidationProvider name="Validity" rules="required" v-slot="{ errors }">
                                <label for="validity">
                                  <span class="required">*</span> {{ $t('Validity2') }} :
                                </label>
                                <b-form-input type="date" id="validity" v-model="auto_insurance.validity" :state="errors.length ? false : null" />
                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-3">
                            <ValidationProvider name="Insurer" rules="required" v-slot="{ errors }">
                                <label for="insurances">
                                  <span class="required">*</span> {{ $t('Insurer') }} :
                                </label>
                                <v-select :options="insurances" :reduce="(insurance) => insurance.id" :label="'name'" v-model="auto_insurance.insurance_id" class="vselect" 
                                  :class="{ 'is-invalid': errors.length }"
                                  />
                                  <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-12">
                            <ValidationProvider name="Vehicle document"  v-slot="{ errors }">
                                <label for="vehicle_doc">
                                  <span class="required">*</span> {{ $t('Vehicle document') }} :
                                </label>
                                <upload-file :fileId="auto_insurance.vehicle_doc" @uploaded="(event) => { auto_insurance.vehicle_doc = event }" :class="{ 'is-invalid': errors.length }" />
                                  <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-12">
                            <ValidationProvider name="Policy File"  v-slot="{ errors }">
                                <label for="policy_doc">
                                  <span class="required">*</span> {{ $t('Policy File') }} :
                              </label>
                                
                                <upload-file :fileId="auto_insurance.policy_doc" @uploaded="(event) => { auto_insurance.policy_doc = event }" :class="{ 'is-invalid': errors.length }" />
                                  <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-12">
                            <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                                <label for="description">
                                  <span class="required">*</span> {{ $t('Description') }} :
                                </label>
                                
                                <b-form-textarea id="description" v-model="auto_insurance.description" rows="3" :state="errors.length ? false : null"></b-form-textarea>
                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </div>
                            <div class="form-group col-md-6">
                              <ValidationProvider name="Requester" rules="required" v-slot="{ errors }">
                                <label for="requester">
                                  <span class="required">*</span> {{ $t('Requester') }} :
                                </label>
                                
                                <v-select :options="professionals" :reduce="(professional) => professional.id" :label="'name'" v-model="auto_insurance.requester_id" class="vselect" 
                                  :class="{ 'is-invalid': errors.length }" />
                                  <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                </ValidationProvider>
                              </div>
                              <div class="form-group col-md-6">
                                <ValidationProvider name="Approver" rules="required" v-slot="{ errors }">
                                  <label for="approver">
                                    <span class="required">*</span> {{ $t('Approver') }} :
                                  </label>
                                  <v-select :options="professionals" :reduce="(professional) => professional.id" :label="'name'" v-model="auto_insurance.approver_id" class="vselect" 
                                    :class="{ 'is-invalid': errors.length }" />
                                    <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                  </ValidationProvider>
                                </div>
                                <div class="form-group col-md-6">
                                  <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                                    <label for="status">
                                      <span class="required">*</span> {{ $t('Status') }} :
                                    </label>
                                
                                <b-form-select v-model="auto_insurance.status" :options="[{ value: 1, text: $t('Active') }, { value: 0, text: $t('Inactive') }]" :state="errors.length ? false : null" />
                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                        </div>
                        <span class="required_fields">
                          <span class="required">*</span>
                          <strong> {{ $t('Required Fields') }}</strong>
                        </span>
                        <div class="block text-right">
                          <router-link to="/auto_insurances" class="btn btn-back">
                            <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                          </router-link>
                          <b-button type="submit" variant="primary" class="ml-1">
                            {{ $t('Save') }}
                            <i class="fa fa-save"></i>
                          </b-button>
                        </div>
                      </b-form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  import { BButton, BForm, BFormInput, BFormTextarea, BFormGroup, BFormInvalidFeedback, BFormSelect } from "bootstrap-vue";
  import UploadFile from "../../layouts/components/UploadFile";
  import vSelect from "vue-select";
  
  extend("required", required);
  
  export default {
    data() {
      return {
        auto_insurance: {
          status: "1",
          vehicle_id: '',
          policy: '',
          ci: '',
          value: '',
          hiring_date: '',
          validity: '',
          insurance_id: '',
          requester_id: '',
          approver_id: '',
        },
        professionals: [],
        vehicles: [],
        insurances: [],
        errors: undefined,
        previewImage: null,
      };
    },
    methods: {
      async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
      save() {
        let api = this.$store.state.api + "auto_insurances";
        if (this.auto_insurance.id) {
          this.auto_insurance._method = "PUT";
          api += "/" + this.auto_insurance.id;
        }
  
        axios
          .post(api, this.auto_insurance)
          .then(() => {
            this.$message(
              this.$t('Success'),
              this.$t('Information saved successfully'),
              "success"
            );
            this.$router.push("/auto_insurances");
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getAutoInsurance(id) {
        let api = this.$store.state.api + "auto_insurances/" + id;
        axios
          .get(api)
          .then((response) => {
            this.auto_insurance = response.data.data[0];
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getProfessionals() {
        let api = this.$store.state.api + "professionals/";
        axios
          .get(api)
          .then((response) => {
            this.professionals = response.data.data;
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getVehicles() {
        let api = this.$store.state.api + "vehicles/";
        axios
          .get(api)
          .then((response) => {
            this.vehicles = response.data.data;
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getInsurances() {
        let api = this.$store.state.api + "insurances/";
        axios
          .get(api)
          .then((response) => {
            this.insurances = response.data.data;
          })
          .catch((error) => {
            this.$error(error);
          });
      },
    },
    mounted() {
      const id = this.$route.params.id;
      this.getProfessionals();
      this.getVehicles();
      this.getInsurances();
      if (id) {
        this.getAutoInsurance(id);
      }
    },
    components: {
      ValidationObserver,
      ValidationProvider,
      BButton,
      BForm,
      BFormInput,
      BFormTextarea,
      BFormGroup,
      BFormInvalidFeedback,
      BFormSelect,
      vSelect,
      UploadFile,
    },
  };
  </script>
  
  <style scoped>
  .required {
    color: red !important;
  }
  </style>
  