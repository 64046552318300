var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationObserver',{ref:"formObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validate = ref.validate;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(validate)}}},[_c('div',{staticClass:"form-row"},[(!_vm.companyId)?_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"company"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Company'))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"id":"company","options":_vm.companies,"reduce":function (company) { return company.id; },"label":'name'},on:{"input":_vm.changeCenterCompany},model:{value:(_vm.unit.company_id),callback:function ($$v) {_vm.$set(_vm.unit, "company_id", $$v)},expression:"unit.company_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Central","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"center"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Central'))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"id":"center","options":_vm.centers,"reduce":function (centers) { return centers.id; },"label":'name'},model:{value:(_vm.unit.center_id),callback:function ($$v) {_vm.$set(_vm.unit, "center_id", $$v)},expression:"unit.center_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Name'))+": ")]),_c('b-form-input',{attrs:{"id":"name","type":"text","autocomplete":"new-password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.unit.name),callback:function ($$v) {_vm.$set(_vm.unit, "name", $$v)},expression:"unit.name"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Contact","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"contact"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Name the person responsible'))+": ")]),_c('b-form-input',{attrs:{"id":"contact","type":"text","autocomplete":"new-password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.unit.contact),callback:function ($$v) {_vm.$set(_vm.unit, "contact", $$v)},expression:"unit.contact"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"phone"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Telephonee'))+": ")]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(99) 99999-9999','(99) 9999-9999']),expression:"['(99) 99999-9999','(99) 9999-9999']"}],attrs:{"id":"phone","type":"text","autocomplete":"new-password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.unit.phone),callback:function ($$v) {_vm.$set(_vm.unit, "phone", $$v)},expression:"unit.phone"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"email"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Email'))+": ")]),_c('b-form-input',{attrs:{"id":"email","type":"email","autocomplete":"new-password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.unit.email),callback:function ($$v) {_vm.$set(_vm.unit, "email", $$v)},expression:"unit.email"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-8"},[_c('ValidationProvider',{attrs:{"name":"Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"address"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Address'))+": ")]),_c('AddressAutocomplete',{class:{ 'is-invalid': errors.length },attrs:{"autocompleteValueProp":_vm.unit.address},on:{"placeSelected":_vm.onPlaceSelected,"placeChange":function (event){_vm.unit.address = event}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"status"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Status'))+": ")]),_c('b-form-select',{attrs:{"id":"status","state":errors.length ? false : null},model:{value:(_vm.unit.status),callback:function ($$v) {_vm.$set(_vm.unit, "status", $$v)},expression:"unit.status"}},[_c('option',{attrs:{"value":"1"}},[_vm._v(" "+_vm._s(_vm.$t('Active'))+" ")]),_c('option',{attrs:{"value":"0"}},[_vm._v(" "+_vm._s(_vm.$t('Inactive'))+" ")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)]),_c('span',{staticClass:"required_fields"},[_c('span',{staticClass:"required"},[_vm._v("*")]),_c('strong',[_vm._v(_vm._s(_vm.$t('Required Fields')))])]),_c('div',{staticClass:"block text-right"},[_c('router-link',{staticClass:"btn btn-back",attrs:{"to":"/units"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t('To Go Back'))+" ")]),_c('b-button',{staticClass:"ml-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" "),_c('i',{staticClass:"fa fa-save"})])],1)])]}}])})],1)])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }