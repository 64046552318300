import Crud from '../views/ProfileType/Crud'
import Entity from '../views/ProfileType/Add'
import PREMISSIONS from '../config/premissions'

let routes = {
    path: '/profile_types',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/profile_types/add',
            name: 'NewProfileType',
            component: Entity,
            access_level: PREMISSIONS.MASTER,
            meta: {
                pageTitle: 'Profile Types',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/profile_types'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/profile_types/edit/:id?',
            name: 'EditProfileType',
            component: Entity,
            meta: {
                pageTitle: 'Profile Type',
                access_level: PREMISSIONS.MASTER,
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/profile_types'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/profile_types',
            name: 'list-profile_types',
            component: Crud,
            meta: {
                pageTitle: 'Profile Types',
                access_level: PREMISSIONS.MASTER,
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
