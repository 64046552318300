

import Crud from '../views/Operators/Crud'
import Entity from '../views/Operators/Add'

const routes = {
    path: '/operators',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/operators/add',
            name: 'NewOperators',
            component: Entity,
            meta: {
                pageTitle: 'Operators',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/operators'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/operators/edit/:id?',
            name: 'EditOperators',
            component: Entity,
            meta: {
                pageTitle: 'Operators',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to: '/operators',
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/operators',
            name: 'list-Operators',
            component: Crud,
            meta: {
                pageTitle: 'Operators',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
