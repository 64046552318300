<template>
    <div>
      <div class="main-content">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <ValidationObserver ref="formObserver" v-slot="{ invalid, validate }">
                      <b-form @submit.prevent="handleSubmit(validate)">
                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                                <label for="Name">
                                <span class="required">*</span> {{ $t('Name') }}:
                              </label>
                                <b-form-input
                                  id="Name"
                                  type="text"
                                  v-model="company.name"
                                  autocomplete="new-password"
                                  :state="errors.length ? false : null"
                                  placeholder=""
                                />
                                <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-6">
                            <ValidationProvider name="Fantasy Name" rules="required" v-slot="{ errors }">
                                <label for="FantasyName">
                                <span class="required">*</span> {{ $t('Fantasy name') }}:
                              </label>
                                <b-form-input
                                  id="FantasyName"
                                  type="text"
                                  v-model="company.fantasy_name"
                                  autocomplete="new-password"
                                  :state="errors.length ? false : null"
                                  placeholder=""
                                />
                                <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                              
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-6">
                            <ValidationProvider name="CNPJ" rules="required" v-slot="{ errors }">
                                <label for="cnpj">
                                <span class="required">*</span> {{ $t('National Register of Legal Entities(CNPJ)') }}:
                              </label>
                                <b-form-input
                                  id="cnpj"
                                  type="text"
                                  v-mask="'##.###.###/####-##'"
                                  v-model="company.cnpj"
                                  autocomplete="new-password"
                                  :state="errors.length ? false : null"
                                  placeholder=""
                                />
                                <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-6">
                            <ValidationProvider name="Contact" rules="required" v-slot="{ errors }">
                                <label for="contact">
                                <span class="required">*</span> {{ $t('Name the person responsible') }}:
                              </label>
                                <b-form-input
                                  id="contact"
                                  type="text"
                                  v-model="company.contact"
                                  autocomplete="new-password"
                                  :state="errors.length ? false : null"
                                  placeholder=""
                                />
                                <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-6">
                            <ValidationProvider name="Phone" rules="required" v-slot="{ errors }">
                                <label for="phone">
                                <span class="required">*</span> {{ $t('Telephone') }}:
                              </label>
                                <b-form-input
                                  id="phone"
                                  type="text"
                                  v-mask="['(99) 99999-9999','(99) 9999-9999']"
                                  v-model="company.phone"
                                  autocomplete="new-password"
                                  :state="errors.length ? false : null"
                                  placeholder=""
                                />
                                <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                             
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-6">
                            <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                                <label for="email">
                                <span class="required">*</span> {{ $t('Email') }}:
                              </label>
                                <b-form-input
                                  id="email"
                                  type="email"
                                  v-model="company.email"
                                  autocomplete="new-password"
                                  :state="errors.length ? false : null"
                                  placeholder=""
                                />
                                <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                              
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-12">
                            <ValidationProvider name="Address"  v-slot="{ errors }">
                              
                                <label for="Address">
                                <span class="required">*</span> {{ $t('Address') }}:
                              </label>
                                <AddressAutocomplete
                                  :autocompleteValueProp="company.address"
                                  @placeSelected="onPlaceSelected"
                                  @placeChange="(event)=>{company.address = event}"
                                  :class="{ 'is-invalid': errors.length }" />
                                  <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                              
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-2">
                            <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                                <label for="status">
                                <span class="required">*</span> {{ $t('Status') }}:
                              </label>
                                <b-form-select
                                  id="status"
                                  v-model="company.status"
                                  :state="errors.length ? false : null"
                                >
                                  <option :value="1"> {{ $t('Active') }} </option>
                                  <option :value="0"> {{ $t('Inactive') }} </option>
                                </b-form-select>
                                <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                              
                            </ValidationProvider>
                          </div>
                        </div>
                        <span class="required_fields">
                          <span class="required">*</span>
                          <strong>{{ $t('Required Fields') }}</strong>
                        </span>
                        <div class="block text-right">
                          <router-link to="/companies" class="btn btn-back">
                            <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                          </router-link>
                          <b-button type="submit" variant="primary" class="ml-1">
                            {{ $t('Save') }}
                            <i class="fa fa-save"></i>
                          </b-button>
                        </div>
                      </b-form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import BaseCrud from "../../layouts/Base/BaseCrud";
  import vSelect from "vue-select";
  import AddressAutocomplete from "../../layouts/Mapa/AddressAutocomplete";
  import AddToList from "../../layouts/components/AddToList";
  import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";
  import { BButton, BForm, BFormInput, BFormGroup, BFormSelect, BFormInvalidFeedback } from "bootstrap-vue";
  
  // Add the rules to vee-validate
  extend("required", required);
  extend("email", email);
  
  export default {
    name: "companyForm",
    components: {
      BaseCrud,
      vSelect,
      AddressAutocomplete,
      AddToList,
      ValidationObserver,
      ValidationProvider,
      BButton,
      BForm,
      BFormInput,
      BFormGroup,
      BFormSelect,
      BFormInvalidFeedback,
    },
    data() {
      return {
        company: {
          name: '',
          fantasy_name: '',
          cnpj: '',
          state_id: '',
          neighborhood_id: '',
          municipality_id: '',
          address_geometry: '',
          address: '',
          phone: '',
          contact: '',
          email: '',
          status: 1,
        },
        errors: undefined,
        previewImage: null,
      };
    },
    methods: {
      async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
      onPlaceChanged(event) {
        this.company.address = event
        this.company.state_id = ''
        this.company.address_geometry = ''
        this.company.municipality_id = ''
        this.company.neighborhood_id = ''
      },
      onPlaceSelected(place) {
        this.company.address = place.address
        this.company.address_geometry = JSON.stringify(place.address_geometry)
        this.company.state_id = place.municipality.state_id
        this.company.municipality_id = place.neighborhood.municipality_id
        this.company.neighborhood_id = place.neighborhood.id
      },
      save() {
        if (!this.company.address || this.company.address === '') {
          this.onPlaceChanged('')
        }
        let api = this.$store.state.api + "companies";
  
        if (this.company.id) {
          this.company._method = "PUT";
          api += "/" + this.company.id;
        }
  
        axios
          .post(api, this.company)
          .then((response) => {
            if (!this.company.id && response.data && response.data.id) {
              this.company = response.data
            }
            this.$message(
              this.$t('Success'),
              this.$t('Information saved successfully'),
              "success"
            );
            this.$router.push("/companies");
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getCompany(id) {
        let api = this.$store.state.api + "companies/" + id;
  
        axios
          .get(api)
          .then((response) => {
            this.company = response.data.data[0];
          })
          .catch((error) => {
            this.$error(error);
          });
      },
    },
    mounted() {
      const id = this.$route.params.id;
      if (id) {
        this.getCompany(id);
      }
    },
  };
  </script>
  
  <style scoped>
  .profile_user {
    text-align: company;
  }
  
  .photo {
    display: block;
    margin-left: 5px;
    width: 18%;
    border-radius: 10px;
    max-width: 400px;
    max-height: 400px;
    object-fit: cover;
  }
  
  .btn-user:hover,
  .btn-user[aria-expanded="true"] {
    background: linear-gradient(to right, #000, #666);
    color: #fff !important;
  }
  
  .photo-title {
    color: #000;
    font-size: 18px;
    display: block;
    margin-left: 5px;
    width: 18%;
  }
  </style>
  