<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <ValidationObserver ref="formObserver" v-slot="{ invalid, validate }">
                    <b-form @submit.prevent="handleSubmit(validate)">
                      <div class="form-row">
                        <div class="form-group col-md-4">
                          <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                            <label for="Name">
                              <span class="required">*</span> {{ $t('Name') }}:
                            </label>
                            <b-form-input
                              id="Name"
                              type="text"
                              v-model="area.name"
                              autocomplete="new-password"
                              :state="errors.length ? false : null"
                              placeholder=""
                            />
                            <b-form-invalid-feedback :state="errors.length">
                              {{ $t(errors[0]) }}
                            </b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>

                        <div class="form-group col-md-4">
                          <ValidationProvider name="Area Category" rules="required" v-slot="{ errors }">
                            <label for="areaCatagory">
                              <span class="required">*</span> {{ $t('Area Catagory') }}:
                            </label>
                            <v-select
                              :options="areaCatagories"
                              :reduce="(areaCatagory) => areaCatagory.id"
                              :label="'name'"
                              v-model="area.area_catagory_id"
                              class="vselect"
                              v-on:input="getUrlImage"
                              :class="{ 'is-invalid': errors.length }"
                            />
                            <span class="invalid-feedback">
                              {{ $t(errors[0]) }}
                            </span>
                          </ValidationProvider>
                        </div>

                        <div class="form-group col-md-4">
                          <ValidationProvider name="Area Type" rules="required" v-slot="{ errors }">
                            <label for="areaType">
                              <span class="required">*</span> {{ $t('Area Type') }}:
                            </label>
                            <v-select
                              :options="areaTypes"
                              :reduce="(areaType) => areaType.id"
                              :label="'name'"
                              v-model="area.area_type_id"
                              class="vselect"
                              :class="{ 'is-invalid': errors.length }"
                            />
                            <span class="invalid-feedback">
                              {{ $t(errors[0]) }}
                            </span>
                          </ValidationProvider>
                        </div>

                        <div class="form-group col-md-8">
                          <ValidationProvider name="Address" v-slot="{ errors }">
                            <label for="address">
                              <span class="required">*</span> {{ $t('Address') }}:
                            </label>
                            <AddressAutocomplete
                              :autocompleteValueProp="area.address"
                              @placeSelected="onPlaceSelected"
                              @placeChange="(event) => { area.address = event }"
                              :class="{ 'is-invalid': errors.length }"
                            />
                            <span class="invalid-feedback">
                              {{ $t(errors[0]) }}
                            </span>
                          </ValidationProvider>
                        </div>

                        <div class="form-group col-md-4" v-if="area.area_type_id == 1">
                          <ValidationProvider name="Ray" rules="required|integer" v-slot="{ errors }">
                            <b-form-group label-for="ray" :label="$t('Ray') + ' ' + $t('KM')">
                              <b-form-input
                                id="ray"
                                type="number"
                                v-model="area.ray"
                                autocomplete="new-password"
                                :state="errors.length ? false : null"
                                placeholder=""
                              />
                              <b-form-invalid-feedback :state="errors.length">
                                {{ $t(errors[0]) }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-12" v-if="area.address">
                          <MapPositionPoint
                            :centerProp="center"
                            :ray="circleRadius"
                            :zoom="zoomMapa"
                            :url="imgUrl"
                            :areaTypeId="areaTypeId"
                            :polygonCoordinates="polygonCoordinates"
                            @polygonCoordinatesChange="polygonCoordinatesChange"
                            @zoomChanged="(event) => { area.zoom = event }"
                            @reload="init"
                          />
                        </div>
                      </div>

                      <span class="required_fields">
                        <span class="required">*</span>
                        <strong>{{ $t('Required Fields') }}</strong>
                      </span>

                      <div class="block text-right">
                        <router-link to="/areas" class="btn btn-back">
                          <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                        </router-link>
                        <b-button type="submit" variant="primary" class="ml-1">
                          {{ $t('Save') }} <i class="fa fa-save"></i>
                        </b-button>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import AddressAutocomplete from "../../layouts/Mapa/AddressAutocomplete";
import MapPositionPoint from "../../layouts/Mapa/MapPositionPoint";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, integer } from "vee-validate/dist/rules";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BFormInvalidFeedback
} from "bootstrap-vue";

extend("required", required);
extend("integer", integer);

export default {
  data() {
    return {
      area: {
        name: "",
        area_catagory_id: "",
        area_type_id: "",
        address: "",
        address_geom: "",
        ray: 5,
        area_geom: "",
        polygon_coordinates: "",
        zoom: 13
      },
      areaTypes: [],
      areaCatagories: [],
      uploadFile: {},
      addressGeometry: { lat: 0, lng: 0 },
      errors: undefined,
      previewImage: null
    };
  },
  computed: {
    zoomMapa() {
      return Number(this.area.zoom) ?? 13;
    },
    imgUrl() {
      return this.uploadFile.url ?? "";
    },
    center() {
      return this.addressGeometry;
    },
    polygonCoordinates() {
      return this.area.polygon_coordinates && this.area.polygon_coordinates !== ""
        ? JSON.parse(this.area.polygon_coordinates)
        : [];
    },
    circleRadius() {
      return Number(this.area.ray) ?? 5;
    },
    areaTypeId() {
      return this.area.area_type_id;
    }
  },
  methods: {
    async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
    polygonCoordinatesChange(event) {
      this.area.polygon_coordinates = event
        ? JSON.stringify(event)
        : "";
    },
    getUrlImage(event) {
      const imageId = this.areaCatagories.find(
        (catagory) => catagory.id === event
      )?.image;
      if (imageId) {
        this.getFile(imageId);
      }
    },
    onPlaceSelected(place) {
      this.area.address = place.address;
      this.area.address_geom = JSON.stringify(place.address_geometry);
      this.area.area_geom = JSON.stringify(place.address_geometry);
      this.addressGeometry = place.address_geometry;
    },
    save() {
      let api = this.$store.state.api + "areas";
      if (this.area.id) {
        this.area._method = "PUT";
        api += "/" + this.area.id;
      }
      axios
        .post(api, this.area)
        .then(() => {
          this.$message(
            this.$t("Success"),
            this.$t("Information saved successfully"),
            "success"
          );
          this.$router.push("/areas");
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getFile(id) {
      let api = this.$store.state.api + "uploadfiles/" + id;
      axios
        .get(api)
        .then((response) => {
          this.uploadFile = response.data.data[0];
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getArea(id) {
      let api = this.$store.state.api + "areas/" + id;
      axios
        .get(api)
        .then((response) => {
          this.area = response.data.data[0];
          this.addressGeometry = JSON.parse(this.area.area_geom);
          this.getUrlImage(this.area.area_catagory_id);
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getAreaTypes() {
      let api = this.$store.state.api + "areatypes";
      axios
        .get(api)
        .then((response) => {
          this.areaTypes = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getAreaCatagories() {
      let api = this.$store.state.api + "areacatagories";
      axios
        .get(api)
        .then((response) => {
          this.areaCatagories = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    init() {
      const id = this.$route.params.id;
      if (id) {
        this.getArea(id);
      }
    }
  },
  mounted() {
    this.getAreaCatagories();
    this.getAreaTypes();
    this.init();
  },
  components: {
    vSelect,
    AddressAutocomplete,
    MapPositionPoint,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback
  }
};
</script>

<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>
