<template>
    <div class="main-content">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <ValidationObserver ref="formObserver" v-slot="{ validate }">
                <b-form @submit.prevent="handleSubmit(validate)">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <ValidationProvider name="Central" rules="required" v-slot="{ errors }">
                          <label for="Central">
                                    <span class="required">*</span> {{ $t('Central') }}:
                                </label>
                          <v-select
                            :options="centers"
                            :reduce="(center) => center.id"
                            :label="'name'"
                            v-model="device.center_id"
                            class="vselect"
                            @input="changetUnitsCenter"
                            :class="{ 'is-invalid': errors.length }"
                        />
                        <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-6">
                      <ValidationProvider name="Unit" rules="required" v-slot="{ errors }">
                        <label for="Unit">
                                    <span class="required">*</span> {{ $t('Unit') }}:
                                </label>
                          <v-select
                            :options="filteredDeviceUnits"
                            :reduce="(unit) => unit.id"
                            :label="'name'"
                            v-model="device.unit_id"
                            class="vselect"
                            :class="{ 'is-invalid': errors.length }"
                        />
                        <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-6">
                      <ValidationProvider name="Supplier" rules="required" v-slot="{ errors }">
                          <label for="Supplier">
                                <span class="required">*</span> {{ $t('Supplier') }}:
                            </label>
                          <v-select
                            :options="suppliers"
                            :reduce="(supplier) => supplier.id"
                            :label="'name'"
                            v-model="device.supplier_id"
                            class="vselect"
                            :class="{ 'is-invalid': errors.length }"
                        />
                        <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-6">
                    <ValidationProvider name="Device Model" rules="required" v-slot="{ errors }">
                        <label for="Devices-Model">
                            <span class="required">*</span> {{ $t('Devices Model') }}:
                        </label>
                        <v-select
                          :options="filteredDeviceModels"
                          :reduce="(deviceModel) => deviceModel.id"
                          :label="'name'"
                          v-model="device.device_model_id"
                          class="vselect"
                          :class="{ 'is-invalid': errors.length }"
                        />
                        <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                    </ValidationProvider>
                  </div>
                    <div class="form-group input-group-sm col-md-6">
                      <ValidationProvider name="IMEI" rules="required" v-slot="{ errors }">
                          <label for="operator">
                                <span class="required">*</span> {{ $t('IMEI') }}:
                            </label>
                          <b-form-input
                            type="text"
                            id="imei"
                            v-model="device.imei"
                            :state="errors.length ? false : null"
                            placeholder=""
                          />
                          <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="Chip">
                           {{ $t('Chip') }}:
                          </label>
                        <v-select
                          :options="chips"
                          :reduce="(chip) => chip.id"
                          :label="'iccid'"
                          v-model="device.chip_id"
                          class="vselect"
                        />
                        
                    </div>
                    <div class="form-group input-group-sm col-md-4">
                      <ValidationProvider name="Firmware" rules="required" v-slot="{ errors }">
                          <label for="Firmware">
                                    <span class="required">*</span> {{ $t('Firmware') }}:
                                </label>
                          <b-form-input
                            type="text"
                            id="firmware"
                            v-model="device.firmware"
                            :state="errors.length ? false : null"
                            placeholder=""
                          />
                          <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>
                    <div class="form-group input-group-sm col-md-4">
                      <ValidationProvider name="Serial Number" rules="required" v-slot="{ errors }">
                          <label for="SerialNumber">
                              <span class="required">*</span> {{ $t('Serial Number') }}:
                          </label>
                          <b-form-input
                            type="text"
                            id="serial_number"
                            v-model="device.serial_number"
                            :state="errors.length ? false : null"
                            placeholder=""
                          />
                          <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>
                    <div class="form-group input-group-sm col-md-4">
                      <ValidationProvider name="Acquisition Date" rules="required|date_format" v-slot="{ errors }">
                          <label for="Acquisition">
                                    <span class="required">*</span> {{ $t('acquisition date') }}:
                                </label>
                          <b-form-input
                            type="date"
                            id="acquisition_date"
                            v-model="device.acquisition_date"
                            :state="errors.length ? false : null"
                            placeholder=""
                          />
                          <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>
                    <div class="form-group input-group-sm col-md-12">
                      <b-form-group :label="$t('Description')">
                        <b-form-input
                          type="text"
                          id="description"
                          v-model="device.description"
                          placeholder=""
                        />
                      </b-form-group>
                    </div>
                  </div>
                  <span class="required_fields">
                    <span class="required">*</span>
                    <strong> {{ $t('Required Fields') }}</strong>
                  </span>
                  <div class="block text-right">
                    <router-link to="/devices" class="btn btn-back">
                      <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                    </router-link>
                    <b-button type="submit" variant="primary" class="ml-1">
                      {{ $t('Save') }}
                      <i class="fa fa-save"></i>
                    </b-button>
                  </div>
                </b-form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
  import { required, regex } from 'vee-validate/dist/rules';
  import { BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton } from 'bootstrap-vue';
  import vSelect from "vue-select";
  import axios from 'axios';
  
  extend('required', required);
  extend('date_format', {
    validate: (value) => {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      return regex.test(value);
    },
    message: 'The {_field_} field must be in the format YYYY-MM-DD'
  });
  
  export default {
    data() {
      return {
        device: {
          center_id: '',
          unit_id: '',
          supplier_id: '',
          device_model_id: '',
          chip_id: '',
          imei: '',
          firmware: '',
          serial_number: '',
          acquisition_date: '',
          description: '',
        },
        suppliers: [],
        centers: [],
        units: [],
        deviceModels: [],
        chips: [],
        errors: undefined,
      };
    },
    computed: {
      filteredDeviceUnits() {
        return !this.device.center_id ? [] : this.units.filter(models => models.center_id === this.device.center_id);
      },
      filteredDeviceModels() {
        return !this.device.supplier_id ? [] : this.deviceModels.filter(models => models.supplier_id === this.device.supplier_id);
      }
    },
    methods: {
      async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
      changetUnitsCenter(center_id) {
        this.device.unit_id = '';
        this.getUnitsCenter(center_id);
      },
      save() {
        let api = this.$store.state.api + "devices";
        if (this.device.id) {
          this.device._method = "PUT";
          api += "/" + this.device.id;
        }
        axios
          .post(api, this.device)
          .then(() => {
            this.$message(
              this.$t('Success'),
              this.$t('Information saved successfully'),
              "success"
            );
            this.$router.push("/devices");
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getDevice(id) {
        let api = this.$store.state.api + "devices/" + id;
        axios
          .get(api)
          .then((response) => {
            this.device = response.data.data[0];
            this.getUnitsCenter(this.device.center_id);
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getCenters() {
        let api = this.$store.state.api + "centers/";
        axios
          .get(api)
          .then((response) => {
            this.centers = response.data.data;
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getUnitsCenter(center_id) {
        const api = `${this.$store.state.api}centers/unit/${center_id}`;
        axios
          .get(api)
          .then((response) => {
            this.units = response.data;
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getDeviceModels() {
        let api = this.$store.state.api + "deviceModels";
        axios
          .get(api)
          .then((response) => {
            this.deviceModels = response.data.data;
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getSuppliers() {
        let api = this.$store.state.api + "suppliers";
        axios
          .get(api)
          .then((response) => {
            this.suppliers = response.data.data;
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getChips() {
        let api = this.$store.state.api + "chips";
        axios
          .get(api)
          .then((response) => {
            this.chips = response.data.data;
          })
          .catch((error) => {
            this.$error(error);
          });
      },
    },
    mounted() {
      const id = this.$route.params.id;
      if (id) {
        this.getDevice(id);
      }
      this.getSuppliers();
      this.getDeviceModels();
      this.getCenters();
      this.getChips();
    },
    components: {
      ValidationObserver,
      ValidationProvider,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      vSelect,
    },
  };
  </script>
  
  <style scoped>
  .required {
    color: red !important;
  }
  </style>
  