<template>
    <div class="main-content">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <ValidationObserver ref="formObserver" v-slot="{ validate }">
                            <b-form @submit.prevent="handleSubmit(validate)">
                                <div class="form-row">
                                    <div class="form-group col-md-4">
                                        <ValidationProvider name="Centrals" rules="required" v-slot="{ errors }">
                                            <label for="centrals">
                                                <span class="required">*</span> {{ $t('Centrals') }} :
                                            </label>
                                            <v-select :options="centers" :reduce="center => center.id" :label="'name'" v-model="fine.center_id" class="vselect" v-on:input="changetUnitsCenter" 
                                                :class="{ 'is-invalid': errors.length }" />
                                            <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <ValidationProvider name="Unit" rules="required" v-slot="{ errors }">
                                            <label for="units">
                                                <span class="required">*</span> {{ $t('Unit') }} :
                                            </label>
                                            <v-select :options="units" :reduce="unit => unit.id" :label="'name'" v-model="fine.unit_id" class="vselect" v-on:input="changeVehicleByUnits" 
                                                :class="{ 'is-invalid': errors.length }" />
                                                <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <ValidationProvider name="Vehicle" rules="required" v-slot="{ errors }">
                                            <label for="vehicles">
                                                <span class="required">*</span> {{ $t('Vehicle') }} :
                                            </label>
                                            <v-select :options="vehicles" :reduce="vehicle => vehicle.id" :label="'name'" v-model="fine.vehicle_id" class="vselect" 
                                                :class="{ 'is-invalid': errors.length }" />
                                                <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <ValidationProvider name="Conductor" rules="required" v-slot="{ errors }">
                                            <label for="professionals">
                                                <span class="required">*</span> {{ $t('Conductor') }} :
                                            </label>
                                            <v-select :options="professionals" :reduce="professional => professional.id" :label="'name'" v-model="fine.professional_id" class="vselect" 
                                                :class="{ 'is-invalid': errors.length }" />
                                                <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <ValidationProvider name="Fine Type" rules="required" v-slot="{ errors }">
                                            <label for="fine_type">
                                                <span class="required">*</span> {{ $t('Fine type') }} :
                                            </label>
                                            
                                            <v-select :options="fine_types" :reduce="fine_type => fine_type.id" :label="'name'" v-model="fine.fine_type_id" class="vselect" 
                                                :class="{ 'is-invalid': errors.length }" />
                                                <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                            </ValidationProvider>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <ValidationProvider name="Infraction Date" rules="required" v-slot="{ errors }">
                                                <label for="infraction_date">
                                                    <span class="required">*</span> {{ $t('Infraction date') }} :
                                                </label>
                                                
                                                <b-form-input type="datetime-local" id="infraction_date" v-model="fine.infraction_date" :state="errors.length ? false : null" placeholder="" />
                                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <ValidationProvider name="Notification Date" rules="required" v-slot="{ errors }">
                                                <label for="notification_date">
                                                    <span class="required">*</span> {{ $t('Notification date') }} :
                                                </label>
                                                <b-form-input type="datetime-local" id="notification_date" v-model="fine.notification_date" :state="errors.length ? false : null" placeholder="" />
                                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                            </ValidationProvider>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <ValidationProvider name="Transfer Date" rules="required" v-slot="{ errors }">
                                                <label for="transfer_date">
                                                    <span class="required">*</span> {{ $t('Transfer date') }} :
                                                </label>
                                                <b-form-input type="date" id="transfer_date" v-model="fine.transfer_date" :state="errors.length ? false : null" placeholder="" />
                                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                            </ValidationProvider>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <ValidationProvider name="Due Date" rules="required" v-slot="{ errors }">
                                                <label for="due_date">
                                                    <span class="required">*</span> {{ $t('Due date') }} :
                                                </label>
                                                <b-form-input type="date" id="due_date" v-model="fine.due_date" :state="errors.length ? false : null" placeholder="" />
                                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                            </ValidationProvider>
                                        </div>
                                        <div class="form-group col-md-12">
                                            <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                                                <label for="description">
                                                    <span class="required">*</span> {{ $t('Description') }} :
                                                </label>
                                                <b-form-textarea id="description" v-model="fine.description" :state="errors.length ? false : null" rows="3" />
                                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                            </ValidationProvider>
                                        </div>
                                        <div class="form-group col-md-12">
                                            <ValidationProvider name="Files"  v-slot="{ errors }">
                                                <label for="files">
                                                    <span class="required">*</span> {{ $t('Attach files') }} :
                                                </label>
                                                <upload-file :fileId="fine.files" @uploaded="(event) => { fine.files = event }"  :class="{ 'is-invalid': errors.length }" />
                                                    <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <span class="required_fields">
                                    <span class="required">*</span>
                                    <strong> {{ $t('Required Fields') }}</strong>
                                </span>
                                <div class="block text-right">
                                    <router-link to="/fines" class="btn btn-back">
                                        <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                                    </router-link>
                                    <b-button type="submit" variant="primary" class="ml-1">
                                        {{ $t('Save') }}
                                        <i class="fa fa-save"></i>
                                    </b-button>
                                </div>
                            </b-form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { BForm, BFormGroup, BFormInput, BFormTextarea, BFormInvalidFeedback, BButton } from 'bootstrap-vue';
import vSelect from "vue-select";
import UploadFile from "../../layouts/components/UploadFile";
import axios from 'axios';

extend('required', required);

export default {
    data() {
        return {
            fine: {
                status: "1",
                center_id: '',
                unit_id: '',
                vehicle_id: '',
                professional_id: '',
                fine_type_id: '',
                infraction_date: '',
                notification_date: '',
                transfer_date: '',
                due_date: '',
                description: '',
                files: null,
            },
            centers: [],
            units: [],
            vehicles: [],
            professionals: [],
            fine_types: [],
            errors: undefined,
            previewImage: null,
        };
    },
    methods: {
        async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
        changetUnitsCenter(center_id) {
            this.fine.unit_id = ''
            this.getUnitsCenter(center_id)
        },
        changeVehicleByUnits(unit_id) {
            this.fine.vehicle_id = ''
            this.getVehicles(unit_id)
        },
        save() {
            const api = `${this.$store.state.api}fines`;

            if (this.fine.id) {
                this.fine._method = "PUT";
                api += "/" + this.fine.id;
            }

            axios
                .post(api, this.fine)
                .then((response) => {
                    this.$message(
                        this.$t('Success'),
                        this.$t('Information saved successfully'),
                        "success"
                    );
                    this.$router.push("/fines");
                })
                .catch((error) => {
                    this.$error(error);
                });
        },
        getFine(id) {
            const api = `${this.$store.state.api}fines/${id}`;

            axios
                .get(api)
                .then((response) => {
                    this.fine = response.data.data[0];
                    this.getUnitsCenter(this.fine.center_id)
                    this.getVehicles(this.fine.unit_id)
                })
                .catch((error) => {
                    this.$error(error);
                });
        },
        getCenters() {
            const api = `${this.$store.state.api}centers/`;

            axios
                .get(api)
                .then((response) => {
                    this.centers = response.data.data;
                })
                .catch((error) => {
                    this.$error(error);
                });
        },
        getUnitsCenter(center_id) {
            const api = `${this.$store.state.api}centers/unit/${center_id}`;

            axios
                .get(api)
                .then((response) => {
                    this.units = response.data;
                })
                .catch((error) => {
                    this.$error(error);
                });
        },
        getVehicles(unit_id) {
            const api = `${this.$store.state.api}units/vehicles/${unit_id}`;

            axios
                .get(api)
                .then((response) => {
                    this.vehicles = response.data;
                })
                .catch((error) => {
                    this.$error(error);
                });
        },
        getProfessionals() {
            const api = `${this.$store.state.api}professionals/`;

            axios
                .get(api)
                .then((response) => {
                    this.professionals = response.data.data;
                })
                .catch((error) => {
                    this.$error(error);
                });
        },
        getFineTypes() {
            const api = `${this.$store.state.api}fine_types/`;

            axios
                .get(api)
                .then((response) => {
                    this.fine_types = response.data.data;
                })
                .catch((error) => {
                    this.$error(error);
                });
        },
    },
    mounted() {
        const id = this.$route.params.id;

        this.getCenters();
        this.getProfessionals();
        this.getFineTypes();

        if (id) {
            this.getFine(id);
        }
    },
    components: {
        ValidationObserver,
        ValidationProvider,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BFormInvalidFeedback,
        BButton,
        vSelect,
        UploadFile,
    },
};
</script>

<style scoped>
.required {
    color: red !important;
}
</style>
