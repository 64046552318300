<template>
    <div class="block text-right">
      <router-link :to="backLink" class="btn btn-back">
        <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
      </router-link>
      <!-- <b-button type="reset" variant="outline-danger" class="ml-1" @click="$emit('reset')">
        {{ $t('clear') }}
        <i class="fa fa-save"></i>
      </b-button> -->
      <b-button type="submit" variant="primary" class="ml-1">
        {{ $t('Save') }}
        <i class="fa fa-save"></i>
      </b-button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      backLink: {
        type: String,
        required: true
      },
    },
  };
  </script>
  