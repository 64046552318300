<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <ValidationObserver ref="formObserver" v-slot="{ invalid, validate }">
                    <b-form @submit.prevent="handleSubmit(validate)">
                      <div class="form-row">
                        <div class="form-group col-md-4" v-if="!companyId">
                          <ValidationProvider name="Company" rules="required" v-slot="{ errors }">
                              <label for="company">
                                      <span class="required">*</span> {{ $t('Company') }}:
                                </label>
                              <v-select
                                id="company"
                                :options="companies"
                                :reduce="(company) => company.id"
                                :label="'name'"
                                v-model="unit.company_id"
                                class="vselect"
                                v-on:input="changeCenterCompany"
                                :class="{ 'is-invalid': errors.length }" />
                                <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-4">
                          <ValidationProvider name="Central" rules="required" v-slot="{ errors }">
                              <label for="center">
                                      <span class="required">*</span> {{ $t('Central') }}:
                                </label>
                              <v-select
                                id="center"
                                :options="centers"
                                :reduce="(centers) => centers.id"
                                :label="'name'"
                                v-model="unit.center_id"
                                class="vselect"
                                :class="{ 'is-invalid': errors.length }" />
                                <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-4">
                          <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                              <label for="name">
                                      <span class="required">*</span> {{ $t('Name') }}:
                                </label>
                              <b-form-input
                                id="name"
                                type="text"
                                v-model="unit.name"
                                autocomplete="new-password"
                                :state="errors.length ? false : null"
                                placeholder=""
                              />
                              <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-4">
                          <ValidationProvider name="Contact" rules="required" v-slot="{ errors }">
                            <label for="contact">
                              <span class="required">*</span> {{ $t('Name the person responsible') }}:
                            </label>
                              <b-form-input
                                id="contact"
                                type="text"
                                v-model="unit.contact"
                                autocomplete="new-password"
                                :state="errors.length ? false : null"
                                placeholder=""
                              />
                              <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-4">
                          <ValidationProvider name="Phone" rules="required" v-slot="{ errors }">
                            <label for="phone">
                              <span class="required">*</span> {{ $t('Telephonee') }}:
                            </label>
                              <b-form-input
                                id="phone"
                                type="text"
                                v-mask="['(99) 99999-9999','(99) 9999-9999']"
                                v-model="unit.phone"
                                autocomplete="new-password"
                                :state="errors.length ? false : null"
                                placeholder=""
                              />
                              <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-4">
                          <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                            <label for="email">
                              <span class="required">*</span> {{ $t('Email') }}:
                            </label>
                              <b-form-input
                                id="email"
                                type="email"
                                v-model="unit.email"
                                autocomplete="new-password"
                                :state="errors.length ? false : null"
                                placeholder=""
                              />
                              <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-8">
                          <ValidationProvider name="Address"  v-slot="{ errors }">
                              <label for="address">
                              <span class="required">*</span> {{ $t('Address') }}:
                            </label>
                              <AddressAutocomplete
                                :autocompleteValueProp="unit.address"
                                @placeSelected="onPlaceSelected"
                                @placeChange="(event)=>{unit.address = event}"
                                :class="{ 'is-invalid': errors.length }" />
                                <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-4">
                          <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                              <label for="status">
                              <span class="required">*</span> {{ $t('Status') }}:
                            </label>
                              <b-form-select
                                id="status"
                                v-model="unit.status"
                                :state="errors.length ? false : null"
                              >
                                <option value="1"> {{ $t('Active') }} </option>
                                <option value="0"> {{ $t('Inactive') }} </option>
                              </b-form-select>
                              <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </div>
                      <span class="required_fields">
                        <span class="required">*</span>
                        <strong>{{ $t('Required Fields') }}</strong>
                      </span>
                      <div class="block text-right">
                        <router-link to="/units" class="btn btn-back">
                          <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                        </router-link>
                        <b-button type="submit" variant="primary" class="ml-1">
                          {{ $t('Save') }}
                          <i class="fa fa-save"></i>
                        </b-button>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import AddressAutocomplete from "../../layouts/Mapa/AddressAutocomplete";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { BButton, BForm, BFormInput, BFormGroup, BFormSelect, BFormInvalidFeedback } from "bootstrap-vue";

// Add the rules to vee-validate
extend("required", required);
extend("email", email);

export default {
  name: "unitForm",
  components: {
    BaseCrud,
    vSelect,
    AddressAutocomplete,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormInvalidFeedback,
  },
  data() {
    return {
      unit: {
        name: '',
        state_id: '',
        company_id: '',
        center_id: '',
        neighborhood_id: '',
        municipality_id: '',
        address_geometry: '',
        area_id: '',
        address: '',
        phone: '',
        contact: '',
        email: '',
        status: "1",
      },
      companies: [],
      areasUnits: [],
      areas: [],
      centers: [],
      errors: undefined,
      previewImage: null,
    };
  },
  computed: {
    companyId() {
      return this.$store.state.user.company_id > 1 ? this.$store.state.user.company_id : null;
    }
  },
  methods: {
    async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
    changeCenterCompany(company_id) {
      this.unit.center_id = '';
      this.centers = [],
      this.getCentersCompany(company_id);
    },
    onPlaceChanged(event) {
      this.unit.address = event;
      this.unit.state_id = '';
      this.unit.address_geometry = '';
      this.unit.municipality_id = '';
      this.unit.neighborhood_id = '';
    },
    onPlaceSelected(place) {
      this.unit.address = place.address;
      this.unit.address_geometry = JSON.stringify(place.address_geometry);
      this.unit.state_id = place.municipality.state_id;
      this.unit.municipality_id = place.neighborhood.municipality_id;
      this.unit.neighborhood_id = place.neighborhood.id;
    },
    getCompanies(id) {
      let api = this.$store.state.api + "companies/list/" + id;
      axios
        .get(api)
        .then((response) => {
          this.companies = response.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getCenter(id) {
      let api = this.$store.state.api + "centers/" + id;
      axios
        .get(api)
        .then((response) => {
          let center = response.data.data[0];
          this.getCentersCompany(center.company_id);
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getCentersCompany(company_id) {
      const api = `${this.$store.state.api}companies/centers/${company_id}`;
      axios
        .get(api)
        .then((response) => {
          this.centers = response.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    save() {
      if (!this.unit.address || this.unit.address === '') {
        this.onPlaceChanged('');
      }
      let api = this.$store.state.api + "units";
      if (this.companyId) {
        this.unit.company_id = this.companyId;
      }
      if (this.unit.id) {
        this.unit._method = "PUT";
        api += "/" + this.unit.id;
      }
      axios
        .post(api, this.unit)
        .then(() => {
          this.$message(
            this.$t('Success'),
            this.$t('Information saved successfully'),
            "success"
          );
          this.$router.push("/units");
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getUnit(id) {
      let api = this.$store.state.api + "units/" + id;
      axios
        .get(api)
        .then((response) => {
          this.unit = response.data.data[0];
          this.getCenter(this.unit.center_id);
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getCenters() {
      let api = this.$store.state.api + "centers";
      axios
        .get(api)
        .then((response) => {
          this.centers = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getAreas() {
      let api = this.$store.state.api + "areas";
      axios
        .get(api)
        .then((response) => {
          this.areas = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
  },
  mounted() {
    let id = this.$route.params.id;
    this.getCompanies(this.$store.state.user.company_id);
    this.getCentersCompany(this.$store.state.user.company_id);
    this.getAreas();
    if (id) {
      this.getUnit(id);
    }
  },
};
</script>

<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>
