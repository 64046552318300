<template>
<div>
    <div class="row">
        <div class="col-md-12">
            <base-crud :ref="'refuelingsCrud'" :crudName="$t('Supplies')" :newText="$t('Add')+ '  '+$t('New')" :table="table" :columns="columns" :options="options" :endPoint="endPoint" :enableAdd="true" :enableEdit="true" :enableDelete="true" :enableView="false" :enableReset="false"  :dataForm="dataForm">
            </base-crud>
        </div>
    </div>
</div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
    data: function () {
        return {
            table: "refuelingsTable",
            showMessageImport: false,
            refuelingsCrud: {},
            pages: [],
            url: "",
            columns: [
                "id",
                "vehicle_id",
                "refueling_date",
                "actions",
            ],
            tableData: [],
            optionsOrigin: {
                filterByColumn: true,
                debounce: 1000,
                filterable: [],
                pagination: {
                    chunk: 10,
                    dropdown: false,
                    nav: "scroll"
                },
                perPage: 10,
                perPageValues: [10, 25, 50, 100],
                headings: {
                    id: this.$t('id'),
                    vehicle_id: this.$t('Vehicle'),
                    refueling_date: this.$t('Supply date'),
                    actions: this.$t('action'),
                },

                texts: {
                    filterBy: this.$t('Filter'),
                    defaultOption: this.$t('Select'),
                },
                listColumns: {

                },
                templates: {
                    status: function (h, row, index) {
                        return row.status == 1 ?
                            this.$t('Active') :
                            this.$t('Inactive')
                    },

                    vehicle_id: function (h, row, index) {
                        return row.vehicle ? row.vehicle.name : ""
                    },
                },
                requestFunction: function (data) {
                    let requestData = {};

                   

                    let query = this.$parent.$parent.$parent.query(data.query);
                    requestData.params = data;
                    requestData.params.query = "";
                    requestData.params.with = ["vehicle"];

                    return axios.get(this.url + "?" + query, requestData).catch(
                        function (e) {
                            this.dispatch("error", e);
                        }.bind(this)
                    );
                },
            },
            extra: [{
                id: 1,
                label: "",
                class: "btn btn-crud check",
                title: "Download",
                name: "download",
                icon: "fas fa-file-download",
            }, ],
            endPoint: "refuelings",
            dataForm: {},
        };
    },
    components: {
        BaseCrud,
    },
    computed: {
        options() {
            const headings = {
                id: this.$t('id'),
                vehicle_id: this.$t('Vehicle'),
                refueling_date: this.$t('Supply date'),
                actions: this.$t('action'),
            }

            const texts = {
                filterBy: this.$t('Filter'),
                defaultOption: this.$t('Select'),
            }
            return {
                ...this.optionsOrigin,
                texts,
                headings,
            }

        }

    },
    methods: {
        makeFormData: function () {
            const self = this;
            let fd = new FormData();

            let fileImport = document.getElementById("fileImport");

            fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

            return fd;
        },
        query: function (query) {
            let columns = {
                id: "id",
                vehicle: "vehicle[name]",
                status: "status"

            };
            let filters = "";
            $.each(query, function (index, value) {
                filters += columns[index] + "=" + value + "&";
            });
            return filters;
        },
        download(props) {
            fetch(props.row.url)
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = '';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(e => console.error('Download failed:', e));
        }
    },
};
</script>

<style scoped>
.VuePagination {
    text-align: center;
}

.uploading-image {
    display: flex;
    border-radius: 10px;
    width: 100px;
    height: 100px;
}
</style>
