import Crud from '../views/TelephoneType/Crud'
import Entity from '../views/TelephoneType/Add'

let routes = {
    path: '/telephone_type',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/telephone_type/add',
            name: 'NewTelephoneType',
            component: Entity,
            meta: {
                pageTitle: 'Phone Types',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/telephone_type'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/telephone_type/edit/:id?',
            name: 'EditTelephoneType',
            component: Entity,
            meta: {
                pageTitle: 'Phone Type',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/telephone_type'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/telephone_type',
            name: 'list-telephone_type',
            component: Crud,
            meta: {
                pageTitle: 'Phone Types',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
