
import Crud from '../views/EventTypes/Crud'
import Entity from '../views/EventTypes/Add'

const routes = {
    path: '/eventtypes',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/eventtypes/add',
            name: 'NewEventType',
            component: Entity,
            meta: {
                pageTitle: 'Event types',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/eventtypes'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/eventtypes/edit/:id?',
            name: 'EditEventType',
            component: Entity,
            meta: {
                pageTitle: 'Event types',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to: '/eventtypes',
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/eventtypes',
            name: 'list-eventTypes',
            component: Crud,
            meta: {
                pageTitle: 'Event types',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
