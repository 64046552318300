
const IT4D = 1
const ADMIN_CLIENT = 2
const USER_CLIENT = 3 

const  PREMISSIONS = {
    MASTER : [IT4D],
    ADMIN :  [IT4D, ADMIN_CLIENT],
    USER : [ IT4D, ADMIN_CLIENT, USER_CLIENT],
}


export default PREMISSIONS;