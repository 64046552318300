<template>
    <div>
        <div class="main-content">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <ValidationObserver ref="formObserver" v-slot="{ validate }">
                                        <b-form @submit.prevent="handleSubmit(validate)">
                                            <div class="form-row">
                                                <div class="form-group col-md-8">
                                                <ValidationProvider name="Supplier" rules="required" v-slot="{ errors }">
                                                        <label for="Name">
                                                            <span class="required">*</span> {{ $t('Supplier') }}:
                                                         </label>
                                                            <b-form-input
                                                                type="text"
                                                                id="Name"
                                                                v-model="supplier.name"
                                                                :state="errors.length ? false : null"
                                                                placeholder=""
                                                                />
                                                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="form-group col-md-8">
                                                            <ValidationProvider name="description" rules="required" v-slot="{ errors }">
                                                                <label for="description">
                                                                    <span class="required">*</span> {{ $t('Description') }}:
                                                                </label>
                                                                <b-form-textarea
                                                                id="description"
                                                                v-model="supplier.description"
                                                                rows="4"
                                                                placeholder=""
                                                                :state="errors.length ? false : null"
                                                            />
                                                            <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <span class="required_fields">
                                                <span class="required">*</span>
                                                <strong> {{ $t('Required Fields') }}</strong>
                                            </span>
                                            <div class="block text-right">
                                                <router-link to="/suppliers" class="btn btn-back">
                                                    <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                                                </router-link>
                                                <b-button type="submit" variant="primary" class="ml-1">
                                                    {{ $t('Save') }}
                                                    <i class="fa fa-save"></i>
                                                </b-button>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
    import { required } from "vee-validate/dist/rules";
    import { BForm, BFormGroup, BFormInput, BFormTextarea, BFormInvalidFeedback, BButton } from "bootstrap-vue";
    import axios from "axios";
    
    extend("required", required);
    
    export default {
        data() {
            return {
                supplier: {
                    name: '',
                    company_id: '',
                    description: '',
                },
                errors: undefined,
                previewImage: null,
            };
        },
        computed: {
            companyId() {
                return this.$store.state.user.company_id;
            }
        },
        methods: {
            async handleSubmit(validate) {
                const isValid = await validate();
                if (isValid) {
                    this.save();
                }
            },
            save() {
                const self = this;
                let api = self.$store.state.api + "suppliers";
                self.supplier.company_id = self.companyId;
    
                if (self.supplier.id) {
                    self.supplier._method = "PUT";
                    api += "/" + self.supplier.id;
                }
    
                axios
                    .post(api, self.supplier)
                    .then(() => {
                        self.$message(
                            this.$t('Success'),
                            this.$t('Information saved successfully'),
                            "success"
                        );
                        self.$router.push("/suppliers");
                    })
                    .catch((error) => {
                        self.$error(error);
                    });
            },
            getSupplier(id) {
                const self = this;
                const api = self.$store.state.api + "suppliers/" + id;
    
                axios
                    .get(api)
                    .then((response) => {
                        self.supplier = response.data.data[0];
                    })
                    .catch((error) => {
                        self.$error(error);
                    });
            },
        },
        mounted() {
            const self = this;
            let id = self.$route.params.id;
    
            if (id) {
                self.getSupplier(id);
            }
        },
        components: {
            ValidationObserver,
            ValidationProvider,
            BForm,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BFormInvalidFeedback,
            BButton,
        },
    };
    </script>
    
    <style scoped>
    .required {
        color: red !important;
    }
    </style>
    