var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationObserver',{ref:"formObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Centrals","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"centrals"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Centrals'))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"id":"centrals","options":_vm.centers,"reduce":function (centers) { return centers.id; },"label":'name'},on:{"input":_vm.changetUnitsCenter},model:{value:(_vm.refueling.center_id),callback:function ($$v) {_vm.$set(_vm.refueling, "center_id", $$v)},expression:"refueling.center_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"unit"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Unit'))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"id":"unit","options":_vm.units,"reduce":function (unit) { return unit.id; },"label":'name'},on:{"input":_vm.changeVehicleByUnits},model:{value:(_vm.refueling.unit_id),callback:function ($$v) {_vm.$set(_vm.refueling, "unit_id", $$v)},expression:"refueling.unit_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Vehicle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Vehicle"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Vehicle'))+" : ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.vehicles,"reduce":function (vehicle) { return vehicle.id; },"label":'name'},model:{value:(_vm.refueling.vehicle_id),callback:function ($$v) {_vm.$set(_vm.refueling, "vehicle_id", $$v)},expression:"refueling.vehicle_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"GasStation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"GasStation"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Gas station'))+" : ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.gas_stations,"reduce":function (station) { return station.id; },"label":'name'},model:{value:(_vm.refueling.gas_station_id),callback:function ($$v) {_vm.$set(_vm.refueling, "gas_station_id", $$v)},expression:"refueling.gas_station_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-2"},[_c('ValidationProvider',{attrs:{"name":"Refueling date","rules":"required|date_format"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Refueling_date"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Refueling date'))+" : ")]),_c('b-form-input',{attrs:{"type":"date","id":"Refueling_date","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.refueling.refueling_date),callback:function ($$v) {_vm.$set(_vm.refueling, "refueling_date", $$v)},expression:"refueling.refueling_date"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-2"},[_c('ValidationProvider',{attrs:{"name":"Fuel Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"fuel_type"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Fuel type'))+": ")]),_c('b-form-select',{staticClass:"form-control",class:{ 'is-invalid': errors.length },model:{value:(_vm.refueling.fuel_type),callback:function ($$v) {_vm.$set(_vm.refueling, "fuel_type", $$v)},expression:"refueling.fuel_type"}},[_c('option',{attrs:{"value":"Gasolina"}},[_vm._v(_vm._s(_vm.$t('Gasolina')))]),_c('option',{attrs:{"value":"Etanol"}},[_vm._v(_vm._s(_vm.$t('Etanol')))]),_c('option',{attrs:{"value":"Diesel"}},[_vm._v(_vm._s(_vm.$t('Diesel')))])]),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-2"},[_c('ValidationProvider',{attrs:{"name":"Quantity (liters)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Quantity"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Quantity (liters)'))+": ")]),_c('b-form-input',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"type":"text","id":"Quantity"},model:{value:(_vm.refueling.liters),callback:function ($$v) {_vm.$set(_vm.refueling, "liters", $$v)},expression:"refueling.liters"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-2"},[_c('ValidationProvider',{attrs:{"name":"Value","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"value"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Value'))+": ")]),_c('b-form-input',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"type":"text","id":"value","placeholder":""},model:{value:(_vm.refueling.value),callback:function ($$v) {_vm.$set(_vm.refueling, "value", $$v)},expression:"refueling.value"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"description"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Description'))+" : ")]),_c('b-form-textarea',{attrs:{"id":"description","state":errors.length ? false : null,"rows":"3"},model:{value:(_vm.refueling.description),callback:function ($$v) {_vm.$set(_vm.refueling, "description", $$v)},expression:"refueling.description"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Requester","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Requester"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Requester'))+" : ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.professionals,"reduce":function (professional) { return professional.id; },"label":'name'},model:{value:(_vm.refueling.requester_id),callback:function ($$v) {_vm.$set(_vm.refueling, "requester_id", $$v)},expression:"refueling.requester_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Requester","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Requester"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Approver'))+" : ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.professionals,"reduce":function (professional) { return professional.id; },"label":'name'},model:{value:(_vm.refueling.approver_id),callback:function ($$v) {_vm.$set(_vm.refueling, "approver_id", $$v)},expression:"refueling.approver_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Attach files"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"anexo"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Attach files'))+": ")]),_c('upload-file',{class:{ 'is-invalid': errors.length },attrs:{"fileId":_vm.refueling.files},on:{"uploaded":function (event) { _vm.refueling.files = event }}})],1)]}}],null,true)})],1)]),_c('span',{staticClass:"required_fields"},[_c('span',{staticClass:"required"},[_vm._v("*")]),_c('strong',[_vm._v(_vm._s(_vm.$t('Required Fields')))])]),_c('ActionButtons',{attrs:{"backLink":"/refuelings"},on:{"reset":_vm.onReset}})],1)]}}])})],1)])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }