var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationObserver',{ref:"formObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validate = ref.validate;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(validate)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Vehicle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"vehicles"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Vehicle'))+" : ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.vehicles,"reduce":function (vehicle) { return vehicle.id; },"label":'name'},model:{value:(_vm.auto_insurance.vehicle_id),callback:function ($$v) {_vm.$set(_vm.auto_insurance, "vehicle_id", $$v)},expression:"auto_insurance.vehicle_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Policy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"policy"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Policy'))+" : ")]),_c('b-form-input',{attrs:{"type":"text","id":"policy","state":errors.length ? false : null},model:{value:(_vm.auto_insurance.policy),callback:function ($$v) {_vm.$set(_vm.auto_insurance, "policy", $$v)},expression:"auto_insurance.policy"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"CI","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"ci"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Identification Code'))+" : ")]),_c('b-form-input',{attrs:{"type":"text","id":"ci","state":errors.length ? false : null},model:{value:(_vm.auto_insurance.ci),callback:function ($$v) {_vm.$set(_vm.auto_insurance, "ci", $$v)},expression:"auto_insurance.ci"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Value","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"value"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Value'))+" : ")]),_c('b-form-input',{attrs:{"type":"text","id":"value","state":errors.length ? false : null},model:{value:(_vm.auto_insurance.value),callback:function ($$v) {_vm.$set(_vm.auto_insurance, "value", $$v)},expression:"auto_insurance.value"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Hiring date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"hiring_date"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Hiring date'))+" : ")]),_c('b-form-input',{attrs:{"type":"date","id":"hiring_date","state":errors.length ? false : null},model:{value:(_vm.auto_insurance.hiring_date),callback:function ($$v) {_vm.$set(_vm.auto_insurance, "hiring_date", $$v)},expression:"auto_insurance.hiring_date"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Validity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"validity"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Validity2'))+" : ")]),_c('b-form-input',{attrs:{"type":"date","id":"validity","state":errors.length ? false : null},model:{value:(_vm.auto_insurance.validity),callback:function ($$v) {_vm.$set(_vm.auto_insurance, "validity", $$v)},expression:"auto_insurance.validity"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Insurer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"insurances"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Insurer'))+" : ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.insurances,"reduce":function (insurance) { return insurance.id; },"label":'name'},model:{value:(_vm.auto_insurance.insurance_id),callback:function ($$v) {_vm.$set(_vm.auto_insurance, "insurance_id", $$v)},expression:"auto_insurance.insurance_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Vehicle document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"vehicle_doc"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Vehicle document'))+" : ")]),_c('upload-file',{class:{ 'is-invalid': errors.length },attrs:{"fileId":_vm.auto_insurance.vehicle_doc},on:{"uploaded":function (event) { _vm.auto_insurance.vehicle_doc = event }}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Policy File"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"policy_doc"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Policy File'))+" : ")]),_c('upload-file',{class:{ 'is-invalid': errors.length },attrs:{"fileId":_vm.auto_insurance.policy_doc},on:{"uploaded":function (event) { _vm.auto_insurance.policy_doc = event }}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"description"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Description'))+" : ")]),_c('b-form-textarea',{attrs:{"id":"description","rows":"3","state":errors.length ? false : null},model:{value:(_vm.auto_insurance.description),callback:function ($$v) {_vm.$set(_vm.auto_insurance, "description", $$v)},expression:"auto_insurance.description"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Requester","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"requester"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Requester'))+" : ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.professionals,"reduce":function (professional) { return professional.id; },"label":'name'},model:{value:(_vm.auto_insurance.requester_id),callback:function ($$v) {_vm.$set(_vm.auto_insurance, "requester_id", $$v)},expression:"auto_insurance.requester_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Approver","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"approver"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Approver'))+" : ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.professionals,"reduce":function (professional) { return professional.id; },"label":'name'},model:{value:(_vm.auto_insurance.approver_id),callback:function ($$v) {_vm.$set(_vm.auto_insurance, "approver_id", $$v)},expression:"auto_insurance.approver_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"status"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Status'))+" : ")]),_c('b-form-select',{attrs:{"options":[{ value: 1, text: _vm.$t('Active') }, { value: 0, text: _vm.$t('Inactive') }],"state":errors.length ? false : null},model:{value:(_vm.auto_insurance.status),callback:function ($$v) {_vm.$set(_vm.auto_insurance, "status", $$v)},expression:"auto_insurance.status"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)]),_c('span',{staticClass:"required_fields"},[_c('span',{staticClass:"required"},[_vm._v("*")]),_c('strong',[_vm._v(" "+_vm._s(_vm.$t('Required Fields')))])]),_c('div',{staticClass:"block text-right"},[_c('router-link',{staticClass:"btn btn-back",attrs:{"to":"/auto_insurances"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t('To Go Back'))+" ")]),_c('b-button',{staticClass:"ml-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" "),_c('i',{staticClass:"fa fa-save"})])],1)])]}}])})],1)])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }