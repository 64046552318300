<template>
    <div>
      <div class="main-content">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <ValidationObserver ref="formObserver" v-slot="{ invalid, validate }">
                      <b-form @submit.prevent="handleSubmit(validate)">
                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <ValidationProvider name="Supplier" rules="required" v-slot="{ errors }">
                                <label for="Supplier">
                                      <span class="required">*</span> {{ $t('Supplier') }}:
                                  </label>
                                <v-select :options="suppliers" :reduce="(supplier) => supplier.id" :label="'name'" v-model="deviceModel.supplier_id" class="vselect" 
                                  :class="{ 'is-invalid': errors.length }" />
                                  <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                              </b-form-group>
                            </ValidationProvider>
                          </div>
                          <div class="form-group input-group-sm col-md-6">
                            <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                                <label for="Name">
                                    <span class="required">*</span> {{ $t('Name') }}:
                                </label>
                                <b-form-input type="text" id="Name" v-model="deviceModel.name" :state="errors.length ? false : null" placeholder="" />
                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-12">
                            <b-form-group :label="$t('Action') + ' :'">
                              <b-form-checkbox id="checkbox-vibrating_alert" v-model="deviceModel.vibrating_alert" name="checkbox-vibrating_alert" :value="1" :unchecked-value="0" inline>
                                {{ $t('Vibrating Alert') }}
                              </b-form-checkbox>
                              <b-form-checkbox id="checkbox-vehicle_lock" v-model="deviceModel.vehicle_lock" name="checkbox-vehicle_lock" :value="1" :unchecked-value="0" inline>
                                {{ $t('Vehicle Lock') }}
                              </b-form-checkbox>
                              <b-form-checkbox id="checkbox-sending_sms" v-model="deviceModel.sending_sms" name="checkbox-sending_sms" :value="1" :unchecked-value="0" inline>
                                {{ $t('Sending SMS') }}
                              </b-form-checkbox>
                              <b-form-checkbox id="checkbox-message" v-model="deviceModel.message" name="checkbox-message" :value="1" :unchecked-value="0" inline>
                                {{ $t('Message') }}
                              </b-form-checkbox>
                            </b-form-group>
                          </div>
                        </div>
                        <span class="required_fields">
                          <span class="required">*</span>
                          <strong>{{ $t('Required Fields') }}</strong>
                        </span>
                        <div class="block text-right">
                          <router-link to="/deviceModels" class="btn btn-back">
                            <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                          </router-link>
                          <b-button type="submit" variant="primary" class="ml-1" >
                            {{ $t('Save') }}
                            <i class="fa fa-save"></i>
                          </b-button>
                        </div>
                      </b-form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
  import { required } from 'vee-validate/dist/rules';
  import { BButton, BForm, BFormInput, BFormGroup, BFormInvalidFeedback } from 'bootstrap-vue';
  import vSelect from 'vue-select';
  import axios from 'axios';
  
  extend('required', required);
  
  export default {
    data() {
      return {
        deviceModel: {
          name: '',
          vibrating_alert: 0,
          vehicle_lock: 0,
          sending_sms: 0,
          message: 0,
          supplier_id: '',
        },
        suppliers: [],
        errors: undefined,
      };
    },
    methods: {
      async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
      save() {
        let api = this.$store.state.api + 'deviceModels';
        if (this.deviceModel.id) {
          this.deviceModel._method = 'PUT';
          api += '/' + this.deviceModel.id;
        }
        axios
          .post(api, this.deviceModel)
          .then(() => {
            this.$message(
              this.$t('Success'),
              this.$t('Information saved successfully'),
              'success'
            );
            this.$router.push('/deviceModels');
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getDeviceModels(id) {
        let api = this.$store.state.api + 'deviceModels/' + id;
        axios
          .get(api)
          .then((response) => {
            this.deviceModel = response.data.data[0];
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getSuppliers() {
        let api = this.$store.state.api + 'suppliers';
        axios
          .get(api)
          .then((response) => {
            this.suppliers = response.data.data;
          })
          .catch((error) => {
            this.$error(error);
          });
      },
    },
    mounted() {
      const id = this.$route.params.id;
      if (id) {
        this.getDeviceModels(id);
      }
      this.getSuppliers();
    },
    components: {
      ValidationObserver,
      ValidationProvider,
      BButton,
      BForm,
      BFormInput,
      BFormGroup,
      BFormInvalidFeedback,
      vSelect,
    },
  };
  </script>
  
  <style scoped>
  .required {
    color: red !important;
  }
  </style>
  