import axios from 'axios';
import env from '../../config/env'
const { baseURL, normalURL , consultApi} = env

const initialForm = {
  status: "1",
  center_id: '',
  unit_id: '',
  vehicle_id: '',
  gas_station_id: '',
  refueling_date: '',
  fuel_type: '',
  liters: '',
  value: '',
  description: '',
  requester_id: '',
  approver_id: '',
  files: ''
};

export default {
  namespaced: true,
  state: {
    refueling: { ...initialForm },
    centers: [],
    units: [],
    vehicles: [],
    professionals: [],
    gas_stations: []
  },
  mutations: {
    SET_REFUELING(state, refueling) {
      state.refueling = refueling;
    },
    RESET_REFUELING(state) {
      state.refueling = { ...initialForm };
    },
    SET_CENTERS(state, centers) {
      state.centers = centers;
    },
    SET_UNITS(state, units) {
      state.units = units;
    },
    SET_VEHICLES(state, vehicles) {
      state.vehicles = vehicles;
    },
    SET_PROFESSIONALS(state, professionals) {
      state.professionals = professionals;
    },
    SET_GAS_STATIONS(state, gas_stations) {
      state.gas_stations = gas_stations;
    },
    UPDATE_REFUELING_FIELD(state, { field, value }) {
      state.refueling[field] = value;
    }
  },
  actions: {
    fetchRefueling({ commit }, id) {
      const api = `${baseURL}refuelings/${id}`;
      return axios.get(api).then(response => {
        commit('SET_REFUELING', response.data.data[0]);
      }).catch(error => {
        console.error('Error fetching refueling:', error);
      });
    },
    fetchCenters({ commit }) {
      const api = `${baseURL}centers/`;
      return axios.get(api).then(response => {
        commit('SET_CENTERS', response.data.data);
      }).catch(error => {
        console.error('Error fetching centers:', error);
      });
    },
    fetchUnits({ commit }, center_id) {
      const api = `${baseURL}centers/unit/${center_id}`;
      return axios.get(api).then(response => {
        commit('SET_UNITS', response.data);
      }).catch(error => {
        console.error('Error fetching units:', error);
      });
    },
    fetchVehicles({ commit }, unit_id) {
      const api = `${baseURL}units/vehicles/${unit_id}`;
      return axios.get(api).then(response => {
        commit('SET_VEHICLES', response.data);
      }).catch(error => {
        // this.$error(error);
        console.error('Error fetching vehicles:', error);
      });
    },
    fetchProfessionals({ commit }) {
      const api = `${baseURL}professionals/`;
      return axios.get(api).then(response => {
        commit('SET_PROFESSIONALS', response.data.data);
      }).catch(error => {
        console.error('Error fetching professionals:', error);
      });
    },
    fetchGasStations({ commit }) {
      const api = `${baseURL}gasstations/`;
      return axios.get(api).then(response => {
        commit('SET_GAS_STATIONS', response.data.data);
      }).catch(error => {
        console.error('Error fetching gas stations:', error);
      });
    },
    saveRefueling({ state }) {
      let api = `${baseURL}refuelings`;
      if (state.refueling.id) {
        state.refueling._method = "PUT";
        api += "/" + state.refueling.id;
      }
      return axios.post(api, state.refueling).catch(error => {
        console.error('Error saving refueling:', error);
      });
    }
  },
  getters: {
    refueling: state => state.refueling,
    centers: state => state.centers,
    units: state => state.units,
    vehicles: state => state.vehicles,
    professionals: state => state.professionals,
    gas_stations: state => state.gas_stations
  }
};
