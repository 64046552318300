<template>
<div class="card">

    <div class="card-body row">
        <div class="col-md-4"> {{ $t('Requester') }} :{{ props.requester }} </div>
        <div class="col-md-4"> {{ $t('Approver') }} :{{ props.approver }} </div>
        <div class="col-md-4"> {{ $t('Gas station') }} :{{ props.gas_station }} </div>
        <div class="col-md-4"> {{ $t('Fuel type') }} :{{ props.fuel_type }} </div>
        <div class="col-md-4"> {{ $t('Liters') }} :{{ props.liters }} </div>
        <div class="col-md-4"> {{ $t('Value') }} : {{ formattedMoney(props.value) }} </div>
        <div class="col-md-4"> {{ $t('Refueling date') }} : {{ formtaDate(props.refueling_date) }} </div>
        <div class="col-md-4"> {{ $t('Description') }} : {{ props.description }} </div>
    </div>
    <div class="card-footer">
        <div class="form-group col-md-12">
            <b-button v-if="props.file_url" pill variant="outline-primary" :href="props.file_url" download target="_blank">
                {{ props.file_name}} <i class="fa fa-download"></i>
            </b-button>
        </div>

    </div>
</div>
</template>

<script>
export default {
    props: {
        props: {
            type: Object,
            required: true,
        }

    },
    methods:{
        formtaDate(date) {
            if(date){
                return moment(date).format('DD/MM/YYYY')

            }
            return ''

        },
        formattedMoney(money) {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
            }).format(money)
        },
    }
}
</script>

<!-- "refueling_date": "2024-04-22",
"fuel_type": "Gasolina",
"liters": "500",
"cccc": "25000",
"requester": "Gabriel Koch Vidotto",
"approver": "Luan de Camargo",
"file_name": "Captura de tela 2023-12-29 150447.png",
"file_url": "http://127.0.0.1:8989/uploads/RRfZtG0ztFejobbj2NGg_Captura de tela 2023-12-29 150447.png",
"description": "teste",
"gas_station": "test Nome posto de gasolina" -->

<!-- refueling_date: {
    type: String,
    default: '',
},
fuel_type: {
    type: String,
    default: '',
},
liters: {
    type: String,
    default: '',
},
value: {
    type: String,
    default: '',
},
requester: {
    type: String,
    default: '',
},
approver: {
    type: String,
    default: '',
},
file_name: {
    type: String,
    default: '',
},
file_url: {
    type: String,
    default: '',
},
description: {
    type: String,
    default: '',
},
gas_station: {
    type: String,
    default: '',
}, -->
