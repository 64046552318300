<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <ValidationObserver ref="formObserver" v-slot="{ invalid, validate }">
                    <b-form @submit.prevent="handleSubmit(validate)">
                      <div class="form-row">
                        <div class="form-group col-md-4" v-if="!companyId">
                          <ValidationProvider name="Company" rules="required" v-slot="{ errors }">
                              <label for="company">
                                <span class="required">*</span> {{ $t('Company') }}:
                              </label>
                              <v-select 
                                id="company"
                                :options="companies" 
                                :reduce="(company) => company.id" 
                                :label="'name'" 
                                v-model="users.company_id" 
                                class="vselect" 
                                v-on:input="changeCenterCompany" 
                                :class="{ 'is-invalid': errors.length }" />
                                <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4">
                          <ValidationProvider name="Centrals" rules="required" v-slot="{ errors }">
                              <label for="centrals">
                                <span class="required">*</span> {{ $t('Centrals') }}:
                              </label>
                              <v-select 
                                id="centrals"
                                :options="centers" 
                                :reduce="(centers) => centers.id" 
                                :label="'name'" 
                                v-model="users.center_id" 
                                class="vselect" 
                                v-on:input="changetUnitsCenter" 
                                :class="{ 'is-invalid': errors.length }" />
                                <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4">
                          <ValidationProvider name="Unit" rules="required" v-slot="{ errors }">
                              <label for="unit">
                                <span class="required">*</span> {{ $t('Unit') }}:
                              </label>
                              <v-select 
                                id="unit"
                                :options="units" 
                                :reduce="(units) => units.id" 
                                :label="'name'" 
                                v-model="users.unit_id" 
                                class="vselect" 
                                :class="{ 'is-invalid': errors.length }" />
                                <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                          </ValidationProvider>
                        </div>

                        <div class="form-group col-md-6">
                          <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                              <label for="Name">
                                  <span class="required">*</span> {{ $t('Name') }}:
                              </label>
                              <b-form-input 
                                id="Name"
                                type="text"
                                v-model="users.name"
                                autocomplete="new-password"
                                :state="errors.length ? false : null"
                                placeholder=""
                              />
                              <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>

                        <div class="form-group col-md-6">
                          <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                              <label for="Email">
                                  <span class="required">*</span> {{ $t('Email') }}:
                              </label>
                              <b-form-input 
                                id="Email"
                                type="email"
                                v-model="users.email"
                                autocomplete="new-password"
                                :state="errors.length ? false : null"
                                placeholder=""
                              />
                              <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-md-4">
                          <ValidationProvider name="Taxpayer Identification Number (CPF)" rules="required" v-slot="{ errors }">
                              <label for="cpf">
                                  <span class="required">*</span> {{ $t('Taxpayer Identification Number (CPF)') }}:
                              </label>
                              <b-form-input 
                                id="cpf"
                                type="text"
                                v-mask="'999.999.999-99'"
                                v-model="users.cpf_cnpj"
                                autocomplete="new-password"
                                :state="errors.length ? false : null"
                                placeholder=""
                              />
                              <b-form-invalid-feedback :state="errors.length">{{ $t('Taxpayer Identification Number (CPF)') +' '+ $t('Required Fields') }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>

                        <div class="form-group col-md-4">
                          <b-form-group label-for="rg" :label="$t('ID Card')">
                            <b-form-input 
                              id="rg"
                              type="text"
                              v-mask="'99.999.999-9'"
                              v-model="users.rg"
                              autocomplete="new-password"
                              placeholder=""
                            />
                          </b-form-group>
                        </div>
                        <div class="form-group col-md-4">
                          <ValidationProvider name="Date Of Birth" rules="required" v-slot="{ errors }">
                              <label for="CellDateBrith">
                                  <span class="required">*</span> {{ $t('Date Of Birth') }}:
                              </label>
                              <b-form-input 
                                id="CellDateBrith"
                                type="text"
                                v-mask="'99/99/9999'"
                                v-model="users.date_birth"
                                autocomplete="new-password"
                                :state="errors.length ? false : null"
                                placeholder=""
                              />
                              <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </div>

                      <div class="form-row">

                        <div class="form-group col-md-4">
                          <b-form-group label-for="Cellphone" :label="$t('Cell Phone')">
                            <b-form-input 
                              id="Cellphone"
                              type="text"
                              v-mask="'(99) 99999-9999'"
                              v-model="users.cellphone"
                              autocomplete="new-password"
                              placeholder=""
                            />
                          </b-form-group>
                        </div>

                        <div class="form-group col-md-4">
                          <b-form-group label-for="Phone" :label="$t('Telephone')">
                            <b-form-input 
                              id="Phone"
                              type="text"
                              v-mask="'(99) 9999-9999'"
                              v-model="users.telephone"
                              autocomplete="new-password"
                              placeholder=""
                            />
                          </b-form-group>
                        </div>
                        <div class="form-group col-md-4">
                          <ValidationProvider name="Access Level" rules="required" v-slot="{ errors }">
                              <label for="inputUser">
                                  <span class="required">*</span> {{ $t('Access Level') }}:
                              </label>
                              <v-select 
                                id="inputUser"
                                :options="profile_types" 
                                :reduce="(profile_types) => profile_types.id" 
                                :label="'name'" 
                                v-model="users.access_level" 
                                class="vselect" 
                                :class="{ 'is-invalid': errors.length }" />
                                <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                          </ValidationProvider>
                        </div>
                      </div>

                      <div class="row" v-if="$route.params.id">
                        <div class="col-md-12 mb-2">
                          <b-button variant="secondary" @click="toggleFormSenha">
                            <i class="fas fa-key"></i> {{ $t('Change Password')}}
                          </b-button>
                        </div>
                      </div>

                      <div class="form-row" v-if="change_password">
                        <div class="form-group col-md-6">
                          <ValidationProvider name="Password" rules="required|min:6" v-slot="{ errors }">
                              <label for="password">
                                  <span class="required">*</span> {{ $t('password') }}:
                              </label>
                              <b-form-input 
                                id="password"
                                type="password"
                                v-model="users.password"
                                autocomplete="new-password"
                                :state="errors.length ? false : null"
                                placeholder=""
                              />
                              <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>

                        <div class="form-group col-md-6">
                          <ValidationProvider name="Confirm The Password" rules="required|confirmed:password" v-slot="{ errors }">
                              <label for="confirmPassword">
                                  <span class="required">*</span> {{ $t('Confirm The Password') }}:
                              </label>
                              <b-form-input 
                                id="confirmPassword"
                                type="password"
                                v-model="users.password_confirmacao"
                                autocomplete="new-password"
                                :state="errors.length ? false : null"
                                placeholder=""
                              />
                              <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                              <label for="status">
                                  <span class="required">*</span> {{ $t('Status') }}:
                              </label>
                              <b-form-select 
                                id="status"
                                v-model="users.status"
                                :state="errors.length ? false : null"
                              >
                                <option value="1"> {{ $t('Active') }} </option>
                                <option value="0"> {{ $t('Inactive') }} </option>
                              </b-form-select>
                              <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </div>

                      <div class="form-group col-md-8 ml-n1">
                        <b-form-group label-for="userPic" :label="$t('Profile Picture')">
                          <div class="user-img">
                            <img v-if="previewImage" :src="previewImage" class="photo" />
                            <img v-else class="photo" />
                          </div>
                          <upload-file :fileId="users.photo" :isImage="true" @uploaded="(event) => { users.photo = event }" size="6rem" />
                        </b-form-group>
                      </div>

                      <span class="required_fields">
                        <span class="required">*</span>
                        <strong>{{ $t('Required Fields') }}</strong>
                      </span>

                      <div class="block text-right">
                        <router-link to="/users" class="btn btn-back">
                          <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                        </router-link>
                        <b-button type="submit" variant="primary" class="ml-1">
                          {{ $t('Save') }}
                          <i class="fa fa-save"></i>
                        </b-button>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import UploadFile from "../../layouts/components/UploadFile";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, email, min, confirmed } from "vee-validate/dist/rules";
import { BButton, BForm, BFormInput, BFormGroup, BFormSelect, BFormInvalidFeedback } from "bootstrap-vue";

// Add the rules to vee-validate
extend("required", required);
extend("email", email);
extend("min", min);
extend("confirmed", confirmed);

export default {
  name: "imageUpload",
  components: {
    BaseCrud,
    vSelect,
    UploadFile,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormInvalidFeedback,
  },
  data() {
    return {
      change_password: true,
      users: {
        name: "",
        email: "",
        cpf_cnpj: "",
        cellphone: "",
        telephone: "",
        photo: "",
        access_level: "",
        company_id: "",
        center_id: "",
        unit_id: "",
        password: "",
        password_confirmacao: "",
        users_id: "",
        status: "1",
      },
      companies: [],
      profile_types: [],
      centers: [],
      units: [],
      errors: undefined,
      previewImage: null,
    };
  },
  computed: {
    companyId() {
      return this.$store.state.user.company_id > 1 ? this.$store.state.user.company_id : null;
    }
  },
  methods: {
    async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
    changeCenterCompany(company_id) {
      this.users.center_id = '';
      this.users.unit_id = '';
      this.users.access_level = '';
      this.centers = [];
      this.units = [];
      this.profile_types = [];

      this.getCentersCompany(company_id);
      this.getProfileTypes(company_id);
    },
    changetUnitsCenter(center_id) {
      this.users.unit_id = '';
      this.getUnitsCenter(center_id);
    },
    toggleFormSenha() {
      this.change_password = !this.change_password;
    },
    save() {
      const api = `${this.$store.state.api}users`;
      if (this.companyId) {
        this.users.company_id = this.companyId;
      }
      if (this.users.id) {
        this.users._method = "PUT";
        api += `/${this.users.id}`;
      }
      axios.post(api, this.users)
        .then((response) => {
          this.$message(
            this.$t('Success'),
            this.$t('Information saved successfully'),
            "success"
          );
          this.$router.push("/users");
        })
        .catch((error) => {
          console.log('error.response.data', error.response.data)
          this.$error(error);
        });
    },
    getUsers(id) {
      const api = `${this.$store.state.api}users/${id}`;
      axios.get(api)
        .then((response) => {
          this.users = response.data.data[0];
          this.users.password = "";
          this.change_password = false;
          this.getCentersCompany(this.users.company_id);
          this.getUnitsCenter(this.users.center_id);
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getCompanies(id) {
      const api = `${this.$store.state.api}companies/list/${id}`;
      axios.get(api)
        .then((response) => {
          this.companies = response.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getCentersCompany(company_id) {
      const api = `${this.$store.state.api}companies/centers/${company_id}`;
      axios.get(api)
        .then((response) => {
          this.centers = response.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getUnitsCenter(center_id) {
      const api = `${this.$store.state.api}centers/unit/${center_id}`;
      axios.get(api)
        .then((response) => {
          this.units = response.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getProfileTypes(profile_types) {
      const api = `${this.$store.state.api}profile_types/list/${profile_types}`;
      axios.get(api)
        .then((response) => {
          this.profile_types = response.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };
    },
  },
  mounted() {
    if (this.companyId) {
      this.users.company_id = this.$store.state.user.company_id;
    }
    this.getCompanies(this.$store.state.user.company_id);
    this.getCentersCompany(this.$store.state.user.company_id);
    this.getProfileTypes(this.$store.state.user.company_id);
    const id = this.$route.params.id;
    if (id) {
      this.change_password = false;
      this.getUsers(id);
    }
  },
};
</script>

<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>
