<template>
<div class="ml-2 ">
    <p v-text="title" />
    <!-- ball  -->
    <div class="mr-1 mt-n1">

        <div class="  bg-secondary rounded" style="height:40px; width:40px; padding-top:10px; padding-left:10px;">
                <!-- small Ball -->
                <div v-if="timeText" :class="variant" class=" rounded-circle" style="height:20px; width:20px; ">
    
                </div>
    
            </div>
    </div>
        <p v-text="timeText" />
    </div>
</template>

<script>
export default {
    props: {
        variant: {
            type: String,
            default: 'bg-secondary',
        },
        title: {
            type: String,
            default: '',
        },
        timeText: {
            type: String,
            default: '',
        },
        
    },
}
</script>

<style scoped>
</style>