
import Crud from '../views/DevicesActivation/Crud'
import Entity from '../views/DevicesActivation/Add'

const routes = {
    path: '/deviceactivations',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/deviceactivations/add',
            name: 'NewdevicesActivation',
            component: Entity,
            meta: {
                pageTitle: 'Devices Activation',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/deviceactivations'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/deviceactivations/edit/:id?',
            name: 'EditdevicesActivation',
            component: Entity,
            meta: {
                pageTitle: 'Devices Activation',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/deviceactivations'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/deviceactivations',
            name: 'list-devicesActivation',
            component: Crud,
            meta: {
                pageTitle: 'Devices Activation',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
