
import Crud from '../views/Supplier/Crud'
import Entity from '../views/Supplier/Add'

let routes = {
    path: '/suppliers',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/suppliers/add',
            name: 'Newsupplier',
            component: Entity,
            meta: {
                pageTitle: 'Supplier',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/suppliers'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/suppliers/edit/:id?',
            name: 'Editsupplier',
            component: Entity,
            meta: {
                pageTitle: 'Supplier',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/suppliers'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/suppliers',
            name: 'list-supplier',
            component: Crud,
            meta: {
                pageTitle: 'Suppliers',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
