var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationObserver',{ref:"formObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(validate)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Centrals","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"centrals"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Centrals'))+" : ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.centers,"reduce":function (center) { return center.id; },"label":'name'},on:{"input":_vm.changetUnitsCenter},model:{value:(_vm.fine.center_id),callback:function ($$v) {_vm.$set(_vm.fine, "center_id", $$v)},expression:"fine.center_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"units"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Unit'))+" : ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.units,"reduce":function (unit) { return unit.id; },"label":'name'},on:{"input":_vm.changeVehicleByUnits},model:{value:(_vm.fine.unit_id),callback:function ($$v) {_vm.$set(_vm.fine, "unit_id", $$v)},expression:"fine.unit_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Vehicle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"vehicles"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Vehicle'))+" : ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.vehicles,"reduce":function (vehicle) { return vehicle.id; },"label":'name'},model:{value:(_vm.fine.vehicle_id),callback:function ($$v) {_vm.$set(_vm.fine, "vehicle_id", $$v)},expression:"fine.vehicle_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Conductor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"professionals"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Conductor'))+" : ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.professionals,"reduce":function (professional) { return professional.id; },"label":'name'},model:{value:(_vm.fine.professional_id),callback:function ($$v) {_vm.$set(_vm.fine, "professional_id", $$v)},expression:"fine.professional_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Fine Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"fine_type"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Fine type'))+" : ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.fine_types,"reduce":function (fine_type) { return fine_type.id; },"label":'name'},model:{value:(_vm.fine.fine_type_id),callback:function ($$v) {_vm.$set(_vm.fine, "fine_type_id", $$v)},expression:"fine.fine_type_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Infraction Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"infraction_date"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Infraction date'))+" : ")]),_c('b-form-input',{attrs:{"type":"datetime-local","id":"infraction_date","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.fine.infraction_date),callback:function ($$v) {_vm.$set(_vm.fine, "infraction_date", $$v)},expression:"fine.infraction_date"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Notification Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"notification_date"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Notification date'))+" : ")]),_c('b-form-input',{attrs:{"type":"datetime-local","id":"notification_date","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.fine.notification_date),callback:function ($$v) {_vm.$set(_vm.fine, "notification_date", $$v)},expression:"fine.notification_date"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Transfer Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"transfer_date"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Transfer date'))+" : ")]),_c('b-form-input',{attrs:{"type":"date","id":"transfer_date","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.fine.transfer_date),callback:function ($$v) {_vm.$set(_vm.fine, "transfer_date", $$v)},expression:"fine.transfer_date"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Due Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"due_date"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Due date'))+" : ")]),_c('b-form-input',{attrs:{"type":"date","id":"due_date","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.fine.due_date),callback:function ($$v) {_vm.$set(_vm.fine, "due_date", $$v)},expression:"fine.due_date"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"description"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Description'))+" : ")]),_c('b-form-textarea',{attrs:{"id":"description","state":errors.length ? false : null,"rows":"3"},model:{value:(_vm.fine.description),callback:function ($$v) {_vm.$set(_vm.fine, "description", $$v)},expression:"fine.description"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Files"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"files"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Attach files'))+" : ")]),_c('upload-file',{class:{ 'is-invalid': errors.length },attrs:{"fileId":_vm.fine.files},on:{"uploaded":function (event) { _vm.fine.files = event }}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)]),_c('span',{staticClass:"required_fields"},[_c('span',{staticClass:"required"},[_vm._v("*")]),_c('strong',[_vm._v(" "+_vm._s(_vm.$t('Required Fields')))])]),_c('div',{staticClass:"block text-right"},[_c('router-link',{staticClass:"btn btn-back",attrs:{"to":"/fines"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t('To Go Back'))+" ")]),_c('b-button',{staticClass:"ml-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" "),_c('i',{staticClass:"fa fa-save"})])],1)])]}}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }