<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <ValidationObserver ref="formObserver" v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="reset">
                      <div class="form-row">
                        <div class="col-md-4">
                          <ValidationProvider name="Centrals" rules="required" v-slot="{ errors }">
                              <label for="centrals">
                                <span class="required">*</span> {{ $t('Centrals') }}:
                              </label>
                              <v-select 
                                id="centrals"
                                :options="centers" 
                                :reduce="(centers) => centers.id" 
                                :label="'name'" 
                                v-model="refueling.center_id" 
                                class="vselect" 
                                v-on:input="changetUnitsCenter" 
                                :class="{ 'is-invalid': errors.length }" />
                                <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-4">
                          <ValidationProvider name="Unit" rules="required" v-slot="{ errors }">
                              <label for="unit">
                                <span class="required">*</span> {{ $t('Unit') }}:
                              </label>
                              <v-select 
                                id="unit"
                                :options="units" 
                                :reduce="(unit) => unit.id" 
                                :label="'name'" 
                                v-model="refueling.unit_id" 
                                v-on:input="changeVehicleByUnits" 
                                class="vselect" 
                                :class="{ 'is-invalid': errors.length }" />
                                <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-4">
                            <ValidationProvider name="Vehicle" rules="required" v-slot="{ errors }">
                                <label for="Vehicle">
                                    <span class="required">*</span> {{ $t('Vehicle') }} :
                                </label>
                                <v-select :options="vehicles" :reduce="vehicle => vehicle.id" :label="'name'" v-model="refueling.vehicle_id" class="vselect" 
                                    :class="{ 'is-invalid': errors.length }" />
                                    <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-group col-md-4">
                            <ValidationProvider name="GasStation" rules="required" v-slot="{ errors }">
                                <label for="GasStation">
                                    <span class="required">*</span> {{ $t('Gas station') }} :
                                </label>
                                <v-select :options="gas_stations" :reduce="station => station.id" :label="'name'" v-model="refueling.gas_station_id" class="vselect" 
                                    :class="{ 'is-invalid': errors.length }" />
                                    <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-group col-md-2">
                            <ValidationProvider name="Refueling date" rules="required|date_format" v-slot="{ errors }">
                                <label for="Refueling_date">
                                    <span class="required">*</span> {{ $t('Refueling date') }} :
                                </label>
                                
                                <b-form-input type="date" id="Refueling_date" v-model="refueling.refueling_date" :state="errors.length ? false : null" placeholder="" />
                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </b-form-group>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-2">
                            <ValidationProvider name="Fuel Type" rules="required" v-slot="{ errors }">
                              <label for="fuel_type">
                                <span class="required">*</span> {{ $t('Fuel type') }}:
                              </label>
                              <b-form-select class="form-control" v-model="refueling.fuel_type" :class="{ 'is-invalid': errors.length }">
                                <option value="Gasolina">{{$t('Gasolina')}}</option>
                                <option value="Etanol">{{$t('Etanol')}}</option>
                                <option value="Diesel">{{$t('Diesel')}}</option>
                              </b-form-select>
                              <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-2">
                            <ValidationProvider name="Quantity (liters)" rules="required" v-slot="{ errors }">
                              <label for="Quantity">
                                <span class="required">*</span> {{ $t('Quantity (liters)') }}:
                              </label>
                              <b-form-input type="text" id="Quantity" class="form-control" v-model="refueling.liters" :class="{ 'is-invalid': errors.length }" />
                              <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-2">
                            <ValidationProvider name="Value" rules="required" v-slot="{ errors }">
                              <label for="value">
                                <span class="required">*</span> {{ $t('Value') }}:
                              </label>
                              <b-form-input type="text" id="value" class="form-control" v-model="refueling.value" placeholder="" :class="{ 'is-invalid': errors.length }" />
                              <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                            </ValidationProvider>
                          </div>
                        <div class="form-group col-md-12">
                            <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                                <label for="description">
                                    <span class="required">*</span> {{ $t('Description') }} :
                                </label>
                                <b-form-textarea id="description" v-model="refueling.description" :state="errors.length ? false : null" rows="3" />
                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </ValidationProvider>
                        </div>
                        <div class="form-group col-md-6">
                            <ValidationProvider name="Requester" rules="required" v-slot="{ errors }">
                                <label for="Requester">
                                    <span class="required">*</span> {{ $t('Requester') }} :
                                </label>
                                <v-select :options="professionals" :reduce="professional => professional.id" :label="'name'" v-model="refueling.requester_id" class="vselect" 
                                    :class="{ 'is-invalid': errors.length }" />
                                    <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-group col-md-6">
                            <ValidationProvider name="Requester" rules="required" v-slot="{ errors }">
                                <label for="Requester">
                                    <span class="required">*</span> {{ $t('Approver') }} :
                                </label>
                                <v-select :options="professionals" :reduce="professional => professional.id" :label="'name'" v-model="refueling.approver_id" class="vselect" 
                                    :class="{ 'is-invalid': errors.length }" />
                                    <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                            </ValidationProvider>
                        </div>
                        
                        <div class="col-md-12">
                          <ValidationProvider name="Attach files"  v-slot="{ errors }">
                          <div class="form-group">
                            <label for="anexo">
                              <span class="required">*</span> {{ $t('Attach files') }}:
                            </label>
                            <upload-file
                              :fileId="refueling.files"
                              @uploaded="(event) => { refueling.files = event }"
                              :class="{ 'is-invalid': errors.length }"
                            />
                            <!-- <span class="invalid-feedback">{{ $t(errors[0]) }}</span> -->
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                      <span class="required_fields">
                        <span class="required">*</span>
                        <strong>{{ $t('Required Fields') }}</strong>
                      </span>
                      <ActionButtons backLink="/refuelings"   @reset="onReset"  />
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import UploadFile from "../../layouts/components/UploadFile";
  import { BForm, BFormGroup, BFormInput, BFormTextarea, BFormSelect, BFormInvalidFeedback, BButton } from "bootstrap-vue";
  import vSelect from "vue-select";
  import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
 import { required } from "vee-validate/dist/rules";
  import ActionButtons from '../../layouts/components/Form/ActionButtons.vue';
 extend("required", required);

  export default {
      data() {
          return {
              refueling: {
                  status: "1",
                  center_id: '',
                  unit_id: '',
                  vehicle_id: '',
                  gas_station_id: '',
                  refueling_date: '',
                  fuel_type: '',
                  liters: '',
                  value: '',
                  description: '',
                  requester_id: '',
                  approver_id: '',
              },
              centers: [],
              units: [],
              vehicles: [],
              professionals: [],
              gas_stations: [],
              errors: undefined,
              previewImage: null,
              money: {
                  prefix: 'R$ ',
              }
          };
      },
      computed: {},
      methods: {
  
          changetUnitsCenter(center_id) {
              this.refueling.unit_id = ''
              this.getUnitsCenter(center_id)
          },
          changeVehicleByUnits(unit_id) {
              this.refueling.vehicle_id = ''
              this.getVehicles(unit_id)
          },
          getUnitsCenter: function (center_id) {
              const self = this;
              const api = `${self.$store.state.api}centers/unit/${center_id}`;
              axios
                  .get(api)
                  .then((response) => {
                      self.units = response.data;
  
                  })
                  .catch((error) => {
                    self.$message(null, self.$t(error.response.data.error), "error");
                  });
          },
          onReset:function(){
            this.refueling = {
                  status: "1",
                  center_id: '',
                  unit_id: '',
                  vehicle_id: '',
                  gas_station_id: '',
                  refueling_date: '',
                  fuel_type: '',
                  liters: '',
                  value: '',
                  description: '',
                  requester_id: '',
                  approver_id: '',
              }
              this.$refs.formObserver.reset();
              this.$nextTick(() => {
                this.init(); 
              });

          },
          async onSubmit() {
          await this.save();
          this.onReset()
          this.$router.push("/refuelings");
        },
  
          save: function () {
              const self = this;
              let api = self.$store.state.api + "refuelings";
  
              if (self.refueling.id) {
                  self.refueling._method = "PUT";
                  api += "/" + self.refueling.id;
              }
  
              axios
                  .post(api, self.refueling)
                  .then((response) => {
                      self.$message(
                         this.$t('Success'),
                          this.$t('Information saved successfully'),
                          "success"
                      );
                      
                  })
                  .catch((error) => {
                    self.$message(null, self.$t(error.response.data.error), "error");
                  });
          },
          getRefueling: function (id) {
              const self = this;
              const api = self.$store.state.api + "refuelings/" + id;
  
              axios
                  .get(api)
                  .then((response) => {
                      self.refueling = response.data.data[0];
                      self.getUnitsCenter(self.refueling.center_id);
                      self.getVehicles(self.refueling.unit_id)
                  })
                  .catch((error) => {
                    self.$message(null, self.$t(error.response.data.error), "error");
                  });
          },
  
          getCenters: function () {
              const self = this;
              const api = self.$store.state.api + "centers/";
  
              axios
                  .get(api)
                  .then((response) => {
                      self.centers = response.data.data;
                  })
                  .catch((error) => {
                    self.$message(null, self.$t(error.response.data.error), "error");
                  });
          },
  
          getVehicles: function (unit_id) {
              const self = this;
              const api = self.$store.state.api + "units/vehicles/" + unit_id;
  
              axios
                  .get(api)
                  .then((response) => {
                      self.vehicles = response.data;
                  })
                  .catch((error) => {
                    self.$message(null, self.$t(error.response.data.error), "error");
                  });
          },
          getProfessionals: function () {
              const self = this;
              const api = self.$store.state.api + "professionals/";
  
              axios
                  .get(api)
                  .then((response) => {
                      self.professionals = response.data.data;
                  })
                  .catch((error) => {
                    self.$message(null, self.$t(error.response.data.error), "error");
                  });
          },
  
          getGasStations: function () {
              const self = this;
              const api = self.$store.state.api + "gasstations/";
  
              axios
                  .get(api)
                  .then((response) => {
                      self.gas_stations = response.data.data;
                  })
                  .catch((error) => {
                    self.$message(null, self.$t(error.response.data.error), "error");
                  });
          },
  
      },
      mounted: function () {
          const self = this;
          let id = self.$route.params.id;
  
          self.getCenters();
          self.getProfessionals();
          self.getGasStations();
  
          if (id) {
              self.getRefueling(id);
          }
      },
      components: {
        ValidationObserver,
        ValidationProvider,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BFormSelect,
        BFormInvalidFeedback,
        BButton,
        vSelect,
        UploadFile,
        ActionButtons,
      },
  };
  </script>
  
  <style scoped>
  .profile_user {
      text-align: refueling;
  }
  
  .photo {
      display: block;
      margin-left: 5px;
      width: 18%;
      border-radius: 10px;
      max-width: 400px;
      max-height: 400px;
      object-fit: cover;
  }
  
  .btn-user:hover,
  .btn-user[aria-expanded="true"] {
      background: linear-gradient(to right, #000, #666);
      color: #fff !important;
  }
  
  .photo-title {
      color: #000;
      font-size: 18px;
      display: block;
      margin-left: 5px;
      width: 18%;
  }
  </style>
  