
import Index from '../views/OperationalPanel/Index'


let routes = {
    path: '/operational_panel',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
      
        {
            path: '/operational_panel',
            name: 'list-operational_panel',
            component: Index,
            meta: {
                pageTitle: 'operational panel',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
