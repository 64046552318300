<template>
<div>
    <img :src="url" :alt="name" />
</div>
</template>

<script>

export default {
    data() {
        return {
            uploadFile: {
                name: '',
                url: '',
            }

        };
    },
    props: {
        fileId: {
            type: String,
            default: '',
        },
    },
    computed: {
        id() {
            return this.fileId;
        },
        url() {
            return this.uploadFile.url
        },
        name() {
            return this.uploadFile.name
        }

    },
    methods: {
        getFile(id) {
            let api = this.$store.state.api + "uploadfiles/" + id;
            axios
                .get(api)
                .then((response) => {
                    this.uploadFile = response.data.data[0];
                })
                .catch((error) => {
                    this.$error(error);
                });

        }

    },
    watch: {
        id: function (newId, oldId) {
            // Faça uma solicitação ao backend para carregar dados com base no novo id
            if (newId && newId != oldId) {

                this.getFile(newId);
            }
        }
    }

}
</script>

<style scoped>
    </style>
