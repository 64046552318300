<!-- ModalNovoItem.vue -->
<template>
<b-modal id="modal-1" >
    <entity-modal />
</b-modal>
</template>

  
  
<script>
import EntityModal from '../../Vehicle/Add'

export default {
    components: {
        EntityModal,
        
    },
    data() {
        return {
            newItemName: ''
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        saveItem() {
            if (this.newItemName.trim() === '') {
                return; // Impedir a adição de itens vazios
            }
            // Emitir o evento "save" com o novo item
            this.$emit('save', {
                id: Math.random(), // Isso é apenas para fins de demonstração, substitua com sua lógica real de geração de ID
                name: this.newItemName
            });
            this.newItemName = ''; // Limpar o campo de texto
        }
    }
};
</script>
  
  
<style scoped>
/* Estilos para o modal, ajuste conforme necessário */
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
</style>
