<template>
<div>
    <div class="row">
        <div class="col-md-12">
            <base-crud :ref="'area_catagoryCrud'" :crudName="$t('Catagory of area')" :newText="$t('Add')+ '  '+$t('New')" :table="table" :columns="columns" :options="options" :endPoint="endPoint" :enableAdd="true" :enableEdit="true" :enableDelete="true" :enableView="false" :enableReset="false" :dataForm="dataForm">
            </base-crud>
        </div>
    </div>
</div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
    data: function () {
        return {
            table: "area_catagoryTable",
            showMessageImport: false,
            area_catagoryCrud: {},
            pages: [],
            url: "",
            columns: [
                "id",
                "name",
                "image",
                "actions",
            ],
            tableData: ["id", "name","image"],
           optionsOrigin: {
                filterByColumn: true,
                debounce: 1000,
                filterable: ["name"],
                pagination: {
                    chunk: 10,
                    dropdown: false,
                    nav: "scroll"
                },
                perPage: 10,
                perPageValues: [10, 25, 50, 100],
                headings: {
                    id: this.$t('id'),
                    name: this.$t('name'),
                    image: this.$t('image'),
                    actions:  this.$t('action'),
                },

                texts: {
                    filterBy: this.$t('Filter'),
                    defaultOption: this.$t('Select'),
                },
                listColumns: {

                },
                templates: {
                    image: function (h, row, index) {
                        return row.upload_files ? h('b-avatar', { props: { src: row.upload_files.url, size: '2rem' } }) : '';
                    },
                },
                requestFunction: function (data) {
                    let requestData = {};
                    let query = this.$parent.$parent.$parent.query(data.query);
                    requestData.params = data;
                    requestData.params.query = "";
                    requestData.params.with = ["uploadFiles"];

                    return axios.get(this.url + "?" + query, requestData).catch(
                        function (e) {
                            this.dispatch("error", e);
                        }.bind(this)
                    );
                },
            },
            endPoint: "areacatagories",
            dataForm: {},
        };
    },
    components: {
        BaseCrud,
    },
    computed: {
        options() {
            const headings = {
                id: this.$t('id'),
                    name: this.$t('name'),
                    image: this.$t('image'),
                    actions:  this.$t('action'),
            }
            const texts = {
                filterBy: this.$t('Filter'),
                defaultOption: this.$t('Select'),
            }
            return {
                ...this.optionsOrigin,
                texts,
                headings,
            }
        }
    },
    methods: {
        makeFormData: function () {
            const self = this;
            let fd = new FormData();

            fd.append("fileImport", "");

            return fd;
        },
        query: function (query) {
            let columns = {
                id: this.$t('id'),
                actions:this.$t('action'),

            };
            let filters = "";
            $.each(query, function (index, value) {
                filters += columns[index] + "=" + value + "&";
            });
            return filters;
        },
        openInput() {},
    },
};
</script>

<style scoped>
.VuePagination {
    text-align: center;
}


</style>
