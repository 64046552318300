
import Crud from '../views/GasStation/Crud'
import Entity from '../views/GasStation/Add'

let routes = {
    path: '/gasstations',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/gasstations/add',
            name: 'NewGasStation',
            component: Entity,
            meta: {
                pageTitle: 'Gas station',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/gasstations'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/gasstations/edit/:id?',
            name: 'EditGasStation',
            component: Entity,
            meta: {
                pageTitle: 'Gas station',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/gasstations'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/gasstations',
            name: 'list-gasstations',
            component: Crud,
            meta: {
                pageTitle: 'Gas station',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
