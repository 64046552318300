<template>
    <div>
        <div class="main-content">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                                        <form @submit.prevent="handleSubmit(save)">
                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                <ValidationProvider name="Type of address" rules="required" v-slot="{ errors }">
                                                        <label for="Name">
                                                            <span class="required">*</span> {{ $t('Type of address') }} :
                                                        </label>
                                                        <input type="text" id="Name" class="form-control" v-model="address_type.name"
                                                               :class="{ 'is-invalid': errors.length }" placeholder="" />
                                                        <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <span class="required_fields">
                                                <span class="required">*</span>
                                                <strong> {{ $t('Required Fields') }}</strong>
                                            </span>
                                            <div class="block text-right">
                                                <router-link to="/address_type" class="btn btn-back">
                                                    <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                                                </router-link>
                                                <button type="submit" class="btn btn-add ml-1">
                                                    {{ $t('Save') }}
                                                    <i class="fa fa-save"></i>
                                                </button>
                                            </div>
                                        </form>
                                    </ValidationObserver>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
    import { required } from "vee-validate/dist/rules";
    
    extend("required", {
      ...required,
      message: "This field is required"
    });
    
    export default {
        data() {
            return {
                address_type: {
                    name: ""
                },
                errors: undefined,
                previewImage: null,
            };
        },
        methods: {
            save() {
                const self = this;
                let api = self.$store.state.api + "address_type";
    
                if (self.address_type.id) {
                    self.address_type._method = "PUT";
                    api += "/" + self.address_type.id;
                }
    
                axios
                    .post(api, self.address_type)
                    .then(() => {
                        self.$message(
                            this.$t('Success'),
                            this.$t('Information saved successfully'),
                            "success"
                        );
                        this.$router.push("/address_type");
                    })
                    .catch((error) => {
                        this.$error(error);
                    });
            },
            getAddresseType(id) {
                const self = this;
                const api = self.$store.state.api + "address_type/" + id;
    
                axios
                    .get(api)
                    .then((response) => {
                        self.address_type = response.data.data[0];
                    })
                    .catch((error) => {
                        this.$error(error);
                    });
            },
        },
        mounted() {
            const self = this;
            let id = self.$route.params.id;
    
            if (id) {
                self.getAddresseType(id);
            }
        },
        components: {
            ValidationObserver,
            ValidationProvider
        },
    };
    </script>
    
    <style scoped>
    
    </style>
    