var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationObserver',{ref:"formObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validate = ref.validate;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(validate)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Operator","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"operator"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Operator'))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.operators,"reduce":function (operator) { return operator.id; },"label":'name'},model:{value:(_vm.chip.operator_id),callback:function ($$v) {_vm.$set(_vm.chip, "operator_id", $$v)},expression:"chip.operator_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group input-group-sm col-md-6"},[_c('ValidationProvider',{attrs:{"name":"ICCID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"iccid"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Integrated Circuit Card Identifier'))+": ")]),_c('b-form-input',{attrs:{"type":"text","id":"iccid","state":errors.length ? false : null},model:{value:(_vm.chip.iccid),callback:function ($$v) {_vm.$set(_vm.chip, "iccid", $$v)},expression:"chip.iccid"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group input-group-sm col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Telephone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Phone"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Telephone'))+": ")]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(99) 9999-9999'),expression:"'(99) 9999-9999'"}],attrs:{"type":"text","id":"Phone","state":errors.length ? false : null},model:{value:(_vm.chip.telephone),callback:function ($$v) {_vm.$set(_vm.chip, "telephone", $$v)},expression:"chip.telephone"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)]),_c('span',{staticClass:"required_fields"},[_c('span',{staticClass:"required"},[_vm._v("*")]),_c('strong',[_vm._v(_vm._s(_vm.$t('Required Fields')))])]),_c('div',{staticClass:"block text-right"},[_c('router-link',{staticClass:"btn btn-back",attrs:{"to":"/chips"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t('To Go Back'))+" ")]),_c('b-button',{staticClass:"ml-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" "),_c('i',{staticClass:"fa fa-save"})])],1)])]}}])})],1)])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }