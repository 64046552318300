<template>
<div class="card">

    <div class="card-body row">
        <div class="col-md-12">

            <h4>
                {{$t('Situation') }}
                <b-badge  :variant="props.situation == 1?'danger':'warning'">{{ props.situation == 1? $t('Expired'): $t('Expiry on') }} </b-badge>
    
            </h4>
        </div>
        <div class="col-md-4"> {{ $t('Payment date') }} : {{ formtaDate(props.payment_date)  }} </div>
        <div class="col-md-4"> {{ $t('Requester') }} :{{ props.requester }} </div>
        <div class="col-md-4"> {{ $t('Liability insurance') }} :{{ props.liability_insurance }} </div>
        <div class="col-md-4"> {{ $t('Licensing value') }} :{{ formattedMoney(props.licensing_value) }} </div>
        <div class="col-md-4"> {{ $t('Ipva value') }} :{{ formattedMoney(props.ipva_value) }} </div>
        <div class="col-md-4"> {{ $t('Description') }} :{{ props.description }} </div>

       

    </div>
    <div class="card-footer">
        <div class="form-group col-md-12">
            <b-button v-if="props.file_url" pill variant="outline-primary" :href="props.file_url" download target="_blank">
                {{ props.file_name}} <i class="fa fa-download"></i>
            </b-button>
        </div>

    </div>
</div>
</template>

<script>
export default {
    props: {
        props: {
            type: Object,
            required: true,
        }

    },
    methods:{
        formtaDate(date) {
            if(date){
                return moment(date).format('DD/MM/YYYY')

            }
            return ''

        },
        formattedMoney(money) {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
            }).format(money)
        },
    }
}
</script>
<!-- 
"ipva_value": "123.22",
"licensing_value": "15.21",
"liability_insurance": "0.11",
"payment_date": "2024-04-29",
"situation": "A vencer",
"description": "bnbmn",
"requester": "Luan de Camargo",
"file_name": "Captura de tela 2023-12-29 153645.png",
"file_url": "http://127.0.0.1:8989/uploads/Nn6KAca4QwiO0fyEMiaO_Captura de tela 2023-12-29 153645.png" -->

<!-- ipva_value: {
    type: String,
    default: '',
},
licensing_value: {
    type: String,
    default: '',
},
liability_insurance: {
    type: String,
    default: '',
},
payment_date: {
    type: String,
    default: '',
},
situation: {
    type: String,
    default: '',
},
description: {
    type: String,
    default: '',
},
requester: {
    type: String,
    default: '',
},
file_name: {
    type: String,
    default: '',
},
file_url: {
    type: String,
    default: '',
}, -->
