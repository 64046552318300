<template>
<div>
    <div class="row " v-if="list">
        <div class="col-md-6">

            <v-select :options="listOrigin" :reduce="(item) => item.id" :label="'name'" v-model="newItem" class="vselect" />
        </div>

        <span style="margin-top: 7px;">
            <b-badge v-if="newItem" @click="adicionarItem" href="#" variant="primary" v-b-tooltip.hover :title="$t('Add')"><i class="fa fa-plus"></i></b-badge>
        </span>

    </div>
    <div lass="row mt-2 mb-2  ">
        <div class="col-md-3 bg-light border border-white  rounded  text-right" v-for="item in itemsSelected" :key="item.area_id">

            <b-badge class="mt-2" @click="removeItem(item)" pill variant="danger" v-b-tooltip.hover :title="$t('delete')"><i class="fa fa-minus"></i></b-badge>
            <div class="text-left  pr-1">
                <b-button variant="link" :href="`/${model}/edit/${item.area_id}`" target="_blank" v-b-tooltip.hover :title="$t('view')">
                    {{ item.name }}
                </b-button>
            </div>

        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            newItem: null,
            itens: [],

        };
    },
    props: {
        list: {
            type: Array,
            default: [],
        },
        listSelected: {
            type: Array,
            default: [],
        },
        model: {
            type: String,
            default: '',
        },
    },
    computed: {
        listOrigin() {
            if (!Array.isArray(this.list) && !Array.isArray(this.itens)) {
                return []
            }
            if (!Array.isArray(this.itens)) {
                return this.list ?? [];
            }

            return this.list.filter(item => !this.itemsSelected.some(objeto => objeto.area_id === item.id));
        },
        itemsSelected() {
            return this.listSelected ?? [];
        }

    },
    methods: {
        adicionarItem() {
            if (this.newItem) {
                const itemSelecionado = this.list.find(item => item.id === this.newItem);
                if (itemSelecionado) {
                    const itens = this.itemsSelected;
                    itens.push({
                        area_id: itemSelecionado.id,
                        name: itemSelecionado.name,
                    });
                    this.newItem = null;
                    this.$emit('updateList', itens)

                }
            }

        },
        removeItem(item) {
            if(!item.id){
                const itens = this.itemsSelected;
                const indice = itens.indexOf(item);
                if (indice !== -1) {
                    itens.splice(indice, 1);
                    this.$emit('updateList', itens)
                }
            }
            this.$emit('removeItem', item)
        }
    },

}
</script>
<!-- props: {
    list: [],
    value: '',
    key: ''
}, -->
