import Crud from '../views/AreaCatagory/Crud'
import Entity from '../views/AreaCatagory/Add'

let routes = {
    path: '/areacatagories',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/areacatagories/add',
            name: 'NewAreacatagory',
            component: Entity,
            meta: {
                pageTitle: 'Catagories of Area',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/areacatagories'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/areacatagories/edit/:id?',
            name: 'EditAreaCatagory',
            component: Entity,
            meta: {
                pageTitle: 'Catagories of Area',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/areacatagories'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/areacatagories',
            name: 'list-areacatagories',
            component: Crud,
            meta: {
                pageTitle: 'Catagories of Area',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
