<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <ValidationObserver ref="formObserver" v-slot="{ validate }">
                    <b-form @submit.prevent="handleSubmit(validate)">
                      <div class="form-row">
                        <ValidationProvider name="Profile Type" rules="required" v-slot="{ errors }">
                          <div class="form-group col-md-6">
                            <b-form-group label-for="Name" :label="$t('Profile Type')">
                              
                              <b-form-input
                                autocomplete="new-password"
                                type="text"
                                id="Name"
                                v-model="profile_type.name"
                                :state="errors.length ? false : null"
                                placeholder=""
                              />
                              <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </b-form-group>
                          </div>
                        </ValidationProvider>

                        <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                          <div class="form-group col-md-6">
                            <b-form-group label-for="Status" :label="$t('Status')">
                              
                              <b-form-select v-model="profile_type.status" :state="errors.length ? false : null">
                                <option value="1">{{ $t('Active') }}</option>
                                <option value="0">{{ $t('Inactive') }}</option>
                              </b-form-select>
                              <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </b-form-group>
                          </div>
                        </ValidationProvider>
                      </div>
                      <span class="required_fields">
                        <span class="required">*</span>
                        <strong>{{ $t('Required Fields') }}</strong>
                      </span>
                      <div class="block text-right">
                        <router-link to="/profile_types" class="btn btn-back">
                          <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                        </router-link>
                        <b-button type="submit" variant="primary" class="ml-1">
                          {{ $t('Save') }}
                          <i class="fa fa-save"></i>
                        </b-button>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { BForm, BFormGroup, BFormInput, BFormSelect, BFormInvalidFeedback, BButton } from "bootstrap-vue";
import axios from "axios";

extend("required", required);

export default {
  data() {
    return {
      profile_type: {
        name: '',
        company_id: '',
        status: '1',
      },
      errors: undefined,
      previewImage: null,
    };
  },
  computed: {
    companyId() {
      return this.$store.state.user.company_id;
    }
  },
  methods: {
    async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
    save() {
      let api = this.$store.state.api + "profile_types";
      this.profile_type.company_id = this.companyId;

      if (this.profile_type.id) {
        this.profile_type._method = "PUT";
        api += "/" + this.profile_type.id;
      }

      axios
        .post(api, this.profile_type)
        .then(() => {
          this.$message(this.$t('Success'), this.$t('Information saved successfully'), "success");
          this.$router.push("/profile_types");
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getProfileType(id) {
      let api = this.$store.state.api + "profile_types/" + id;

      axios
        .get(api)
        .then((response) => {
          this.profile_type = response.data.data[0];
        })
        .catch((error) => {
          this.$error(error);
        });
    },
  },
  mounted() {
    const id = this.$route.params.id;

    if (id) {
      this.getProfileType(id);
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormInvalidFeedback,
    BButton,
  },
};
</script>

<style scoped>
.required {
  color: red !important;
}
</style>
