<template>
<div>

    <div class="row bg-light m-1 h-25 border border-white rounded fs-11" style="font-size:9px;">
        <!-- handleCloseItem -->
        <div class="col-md-12" :class="classBtn" >

            <a class="btn btn-back btn-sm pl-n2" :class="classBtn" @click="$emit('handleCloseItem', false)" >
                <i :class="iconClose"></i> {{ $t('To Go Back') }}
            </a>
        </div>
        <div class="col-md-4 ">
            <div class="row">

                <div class="col-md-3 pt-1 pl-4">
                    <type-image :especialidade="item.especialidade_veiculo_sigla" :gps="item.veiculo_situacao == 'A'? true : false" />
                    <p v-text="item.especialidade_veiculo_sigla"></p>
                </div>
                <div class="col-md-8 text-center mt-2">
                    <div class="badge bg-warning  text-wrap mb-1" v-if="!item.ocorrencia_id">Sem Ocorrência </div>
                    <div class="badge bg-primary  text-wrap mb-1" v-else="item.ocorrencia_id " v-text="'Ocor: '+ item.ocorrencia_id"> </div>
                    <div v-text="item.veiculo_numero+ ' / '+item.veiculo_placa">USA 01 (AM 73) / FZH5F21</div>
                    <div v-text="item.unidade_nome"></div>
                    <div v-text="item.especialidade_veiculo_nome"></div>
                </div>
            </div>
        </div>
        <div class="col-md-8 text-center ">
            <div class="row pl-1 m-1">
                <time-ball variant="bg-success" title="Base" :timeText="item.extchegadabasedt" />
                <line-join />
                <time-ball variant="bg-warning" title="Empenhado" :timeText="item.extenvioequipedt" />
                <line-join />
                <time-ball variant="bg-warning" title="Cam. Ocor." :timeText="item.extsaidabasedt" />
                <line-join />
                <time-ball variant="bg-warning" title="Ocorrência" :timeText="item.extchegadalocaldt" />
                <line-join />
                <time-ball variant="bg-warning" title="Saída Ocor." :timeText="item.extsaidalocaldt" />
                <line-join />
                <time-ball variant="bg-warning" title="Destino" :timeText="item.extchegadadestinodt" />
                <line-join />
                <time-ball variant="bg-success" title="Retorno" :timeText="item.extretornodestinodt" />
            </div>

        </div>

        <div class="row mt-n2 mb-2 ml-2 " v-if="item.ocorrencia_id">
            <div class="ml-2 mr-1 font-weight-bold">
                Ocorrência: {{ item.extocorrenciaid }}
            </div>
            <div class="ml-2 mr-1 font-weight-bold">
                Apelido: {{ item.extapelidods }}
            </div>
            <div class="ml-2 mr-1 font-weight-bold">
                {{ $t('Name') }} : {{ item.extmotivonm }}
            </div>

            <div class="ml-2 mr-1 font-weight-bold">
                Risco: {{ item.extrisconm }}
            </div>

            <div class="ml-2 mr-1 font-weight-bold">
                Ponto de Referência: {{ item.extreferencianm}}
            </div>

            <div class="ml-2 mr-1 font-weight-bold">
                Endereço:{{ item.extlogradouronm }} -{{ item.extbairronm? item.extbairronm :'' }} - {{ item.extmunicipionm }}
            </div>
        </div>
    </div>
</div>
</template>

<script>
import TimeBall from './TimeBall';
import LineJoin from './TimeLineJoin';
import TypeImage from './TypeImage';

export default {
    components: {
        TimeBall,
        LineJoin,
        TypeImage,
    },
    props: {
        item: {
            type: Object,
            required: true
        },
       
    },
    computed:{
        iconClose(){
        //    return ['he', 'ar'].includes(this.$i18n.locale)? 'fa fa-arrow-right':'fa fa-arrow-left'
        return'fa fa-arrow-left'

        },
        classBtn() {
           return ['he', 'ar'].includes(this.$i18n.locale)? 'text-left' : 'text-right'
        }
    } 
}
</script>

<style scoped>
/* Estilos específicos do componente */
</style>
