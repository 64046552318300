<template>
    <div>
        <img :src="imageSrc" alt="Ambulance Image" />
        <img v-if="gps" :src="imageGpsSrc" alt="Ambulance  GPS"  class="mt-n2"/>
    </div>
</template>

    
    
<script>
const initialSrcImage ={
    'VIR': require("@/assets/images/resgate/ambulanceVIR.png"),
    'USA': require("@/assets/images/resgate/ambulanceUSA.png"),
    'USB': require("@/assets/images/resgate/ambulanceUSB.png"),
    'USI': require("@/assets/images/resgate/ambulanceUSI.png"),
    'VT': require("@/assets/images/resgate/ambulanceVT.png"),
    'MOT': require("@/assets/images/resgate/ambulanceMOT.png"),
    'AV': require("@/assets/images/resgate/aviao.png"),
    'HEL': require("@/assets/images/resgate/ambulanceH.png"),
    'DES': require("@/assets/images/resgate/ambulanceM.png"),
    'LAN': require("@/assets/images/resgate/ambulanceM.png"),
    'TNT': require("@/assets/images/resgate/ambulanceM.png"),
    
}

const HAS_GPS = require("@/assets/images/resgate/avlIcon.png")

export default {
    props: {
        especialidade: {
            type: String,
            default: '',
        },
        gps: {
            type: Boolean,
            default: false,
        },
    },
       computed: {
        imageSrc: function() {
            return  initialSrcImage[this.especialidade]??initialSrcImage['USA'] ;
        },
        imageGpsSrc: function() {
            return HAS_GPS;
        }
      
    },


}
</script>
    
    
<style scoped>
    </style>
