<template>
    <div class="main-content">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <ValidationObserver ref="formObserver" v-slot="{ validate }">
                            <b-form @submit.prevent="handleSubmit(validate)">
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                    <ValidationProvider name="Event type" rules="required" v-slot="{ errors }">
                                            <b-form-group label-for="eventType" :label="$t('Event type')">
                                                
                                                <v-select
                                                    :options="eventTypes"
                                                    :reduce="(eventType) => eventType.id"
                                                    :label="'name'"
                                                    v-model="sensor.event_type_id"
                                                    class="vselect"
                                                    :class="{ 'is-invalid': errors.length }" />
                                                    <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
    
                                    <div class="form-group input-group-sm col-md-6">
                                    <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                                            <b-form-group label-for="NameSensor" :label="$t('Name')">
                                                
                                                <b-form-input
                                                    type="text"
                                                    id="NameSensor"
                                                    v-model="sensor.name"
                                                    :state="errors.length ? false : null"
                                                    placeholder=""
                                                />
                                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
    
                                    <div class="form-group input-group-sm col-md-6">
                                        <b-form-group label-for="ValueMinimum" :label="$t('Value minimum')">
                                            <b-form-input
                                                type="text"
                                                id="ValueMinimum"
                                                v-model="sensor.value_minimum"
                                                placeholder=""
                                            />
                                        </b-form-group>
                                    </div>
    
                                    <div class="form-group input-group-sm col-md-6">
                                        <b-form-group label-for="ValueMaximum" :label="$t('Value maximum')">
                                            <b-form-input
                                                type="text"
                                                id="ValueMaximum"
                                                v-model="sensor.value_maximum"
                                                placeholder=""
                                            />
                                        </b-form-group>
                                    </div>
    
                                    <div class="form-group input-group-sm col-md-6">
                                        <b-form-group label-for="NumberSamples" :label="$t('Number sample')">
                                            <b-form-input
                                                type="text"
                                                id="NumberSamples"
                                                v-model="sensor.number_samples"
                                                placeholder=""
                                            />
                                        </b-form-group>
                                    </div>
    
                                    <div class="form-group input-group-sm col-md-6">
                                        <b-form-group label-for="valueViolation" :label="$t('Value violation')">
                                            <b-form-input
                                                type="text"
                                                id="valueViolation"
                                                v-model="sensor.value_violation"
                                                placeholder=""
                                            />
                                        </b-form-group>
                                    </div>
    
                                    <div class="form-group input-group-sm col-md-12">
                                        <b-form-group label-for="description" :label="$t('Description')">
                                            <b-form-input
                                                type="text"
                                                id="description"
                                                v-model="sensor.description"
                                                placeholder=""
                                            />
                                        </b-form-group>
                                    </div>
                                </div>
                                <span class="required_fields">
                                    <span class="required">*</span>
                                    <strong> {{ $t('Required Fields') }} </strong>
                                </span>
                                <div class="block text-right">
                                    <router-link to="/sensors" class="btn btn-back">
                                        <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                                    </router-link>
                                    <b-button type="submit" variant="primary" class="ml-1">
                                        {{ $t('Save') }}
                                        <i class="fa fa-save"></i>
                                    </b-button>
                                </div>
                            </b-form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
    import { required } from "vee-validate/dist/rules";
    import { BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton } from "bootstrap-vue";
    import vSelect from "vue-select";
    import axios from "axios";
    
    extend("required", required);
    
    export default {
        data() {
            return {
                sensor: {
                    name: '',
                    event_type_id: '',
                    value_minimum: '',
                    value_maximum: '',
                    number_samples: '',
                    value_violation: '',
                    description: '',
                },
                eventTypes: [],
                errors: undefined,
            };
        },
        computed: {},
        methods: {
            async handleSubmit(validate) {
                const isValid = await validate();
                if (isValid) {
                    this.save();
                }
            },
            save() {
                let api = this.$store.state.api + "sensors";
    
                if (this.sensor.id) {
                    this.sensor._method = "PUT";
                    api += "/" + this.sensor.id;
                }
    
                axios
                    .post(api, this.sensor)
                    .then(() => {
                        this.$message(
                            this.$t('Success'),
                            this.$t('Information saved successfully'),
                            "success"
                        );
                        this.$router.push("/sensors");
                    })
                    .catch((error) => {
                        this.$error(error);
                    });
            },
            getSensor(id) {
                let api = this.$store.state.api + "sensors/" + id;
    
                axios
                    .get(api)
                    .then((response) => {
                        this.sensor = response.data.data[0];
                    })
                    .catch((error) => {
                        this.$error(error);
                    });
            },
            getEventTypes() {
                let api = this.$store.state.api + "eventtypes";
    
                axios
                    .get(api)
                    .then((response) => {
                        this.eventTypes = response.data.data;
                    })
                    .catch((error) => {
                        this.$error(error);
                    });
            },
        },
        mounted() {
            let id = this.$route.params.id;
    
            if (id) {
                this.getSensor(id);
            }
    
            this.getEventTypes();
        },
        components: {
            vSelect,
            ValidationObserver,
            ValidationProvider,
            BForm,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton,
        },
    };
    </script>
    
    <style scoped>
    .required {
        color: red !important;
    }
    </style>
    