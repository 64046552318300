<template>
<div class="row">
    <div class="col-md-12">
        <base-crud :ref="'operatorsCrud'" :crudName="$t('Operators')" :newText="$t('Add')+ '  '+$t('New')" :table="table" :columns="columns" :options="options" :endPoint="endPoint" :enableAdd="true" :enableEdit="true" :enableDelete="true" :enableView="false" :enableReset="false" :dataForm="dataForm">
        </base-crud>
    </div>
</div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
    data: function () {
        return {
            table: "operatorsTable",
            showMessageImport: false,
            operatorsCrud: {},
            pages: [],
            url: "",
            columns: [
                "id",
                "name",
                "apn_url",
                "apn_user",
                "actions",
            ],
            tableData: ["id", "name"],
            optionsOrigin: {
                filterByColumn: true,
                debounce: 1000,
                filterable: ["name", "apn_user"],
                pagination: {
                    chunk: 10,
                    dropdown: false,
                    nav: "scroll"
                },
                perPage: 10,
                perPageValues: [10, 25, 50, 100],
                headings: {
                    id: this.$t('Id'),
                    name: this.$t('name'),
                    apn_url: this.$t('Url'),
                    apn_user: this.$t('User'),
                    actions: this.$t('action'),
                },

                texts: {
                    filterBy: this.$t('Filter'),
                    defaultOption: this.$t('Select'),
                },
                listColumns: {

                },
                requestFunction: function (data) {
                    let requestData = {};

                   

                    let query = this.$parent.$parent.$parent.query(data.query);
                    requestData.params = data;
                    requestData.params.query = "";

                    return axios.get(this.url + "?" + query, requestData).catch(
                        function (e) {
                            this.dispatch("error", e);
                        }.bind(this)
                    );
                },
            },
            endPoint: "operators",
            dataForm: {},
        };
    },
    components: {
        BaseCrud,
    },
    computed: {
        options() {
            const headings = {
                id: this.$t('Id'),
                name: this.$t('name'),
                apn_url: this.$t('Url'),
                apn_user: this.$t('User'),
                actions: this.$t('action'),
            }

            const texts = {
                filterBy: this.$t('Filter'),
                defaultOption: this.$t('Select'),
            }
            return {
                ...this.optionsOrigin,
                texts,
                headings,
            }

        }

    },
    methods: {
        query: function (query) {
            let columns = {
                id: "id",
                name: "name",
                apn_url: "apn_url",
                apn_user: "apn_user",
            };
            let filters = "";
            $.each(query, function (index, value) {
                filters += columns[index] + "=" + value + "&";
            });
            return filters;
        },

    },
};
</script>

<style scoped>
.VuePagination {
    text-align: center;
}
</style>
