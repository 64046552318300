

import Crud from '../views/Chips/Crud'
import Entity from '../views/Chips/Add'

const routes = {
    path: '/chips',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/chips/add',
            name: 'NewChips',
            component: Entity,
            meta: {
                pageTitle: 'Chips',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to: '/chips',
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/chips/edit/:id?',
            name: 'EditChips',
            component: Entity,
            meta: {
                pageTitle: 'Chips',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to: '/chips',
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/chips',
            name: 'list-Chips',
            component: Crud,
            meta: {
                pageTitle: 'Chips',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
