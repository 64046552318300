<template>
<div class="card bg-light">

    <div class="card-body row">
        <div class="col-md-4"> {{ $t('Insurer') }} : {{ props.insurance }} </div>
        <div class="col-md-4"> {{ $t('Policy') }} : {{ props.policy }} </div>
        <div class="col-md-4"> {{ $t('C.I') }} : {{ props.ci }} </div>
        <div class="col-md-4"> {{ $t('Hiring date') }} : {{ formtaDate(props.hiring_date) }} </div>
        <div class="col-md-4"> {{ $t('Validity') }} : {{ formtaDate(props.validity) }} </div>
        <div class="col-md-4"> {{ $t('Value') }} : {{ formattedMoney(props.value) }} </div>
        <div class="col-md-4"> {{ $t('Requester') }} :{{ props.requester }} </div>
        <div class="col-md-4"> {{ $t('Approver') }} :{{ props.approver }} </div>
        <div class="col-md-4"> {{ $t('Description') }} : {{ props.description }} </div>

    </div>
    <div class="card-footer row">
        <div class="form-group col-md-6">
            <b-button v-if="props.vehicle_doc_url" pill variant="outline-primary" :href="props.vehicle_doc_url" download target="_blank">
                {{ $t('Vehicle') }} <i class="fa fa-download"></i>
            </b-button>
        </div>
        <div class="form-group col-md-6">
            <b-button v-if="props.policy_doc_url" pill variant="outline-primary" :href="props.policy_doc_url" download target="_blank">
                {{ $t('Policy')}} <i class="fa fa-download"></i>
            </b-button>
        </div>

    </div>
</div>
</template>

<script>
export default {
    props: {
        props: {
            type: Object,
            required: true,
        }

    },
    computed: {
      
    },
    methods: {
        formtaDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')

            }
            return ''

        },
        formattedMoney(money) {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
            }).format(money)
        },
    }
}
</script>

<!-- "requester": "Gabriel Koch Vidotto",
"approver": "Luan de Camargo",
"insurance": "Porto Seguro",
"vehicle_doc_name": "Captura de tela 2023-12-29 153717.png",
"vehicle_doc_url": "http://127.0.0.1:8989/uploads/i1VYUhw08A4KLTPOR8PK_Captura de tela 2023-12-29 153717.png",
"policy_doc_name": "Captura de tela 2023-12-29 150305.png",
"policy_doc_url": "http://127.0.0.1:8989/uploads/RckgaLT2haDy0yJUXN1I_Captura de tela 2023-12-29 150305.png",
"policy": "06565655",
"ci": "565",
"value": "55656",
"hiring_date": "2024-04-10",
"validity": "2025-12-29",
"description": "teste" -->

<!-- requester: {
    type: String,
    default: '',
},
approver: {
    type: String,
    default: '',
},
insurance: {
    type: String,
    default: '',
},
vehicle_doc_name: {
    type: String,
    default: '',
},
vehicle_doc_url: {
    type: String,
    default: '',
},
policy_doc_name: {
    type: String,
    default: '',
},

policy_doc_url: {
    type: String,
    default: '',
},
policy: {
    type: String,
    default: '',
},
ci: {
    type: String,
    default: '',
},
value: {
    type: String,
    default: '',
},
hiring_date: {
    type: String,
    default: '',
},
validity: {
    type: String,
    default: '',
},
description: {
    type: String,
    default: '',
}, -->
