<template>
    <div>
        <div class="main-content">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <ValidationObserver ref="formObserver" v-slot="{ validate }">
                                        <b-form @submit.prevent="handleSubmit(validate)">
                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <ValidationProvider name="Criticality Type" rules="required" v-slot="{ errors }">
                                                        <b-form-group label-for="criticalityType" :label="$t('Criticality type')">
                                                            
                                                            <v-select :options="criticalitytypes" :reduce="(criticalitytype) => criticalitytype.id" :label="'name'" v-model="eventType.criticality_type_id" class="vselect" 
                                                                :class="{ 'is-invalid': errors.length }" />
                                                                <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                                                        <b-form-group label-for="name" :label="$t('Name')">
                                                            
                                                            <b-form-input type="text" id="name" v-model="eventType.name" placeholder="" :state="errors.length ? false : null"/>
                                                            <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <ValidationProvider name="Code" rules="required" v-slot="{ errors }">
                                                        <b-form-group label-for="code" :label="$t('Code')">
                                                            
                                                            <b-form-input type="text" id="code" v-model="eventType.code" class="text-uppercase" placeholder="" :state="errors.length ? false : null"/>
                                                            <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                                                        <b-form-group label-for="description" :label="$t('Description')">
                                                            
                                                            <b-form-input type="text" id="description" v-model="eventType.description" placeholder="" :state="errors.length ? false : null"/>
                                                            <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <span class="required_fields">
                                                <span class="required">*</span>
                                                <strong>{{ $t('Required Fields') }}</strong>
                                            </span>
                                            <div class="block text-right">
                                                <router-link to="/eventtypes" class="btn btn-back">
                                                    <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                                                </router-link>
                                                <b-button type="submit" variant="primary" class="ml-1">
                                                    {{ $t('Save') }}
                                                    <i class="fa fa-save"></i>
                                                </b-button>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';
    import { BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton } from 'bootstrap-vue';
    import vSelect from "vue-select";
    import axios from 'axios';
    
    extend('required', required);
    
    export default {
        data() {
            return {
                eventType: {
                    name: '',
                    code: '',
                    criticality_type_id: '',
                    description: '',
                },
                criticalitytypes: [],
                errors: undefined,
            };
        },
        computed: {},
        methods: {
            async handleSubmit(validate) {
                const isValid = await validate();
                if (isValid) {
                    this.save();
                }
            },
            save() {
                let api = this.$store.state.api + "eventtypes";
                if (this.eventType.id) {
                    this.eventType._method = "PUT";
                    api += "/" + this.eventType.id;
                }
                axios
                    .post(api, this.eventType)
                    .then((response) => {
                        this.$message(
                            this.$t('Success'),
                            this.$t('Information saved successfully'),
                            "success"
                        );
                        this.$router.push("/eventtypes");
                    })
                    .catch((error) => {
                        this.$error(error);
                    });
            },
            getEventType(id) {
                let api = this.$store.state.api + "eventtypes/" + id;
                axios
                    .get(api)
                    .then((response) => {
                        this.eventType = response.data.data[0];
                    })
                    .catch((error) => {
                        this.$error(error);
                    });
            },
            getCriticalitytypes() {
                let api = this.$store.state.api + "criticalitytypes";
                axios
                    .get(api)
                    .then((response) => {
                        this.criticalitytypes = response.data.data;
                    })
                    .catch((error) => {
                        this.$error(error);
                    });
            },
        },
        mounted() {
            const id = this.$route.params.id;
            if (id) {
                this.getEventType(id);
            }
            this.getCriticalitytypes();
        },
        components: {
            ValidationObserver,
            ValidationProvider,
            BForm,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton,
            vSelect,
        },
    };
    </script>
    
    <style scoped>
    .required {
        color: red !important;
    }
    </style>
    