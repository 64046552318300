<!-- SelectItem.vue -->
<template>
    <div>
      <select v-model="selectedItem">
        <option v-for="item in items" :value="item.id">{{ item.name }}</option>
      </select>
      <a class="btn btn-add ml-1" v-b-modal.modal-1 >Add Novo </a>
      <modal-novo-item  @close="closeModal" @save="saveNewItem" />
    </div>
  </template>
  
  <script>
  import ModalNovoItem from "./ModalNovoItem.vue";
  
  export default {
    components: {
      ModalNovoItem
    },
    data() {
      return {
        selectedItem: null,
        items: [{name: 'Ali', id:1 },{name: 'Mohammed', id:2 }, {name: 'Saleh', id:3 }], 
        showModal: false
      };
    },
    methods: {
      openModal() {
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      },
      saveNewItem(newItem) {
        // Adicionar o novo item à lista de itens
        this.items.push(newItem);
        // Selecionar o novo item recém-adicionado
        this.selectedItem = newItem.id;
        // Fechar o modal
        this.showModal = false;
      }
    }
  };
  </script>
  