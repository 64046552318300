<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <ValidationObserver ref="formObserver" v-slot="{ invalid, validate }">
                    <b-form @submit.prevent="handleSubmit(validate)">
                      <div class="form-row">
                        <div class="form-group col-md-12">
                          <ValidationProvider name="Type of Fine" rules="required" v-slot="{ errors }">
                              <label for="Name">
                              <span class="required">*</span> {{ $t('Type of fine') }}:
                            </label>
                              <b-form-input
                                id="Name"
                                type="text"
                                v-model="fine_type.name"
                                autocomplete="new-password"
                                :state="errors.length ? false : null"
                                placeholder=""
                              />
                              <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-4">
                          <ValidationProvider name="Nature" rules="required" v-slot="{ errors }">
                              <label for="Nature">
                              <span class="required">*</span> {{ $t('Nature') }}:
                            </label>
                            <b-form-select
                            id="Nature"
                            v-model="fine_type.traffic_violation"
                            :state="errors.length ? false : null"
                            >
                            <option value="Leve">Leve</option>
                            <option value="Média">Média</option>
                            <option value="Grave">Grave</option>
                            <option value="Gravíssima">Gravíssima</option>
                          </b-form-select>
                          <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-4">
                          <ValidationProvider name="Points" rules="required|integer" v-slot="{ errors }">
                              <label for="Points">
                              <span class="required">*</span> {{ $t('Points') }}:
                            </label>
                            
                            <b-form-input
                            id="Points"
                            type="number"
                            v-model="fine_type.points"
                            autocomplete="new-password"
                            :state="errors.length ? false : null"
                            placeholder=""
                            />
                            <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-4 ">
                          <ValidationProvider name="Value" rules="required" v-slot="{ errors }">
                              <label for="Value">
                              <span class="required">*</span> {{ $t('Value') }}:
                            </label>
                              <money
                                id="Value"
                                v-model="fine_type.value"
                                autocomplete="new-password"
                                :state="errors.length ? false : null"
                                placeholder=""
                                v-bind="money"
                                class="form-control"
                                :class="{ 'is-invalid': errors.length }" />
                                <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-12">
                          <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                              <label for="Description">
                              <span class="required">*</span> {{ $t('Description') }}:
                            </label>
                              <b-form-textarea
                                id="Description"
                                v-model="fine_type.description"
                                autocomplete="new-password"
                                :state="errors.length ? false : null"
                                placeholder=""
                                rows="3"
                              />
                              <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-3">
                          <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                            <label for="status">
                              <span class="required">*</span> {{ $t('Status') }}:
                            </label>
                              <b-form-select
                                id="Status"
                                v-model="fine_type.status"
                                :state="errors.length ? false : null"
                              >
                                <option value="1"> {{ $t('Active') }} </option>
                                <option value="0"> {{ $t('Inactive') }} </option>
                              </b-form-select>
                              <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </div>
                      <span class="required_fields">
                        <span class="required">*</span>
                        <strong>{{ $t('Required Fields') }}</strong>
                      </span>
                      <div class="block text-right">
                        <router-link to="/fine_types" class="btn btn-back">
                          <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                        </router-link>
                        <b-button type="submit" variant="primary" class="ml-1">
                          {{ $t('Save') }}
                          <i class="fa fa-save"></i>
                        </b-button>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, integer } from "vee-validate/dist/rules";
import { BButton, BForm, BFormInput, BFormGroup, BFormSelect, BFormInvalidFeedback, BFormTextarea } from "bootstrap-vue";

// Add the rules to vee-validate
extend("required", required);
extend("integer", integer);

export default {
  name: "fineTypeForm",
  components: {
    BaseCrud,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormInvalidFeedback,
    BFormTextarea,
  },
  data() {
    return {
      fine_type: {
        name: '',
        traffic_violation: '',
        points: '',
        value: '',
        description: '',
        status: "1"
      },
      money: {
        prefix: this.$t('Prefix'),
      },
      errors: undefined,
      previewImage: null,
    };
  },
  methods: {
    async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
    save() {
      let api = this.$store.state.api + "fine_types";
      if (this.fine_type.id) {
        this.fine_type._method = "PUT";
        api += "/" + this.fine_type.id;
      }
      axios
        .post(api, this.fine_type)
        .then(() => {
          this.$message(
            this.$t('Success'),
            this.$t('Information saved successfully'),
            "success"
          );
          this.$router.push("/fine_types");
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getFineType(id) {
      let api = this.$store.state.api + "fine_types/" + id;
      axios
        .get(api)
        .then((response) => {
          this.fine_type = response.data.data[0];
        })
        .catch((error) => {
          this.$error(error);
        });
    },
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.getFineType(id);
    }
  },
};
</script>

<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>
