import Crud from '../views/Professional/Crud'
import Entity from '../views/Professional/Add'

let routes = {
    path: '/professionals',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/professionals/add',
            name: 'NewProfessional',
            component: Entity,
            meta: {
                pageTitle: 'Professional',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/professionals'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/professionals/edit/:id?',
            name: 'EditProfessional',
            component: Entity,
            meta: {
                pageTitle: 'Professional',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/professionals'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/professionals',
            name: 'list-professionals',
            component: Crud,
            meta: {
                pageTitle: 'Professionals',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
