import Crud from '../views/Licensing/Crud'
import Entity from '../views/Licensing/Add'

let routes = {
    path: '/licensings',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/licensings/add',
            name: 'NewLicensing',
            component: Entity,
            meta: {
                pageTitle: 'Licensing',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/licensings'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/licensings/edit/:id?',
            name: 'EditLicensing',
            component: Entity,
            meta: {
                pageTitle: 'Licensing',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/licensings'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/licensings',
            name: 'list-licensings',
            component: Crud,
            meta: {
                pageTitle: 'Licensing',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
