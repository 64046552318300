<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">{{ $t('My profile') }}</div>
            </div>
            <div class="card-body">
              <div class="form-group">
                <img
                  v-if="previewImage"
                  :src="previewImage"
                  style="card-body"
                  class="photo"
                />
                <img
                  v-else
                  :src="users.photo"
                  style="card-body"
                  class="photo"
                />
              </div>
              <div class="profile_user form-group">
                <h3 class="user_name_max">{{ users.name }}</h3>
                <hr class="hr" />
                <br />
                <br />
              </div>
              <div class="row">
                <div class="col-md-12">
                  <ValidationObserver ref="formObserver" v-slot="{ invalid, validate }">
                    <b-form @submit.prevent="handleSubmit(validate)">
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                            <b-form-group label-for="userName" :label="$t('Name')">
                              <span class="required">*</span>
                              <b-form-input
                                type="text"
                                id="userName"
                                class="form-control"
                                v-model="users.name"
                                :state="errors.length ? false : null"
                                placeholder=""
                              />
                              <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </b-form-group>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-6">
                          <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                            <b-form-group label-for="userEmail" :label="$t('Email')">
                              <span class="required">*</span>
                              <b-form-input
                                type="email"
                                id="userEmail"
                                class="form-control"
                                v-model="users.email"
                                :state="errors.length ? false : null"
                                placeholder=""
                              />
                              <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </b-form-group>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="form-group">
                        <div>
                          <a
                            class="btn btn-put-pass"
                            data-target="#pwdModal"
                            data-toggle="modal"
                          >
                            {{ $t('Do you want to change the password?')}}
                            <i class="fa fa-key"></i>
                          </a>
                        </div>

                        <div
                          id="pwdModal"
                          v-on:keyup="keymonitor"
                          class="modal fade"
                          tabindex="-1"
                          role="dialog"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog">
                            <div class="modal-content">
                              <div class="modal-header">
                                <label>Digite a nova Senha</label>
                              </div>
                              <div class="modal-body">
                                <div class="col-md-12">
                                  <div class="panel panel-default">
                                    <div class="panel-body">
                                      <div class="text-center">
                                        <div class="panel-body">
                                          <fieldset>
                                            <ValidationProvider name="New Password" rules="required|min:6|max:12" v-slot="{ errors }">
                                              <b-form-group label-for="userPassword" :label="$t('New Password')">
                                                <span class="required">*</span>
                                                <b-form-input
                                                  type="password"
                                                  id="userPassword"
                                                  v-model="users.nova_password"
                                                  autocomplete="off"
                                                  :state="errors.length ? false : null"
                                                />
                                                <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                              </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="Confirm Password" rules="required|confirmed:users.nova_password" v-slot="{ errors }">
                                              <b-form-group label-for="userPasswordConfirmation" :label="$t('Confirm The Password')">
                                                <span class="required">*</span>
                                                <b-form-input
                                                  type="password"
                                                  id="userPasswordConfirmation"
                                                  v-model="users.password_confirmation"
                                                  autocomplete="off"
                                                  :state="errors.length ? false : null"
                                                />
                                                <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                              </b-form-group>
                                            </ValidationProvider>
                                          </fieldset>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <div class="col-md-12">
                                  <div class="block text-right">
                                    <a
                                      id="exitModal"
                                      @click="clearModal"
                                      data-dismiss="modal"
                                      class="btn btn-back"
                                    >
                                      <i class="fa fa-arrow-left"></i> {{ $t('Cancel') }}
                                    </a>
                                    <a
                                      data-dismiss="modal"
                                      class="btn btn-add ml-2"
                                    >
                                      {{ $t('Save') }}
                                      <i class="fa fa-save"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="form-group">
                        <label for="userPic">{{ $t('Profile Picture') }}</label>
                        <input
                          type="file"
                          class="form-control"
                          id="userPic"
                          accept="image/*"
                          name="userPic"
                          @change="uploadImage"
                          placeholder=""
                        />
                      </div>
                      <span class="required_fields">
                        <span class="required">*</span>
                        <strong>{{ $t('Required Fields') }}</strong>
                      </span>
                      <div class="block text-right">
                        <router-link to="/users" class="btn btn-back mr-1">
                          <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                        </router-link>
                        <b-button type="submit" variant="primary" class="ml-1">
                          {{ $t('Save') }}
                          <i class="fa fa-save"></i>
                        </b-button>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, email, min, max, confirmed } from "vee-validate/dist/rules";
import { BButton, BForm, BFormInput, BFormGroup, BFormInvalidFeedback } from "bootstrap-vue";

// Add the rules to vee-validate
extend("required", required);
extend("email", email);
extend("min", min);
extend("max", max);
extend("confirmed", confirmed);

export default {
  data() {
    return {
      companies: [],
      departments: [],
      change_password: true,
      users: {
        name: "",
        email: "",
        nova_password: "",
        password_confirmation: "",
        photo: "",
      },
      errors: undefined,
      previewImage: null,
    };
  },
  methods: {
    async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
    makeFormData() {
      let fd = new FormData();
      let photo = document.getElementById("userPic");

      fd.append("name", this.users.name);
      fd.append("email", this.users.email);
      fd.append("password", this.users.nova_password);
      fd.append("password_confirmacao", this.users.password_confirmation);
      fd.append("photo", photo.files[0] ? photo.files[0] : "");

      if (this.users.id) {
        fd.append("id", this.users.id);
        fd.append("_method", "PUT");
      }

      return fd;
    },
    save() {
      let api = this.$store.state.api + "users";
      let fd = this.makeFormData();

      if (this.users.id) {
        api += "/" + this.users.id;
      }

      axios
        .post(api, fd)
        .then(() => {
          this.$message(
            this.$t('Success'),
            this.$t('Information saved successfully'),
            "success"
          );
          this.$router.push("/");
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    clearModal() {
      this.users.password_confirmation = "";
      this.users.nova_password = "";
    },
    getUsers() {
      let api = this.$store.state.api + "me";
      axios
        .post(api)
        .then((response) => {
          this.users = response.data;
          this.users.password_confirmacao = "";
          this.users.password = "";
          this.users.password_confirmation = "";
          this.users.nova_password = "";
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };
    },
  },
  mounted() {
    this.getUsers();
  },
  components: {
    BaseCrud,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
  },
};
</script>

<style scoped>
.left {
  text-align: left;
}
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  height: 200px;
  object-fit: cover;
}

.modal-footer {
  border-top: 0px;
}
.pointer {
  cursor: pointer;
}
.caps {
  color: black;
  font: bold;
  text-align: right;
  font-size: 20px;
}

.required {
  color: red !important;
}
.btn-put-prof:hover,
.btn-put-prof[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.profile_user {
  text-align: center;
}
</style>
