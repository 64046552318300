<template>
<div>
    <div class="row">
        <div class="col-md-12">
            <base-crud :ref="'supplierCrud'" :crudName="$t('Suppliers')" :newText="$t('Add')+ '  '+$t('New')" :table="table" :columns="columns" :options="options" :endPoint="endPoint" :enableAdd="true" :enableEdit="true" :enableDelete="true" :enableView="false" :enableReset="false" :dataForm="dataForm">
            </base-crud>
        </div>
    </div>
</div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
    data: function () {
        return {
            table: "suppliersTable",
            showMessageImport: false,
            supplierCrud: {},
            pages: [],
            url: "",
            columns: [
                "id",
                "name",
                "actions",
            ],
            tableData: ["id", "name", "description"],
            optionsOrigin: {
                filterByColumn: true,
                debounce: 1000,
                filterable: ["name"],
                pagination: {
                    chunk: 10,
                    dropdown: false,
                    nav: "scroll"
                },
                perPage: 10,
                perPageValues: [10, 25, 50, 100],
                headings: {
                    id: this.$t('id'),
                    name: this.$t('Name'),
                    description: this.$t('Description'),
                    actions: this.$t('action'),
                },

                texts: {
                    filterBy: this.$t('Filter'),
                    defaultOption: this.$t('Select'),
                },
                listColumns: {

                },
                templates: {

                },
                requestFunction: function (data) {
                    let requestData = {};

                   

                    let query = this.$parent.$parent.$parent.query(data.query);
                    requestData.params = data;
                    requestData.params.query = "";

                    return axios.get(this.url + "?" + query, requestData).catch(
                        function (e) {
                            this.dispatch("error", e);
                        }.bind(this)
                    );
                },
            },
            endPoint: "suppliers",
            dataForm: {},
        };
    },
    components: {
        BaseCrud,
    },
    computed: {
        options() {
            const headings = {
                id: this.$t('id'),
                name: this.$t('Name'),
                description: this.$t('Description'),
                actions: this.$t('action'),
            }

            const texts = {
                filterBy: this.$t('Filter'),
                defaultOption: this.$t('Select'),
            }
            return {
                ...this.optionsOrigin,
                texts,
                headings,
            }

        }

    },
    methods: {
        makeFormData: function () {
            const self = this;
            let fd = new FormData();

            fd.append("fileImport", "");

            return fd;
        },
        query: function (query) {
            let columns = {
                id: "id",
                name: "name",

            };
            let filters = "";
            $.each(query, function (index, value) {
                filters += columns[index] + "=" + value + "&";
            });
            return filters;
        },
        openInput() {},
    },
};
</script>

<style scoped>
.VuePagination {
    text-align: center;
}

.uploading-image {
    display: flex;
    border-radius: 10px;
    width: 100px;
    height: 100px;
}
</style>
