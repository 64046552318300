<template>
<div class="card">

    <div class="card-body">
        <div class="row">
            <div class="col-md-4">
                <b-avatar :src="data.vehicle_image_url" size="6rem"></b-avatar>
                <b-avatar v-if="data.deviceActivation.status == 1" class="ml-n1 mt-n4" :variant="`light-info`" size="45">
                    <feather-icon size="21" :icon="'RadioIcon'" />
                </b-avatar>

            </div>
            <div class="col-md-4">
                <p> {{ $t('Surname') }} : {{ data.vehicle }} </p>
                <p> {{ $t('Acronym') }} : {{ data.sigla }} </p>

            </div>
            <div class="col-md-4">
                <p> {{ $t('License Plate') }} : {{ data.license_plate }} </p>
                <p> {{ $t('Frame') }} : {{ data.frame }} </p>
                <p> {{ $t('National car registration') }} : {{ data.national_registry }} </p>
            </div>
            <div class="col-md-12">
                <div class="row ">

                    <div class="col-md-4"> {{ $t('Central') }} : {{ data.center }} </div>
                    <div class="col-md-4"> {{ $t('Vehicle Brand') }} : {{ data.vehicle_brand }} </div>
                    <div class="col-md-4"> {{ $t('Vehicle Type') }} : {{ data.vehicleType }} </div>

                    <div class="col-md-4"> {{ $t('Unit') }} : {{ data.unit }} </div>
                    <div class="col-md-4"> {{ $t('Vehicle Colors') }} : {{ data.vehicle_color }} </div>

                    <div class="col-md-4"> {{ $t('Vehicle Model') }} : {{ data.vehicle_model }} </div>

                    <div class="col-md-12"> {{ $t('Specialty') }} : {{ data.specialty }} </div>
                </div>

            </div>
            <div class="col-md-12">
                <div class="row ">
                    <div class="col-md-4"> {{ $t('manufacture Year') }} : {{ data.manufacture_year }} </div>
                    <div class="col-md-4"> {{ $t('model Year') }} : {{ data.model_year }} </div>

                </div>

            </div>
            <div class="col-md-12">
                <div class="row ">
                    <div class="col-md-4"> {{ $t('maintenance free') }} : {{ data.maintenance_free == 1? $t('Yes'): $t('No') }} </div>
                    <div class="col-md-8"> {{ $t('checklist free') }} : {{ data.checklist_free == 1? $t('Yes'): $t('No') }} </div>
                    <div class="col-md-12"> {{ $t('description') }} : {{ data.description }} </div>
                </div>

            </div>

        </div>

    </div>
    <div class="card-footer row">
        <div class="form-group col-md-9">
            <b-button v-if="data.doc_vehicle_url" pill variant="outline-primary" :href="data.doc_vehicle_url" download target="_blank">
                {{ $t('CRLV') }} <i class="fa fa-download"></i>
            </b-button>
        </div>
        <div class=" col-md-3">
            <b-button pill variant="outline-primary" @click="$emit('showDeviceActivtions', !modalShow)">
                {{ $t('Device')}}
                <b-badge pill :variant="data.deviceActivation.status == 1?'success':'secondary'"> {{ data.deviceActivation.status == 1? $t('Active'): $t('Inactive')}} </b-badge>

            </b-button>

        </div>

    </div>

</div>
</template>

<script>
const HAS_GPS = require("@/assets/images/resgate/avlIcon.png")

export default {
    props: {
        props: {
            type: Object,
            required: true,
        },
        modalShow: {
            type: Boolean,
            default: false,
        }

    },
    computed: {
        data() {
            return this.props;
        },
        getSrsGPS() {
            return HAS_GPS;
        }
    },
    methods: {
        formtaDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')

            }
            return ''

        }
    }
}
</script>
