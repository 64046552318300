<template>
  <div>
      <div class="main-content">
          <div class="row">
              <div class="col-md-12">
                  <div class="card">
                      <div class="card-body">
                          <div class="row">
                              <div class="col-md-12">
                                  <ValidationObserver ref="formObserver" v-slot="{ validate }">
                                      <b-form @submit.prevent="handleSubmit(validate)">
                                          <div class="form-row">
                                              <div class="form-group col-md-4">
                                                  <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                                                      <label for="Name">
                                                          <span class="required">*</span> {{ $t('Name') }}:
                                                      </label>
                                                      <input
                                                          autocomplete="new-password"
                                                          type="text"
                                                          id="Name"
                                                          class="form-control"
                                                          v-model="professional.name"
                                                          :class="{ 'is-invalid': errors.length }"
                                                          placeholder=""
                                                      />
                                                      <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                  </ValidationProvider>
                                              </div>
                                              <div class="form-group col-md-4">
                                                  <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                                                      <label for="Email">
                                                          <span class="required">*</span> {{ $t('Email') }}:
                                                      </label>
                                                      <input
                                                          autocomplete="new-password"
                                                          class="form-control"
                                                          id="Email"
                                                          placeholder=""
                                                          type="text"
                                                          v-model="professional.email"
                                                          :class="{ 'is-invalid': errors.length }"
                                                      />
                                                      <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                  </ValidationProvider>
                                              </div>
                                              <div class="form-group col-md-4">
                                                  <ValidationProvider name="Date Of Birth" rules="required|date_format" v-slot="{ errors }">
                                                      <label for="birthDate"> <span class="required">*</span> {{ $t('Date Of Birth') }}:</label>
                                                      <input
                                                          autocomplete="new-password"
                                                          class="form-control"
                                                          id="birthDate"
                                                          type="date"
                                                          v-model="professional.birth_date"
                                                          :class="{ 'is-invalid': errors.length }"
                                                          placeholder=""
                                                      />
                                                      <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                  </ValidationProvider>
                                              </div>
                                              <div class="form-group col-md-3">
                                                <ValidationProvider name="Type Of Professional" rules="required" v-slot="{ errors }">
                                                  <label for="professionalTypeId"><span class="required">*</span>{{ $t('Type Of Professional') }}:</label>
                                                  <v-select
                                                      :options="typeProfessionals"
                                                      :reduce="(typeProfessional) => typeProfessional.id"
                                                      :label="'name'"
                                                      v-model="professional.professional_type_id"
                                                      class="vselect"
                                                      :class="{ 'is-invalid': errors.length }" 
                                                    />
                                                    <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                  </ValidationProvider>
                                              </div>
                                              <div class="form-group col-md-3">
                                                <ValidationProvider name="Tuition" rules="required" v-slot="{ errors }">
                                                    <label for="registration">
                                                        <span class="required">*</span> {{ $t('Tuition') }}:
                                                    </label>
                                                    <input
                                                        autocomplete="new-password"
                                                        class="form-control"
                                                        id="registration"
                                                        type="text"
                                                        v-model="professional.registration"
                                                        placeholder=""
                                                        :class="{ 'is-invalid': errors.length }"
                                                        />
                                                        <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                    </ValidationProvider>
                                              </div>
  
                                              <div class="form-group col-md-3">
                                                  <ValidationProvider name="ID Card" rules="required" v-slot="{ errors }">
                                                      <label for="rg">
                                                          <span class="required">*</span> {{ $t('ID Card') }}:
                                                      </label>
                                                      <input
                                                          autocomplete="new-password"
                                                          class="form-control"
                                                          id="rg"
                                                          placeholder=""
                                                          type="text"
                                                          v-model="professional.rg"
                                                          :class="{ 'is-invalid': errors.length }"
                                                      />
                                                      <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                  </ValidationProvider>
                                              </div>
  
                                              <div class="form-group col-md-3">
                                                  <ValidationProvider name="Taxpayer Identification Number (CPF)" rules="required|regex:^[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}$" v-slot="{ errors }">
                                                      <label for="cpf">
                                                          <span class="required">*</span> {{ $t(`Taxpayer Identification Number (CPF)`) }}:
                                                      </label>
                                                      <input
                                                          autocomplete="new-password"
                                                          type="text"
                                                          id="cpf"
                                                          class="form-control"
                                                          v-model="professional.cpf"
                                                          :class="{ 'is-invalid': errors.length }"
                                                          placeholder=""
                                                      />
                                                      <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                  </ValidationProvider>
                                              </div>
  
                                              <div class="form-group col-md-2">
                                                  <label for="Phone">{{ $t('Telephone') }}:</label>
                                                  <input
                                                      autocomplete="new-password"
                                                      type="text"
                                                      class="form-control"
                                                      id="Phone"
                                                      v-model="professional.telephone"
                                                      v-mask="'(99) 9999-9999'"
                                                      placeholder=""
                                                  />
                                              </div>
  
                                              <div class="form-group col-md-2">
                                                <ValidationProvider name="Cell Phone" rules="required" v-slot="{ errors }">
                                                    <label for="Cellphone">
                                                        <span class="required">*</span> {{ $t('Cell Phone') }}:
                                                    </label>
                                                  <input
                                                      autocomplete="new-password"
                                                      class="form-control"
                                                      id="Cellphone"
                                                      type="text"
                                                      v-mask="'(99) 99999-9999'"
                                                      v-model="professional.cellphone"
                                                      :class="{ 'is-invalid': errors.length }"
                                                          placeholder=""
                                                      />
                                                      <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                      </ValidationProvider>
                                              </div>
  
                                              <div class="form-group col-md-2">
                                                  <label for="cnh">{{ $t(`Driver's License`) }}:</label>
                                                  <input
                                                      autocomplete="new-password"
                                                      class="form-control"
                                                      id="cnh"
                                                      type="text"
                                                      v-model="professional.cnh"
                                                      placeholder=""
                                                  />
                                              </div>
  
                                              <div class="form-group col-md-2">
                                                  <label for="cnhType">{{ $t(`Type of Driver's License`) }}:</label>
                                                  <input
                                                      autocomplete="new-password"
                                                      class="form-control"
                                                      id="cnhType"
                                                      type="text"
                                                      v-model="professional.cnh_type"
                                                      placeholder=""
                                                  />
                                              </div>
  
                                              <div class="form-group col-md-2">
                                                  <ValidationProvider name="Expiration Date of Driver's License" rules="date_format" v-slot="{ errors }">
                                                      <label for="expiredCnh">{{ $t(`Expiration Date of Driver's License`) }}:</label>
                                                      <input
                                                          autocomplete="new-password"
                                                          class="form-control"
                                                          id="expiredCnh"
                                                          type="date"
                                                          v-model="professional.expired_cnh"
                                                          :class="{ 'is-invalid': errors.length }"
                                                          placeholder=""
                                                      />
                                                      <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                  </ValidationProvider>
                                              </div>
  
                                              <div class="form-group col-md-2">
                                                  <label for="punctuationCnh">{{ $t(`Driver's License Points`) }}:</label>
                                                  <input
                                                      autocomplete="new-password"
                                                      class="form-control"
                                                      id="punctuationCnh"
                                                      type="text"
                                                      v-model="professional.punctuation_cnh"
                                                      placeholder=""
                                                  />
                                              </div>
  
                                              <div class="form-group col-md-3">
                                                  <ValidationProvider name="Validity of First Aid Course" rules="date_format" v-slot="{ errors }">
                                                      <label for="expiredCourseRescuer">{{ $t(`Validity of First Aid Course`) }}:</label>
                                                      <input
                                                          autocomplete="new-password"
                                                          class="form-control"
                                                          id="expiredCourseRescuer"
                                                          type="date"
                                                          v-model="professional.expired_course_rescuer"
                                                          :class="{ 'is-invalid': errors.length }"
                                                          placeholder=""
                                                      />
                                                      <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                  </ValidationProvider>
                                              </div>
  
                                              <div class="col-md-3">
                                                <ValidationProvider name="Centrals" rules="required" v-slot="{ errors }">
                                                    <label for="centrals">
                                                        <span class="required">*</span> {{ $t('Centrals') }}:
                                                    </label>
                                                  <v-select
                                                      :options="centers"
                                                      :reduce="(centers) => centers.id"
                                                      :label="'name'"
                                                      v-model="professional.center_id"
                                                      class="vselect"
                                                      v-on:input="changetUnitsCenter"
                                                      :class="{ 'is-invalid': errors.length }" />
                                                        <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                </ValidationProvider>
                                              </div>
  
                                              <div class="col-md-3">
                                                <ValidationProvider name="Unit" rules="required" v-slot="{ errors }">

                                                <label for="unit">
                                                    <span class="required">*</span> {{ $t('Unit') }}:
                                                </label>
                                                  <v-select
                                                      :options="units"
                                                      :reduce="(unit) => unit.id"
                                                      :label="'name'"
                                                      v-model="professional.unit_id"
                                                      class="vselect"
                                                      :class="{ 'is-invalid': errors.length }" />
                                                        <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                </ValidationProvider>
                                              </div>
  
                                              <div class="form-group col-md-3">
                                                  <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                                                      <label for="Status">
                                                          <span class="required">*</span> {{ $t('Status') }}:
                                                      </label>
                                                      <select
                                                          class="form-control"
                                                          v-model="professional.status"
                                                          :class="{ 'is-invalid': errors.length }"
                                                      >
                                                          <option value="1"> {{ $t('Active') }} </option>
                                                          <option value="0"> {{ $t('Inactive') }} </option>
                                                      </select>
                                                      <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                  </ValidationProvider>
                                              </div>
                                          </div>
  
                                          <span class="required_fields">
                                              <span class="required">*</span>
                                              <strong> {{ $t('Required Fields') }}</strong>
                                          </span>
                                          <div class="block text-right">
                                              <router-link to="/professionals" class="btn btn-back">
                                                  <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                                              </router-link>
                                              <b-button type="submit" variant="primary" class="ml-1">
                                                  {{ $t('Save') }}
                                                  <i class="fa fa-save"></i>
                                              </b-button>
                                          </div>
                                      </b-form>
                                  </ValidationObserver>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  </template>
  
  <script>
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
  import { required, email, regex } from 'vee-validate/dist/rules';
  import { BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue';
  import vSelect from "vue-select";
  import axios from 'axios';
  
  extend('required', required);
  extend('email', email);
  extend('date_format', {
      validate: (value) => {
          const pattern = /^\d{4}-\d{2}-\d{2}$/;
          return pattern.test(value);
      },
      message: 'The {_field_} field must be in the format YYYY-MM-DD'
  });
  extend('regex', regex);
  
  export default {
      data() {
          return {
              professional: {
                  status: '1',
                  center_id: '',
                  unit_id: '',
                  name: '',
                  email: '',
                  birth_date: '',
                  professional_type_id: '',
                  registration: '',
                  rg: '',
                  cpf: '',
                  telephone: '',
                  cellphone: '',
                  cnh: '',
                  cnh_type: '',
                  expired_cnh: '',
                  punctuation_cnh: '',
                  expired_course_rescuer: '',
              },
              units: [],
              typeProfessionals: [],
              centers: [],
              errors: undefined,
          };
      },
      computed: {},
      methods: {
        async handleSubmit(validate) {
            const isValid = await validate();
            if (isValid) {
                this.save();
            }
        },
        changetUnitsCenter(center_id) {
              this.professional.unit_id = ''
              this.getUnitsCenter(center_id)
          },
          save() {
              let api = `${this.$store.state.api}professionals`;
  
              if (this.professional.id) {
                  this.professional._method = "PUT";
                  api += "/" + this.professional.id;
              }
  
              axios
                  .post(api, this.professional)
                  .then(() => {
                      this.$message(
                          this.$t('Success'),
                          this.$t('Information saved successfully'),
                          "success"
                      );
                      this.$router.push("/professionals");
                  })
                  .catch((error) => {
                      this.$error(error);
                  });
          },
          getProfessional(id) {
              let api = this.$store.state.api + "professionals/" + id;
  
              axios
                  .get(api)
                  .then((response) => {
                      this.professional = response.data.data[0];
                      this.getUnitsCenter(this.professional.center_id)
                  })
                  .catch((error) => {
                      this.$error(error);
                  });
          },
          getCenters() {
              let api = this.$store.state.api + "centers/";
  
              axios
                  .get(api)
                  .then((response) => {
                      this.centers = response.data.data;
                  })
                  .catch((error) => {
                      this.$error(error);
                  });
          },
          getUnitsCenter(centerId) {
              const api = `${this.$store.state.api}centers/unit/${centerId}`;
              axios
                  .get(api)
                  .then((response) => {
                      this.units = response.data;
                  })
                  .catch((error) => {
                      this.$error(error);
                  });
          },
          getTypeProfessional() {
              let api = this.$store.state.api + "typeprofessionals?paginated=false";
  
              axios
                  .get(api)
                  .then((response) => {
                      this.typeProfessionals = response.data.data;
                  })
                  .catch((error) => {
                      this.$error(error);
                  });
          },
      },
      mounted() {
          this.getTypeProfessional();
          this.getCenters();
          const id = this.$route.params.id;
          if (id) {
              this.getProfessional(id);
          }
      },
      components: {
          ValidationObserver,
          ValidationProvider,
          BForm,
          BFormGroup,
          BFormInput,
          BButton,
          vSelect,
      },
  };
  </script>
  
  <style scoped>
  .required {
      color: red !important;
  }
  </style>
  