import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
function loadLocaleMessages() {
  const files = require.context('./locales', true, /\.json$/)
  const messages = {}
  files.keys().map(key => {
    const filename = key.replace('./', '').replace('.json', '')
    const fileContent = files(key)
    return messages[filename] = fileContent
  })

  return messages
}

function browserLanguage() {
  // if user choice language
  if (localStorage.locale) {
    return localStorage.locale
  }
  //  if user still not choice language
  return navigator.language?.split('-')?.[0] ?? 'en'
}

export default new VueI18n({
  locale: browserLanguage(),
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
})
