<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <ValidationObserver ref="formObserver" v-slot="{ validate }">
                    <b-form @submit.prevent="handleSubmit(validate)">
                      <div class="form-row">
                        <div class="form-group col-md-4">
                          <ValidationProvider name="Centrals" rules="required" v-slot="{ errors }">
                              <label for="center_id">
                                    <span class="required">*</span> {{ $t('Centrals') }} :
                                </label>
                                <v-select :options="centers" :reduce="center => center.id" :label="'name'" v-model="licensing.center_id" class="vselect" v-on:input="getUnitsCenter" 
                                  :class="{ 'is-invalid': errors.length }" />
                                  <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                </ValidationProvider>
                              </div>
                              <div class="form-group col-md-4">
                                <ValidationProvider name="Unit" rules="required" v-slot="{ errors }">
                                  <label for="unit_id">
                                        <span class="required">*</span> {{ $t('Unit') }} :
                                    </label>
                                    
                                    <v-select :options="units" :reduce="unit => unit.id" :label="'name'" v-model="licensing.unit_id" class="vselect" v-on:input="getVehicles" 
                                      :class="{ 'is-invalid': errors.length }" />
                                      <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                    </ValidationProvider>
                                  </div>
                                  <div class="form-group col-md-4">
                                    <ValidationProvider name="Vehicle" rules="required" v-slot="{ errors }">
                                        <label for="vehicle_id">
                                              <span class="required">*</span> {{ $t('Vehicle') }} :
                                          </label>
                              
                              <v-select :options="vehicles" :reduce="vehicle => vehicle.id" :label="'name'" v-model="licensing.vehicle_id" class="vselect" 
                                :class="{ 'is-invalid': errors.length }" />
                                <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-2">
                          <ValidationProvider name="Ipva value" rules="required" v-slot="{ errors }">
                              <label for="ipva_value">
                                    <span class="required">*</span> {{ $t('Ipva value') }} :
                                </label>
                                <money autocomplete="new-password" type="text" id="ipva_value" class="form-control" v-model="licensing.ipva_value" placeholder="" 
                                :class="{ 'is-invalid': errors.length }" />
                                <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                              </ValidationProvider>
                            </div>
                            <div class="form-group col-md-2">
                              <ValidationProvider name="Licensing value" rules="required" v-slot="{ errors }">
                                <label for="licensing_value">
                                      <span class="required">*</span> {{ $t('Licensing value') }} :
                                  </label>
                                  <money autocomplete="new-password" type="text" id="licensing_value" class="form-control" v-model="licensing.licensing_value" placeholder="" 
                                  :class="{ 'is-invalid': errors.length }" />
                                  <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                </ValidationProvider>
                              </div>
                              <div class="form-group col-md-2">
                                <ValidationProvider name="Liability insurance" rules="required" v-slot="{ errors }">
                                  <label for="liability_insurance">
                                      <span class="required">*</span> {{ $t('Liability insurance') }} :
                                  </label>
                                  
                                  <money autocomplete="new-password" type="text" id="liability_insurance" class="form-control" v-model="licensing.liability_insurance" placeholder="" 
                                  :class="{ 'is-invalid': errors.length }" />
                                  <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                </ValidationProvider>
                              </div>
                              <div class="form-group col-md-3">
                                <ValidationProvider name="Payment date" rules="required" v-slot="{ errors }">
                                  <label for="payment_date">
                                      <span class="required">*</span> {{ $t('Payment date') }} :
                                  </label>
                                  
                                  <b-form-input autocomplete="new-password" type="date" id="payment_date" class="form-control" v-model="licensing.payment_date" placeholder="" :state="errors.length ? false : null" />
                                  <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                </ValidationProvider>
                              </div>
                              <div class="form-group col-md-3">
                                <ValidationProvider name="Situation" rules="required" v-slot="{ errors }">
                                  <label for="situation">
                                      <span class="required">*</span> {{ $t('Situation') }} :
                                  </label>
                                  <b-form-select class="form-control" v-model="licensing.situation" :state="errors.length ? false : null">
                                    <option :value="1">{{ $t('Expired')}}</option>
                                    <option :value="0">{{$t('Expiry on')}}</option>
                                  </b-form-select>
                                  <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                </ValidationProvider>
                              </div>
                              <div class="form-group col-md-12">
                                <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                                  <label for="description">
                                      <span class="required">*</span> {{ $t('Description') }} :
                                  </label>
                                  
                                  <b-form-textarea class="form-control" id="description" v-model="licensing.description" cols="30" rows="10" :state="errors.length ? false : null"></b-form-textarea>
                                  <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                </ValidationProvider>
                              </div>
                              <div class="form-group col-md-6">
                                <ValidationProvider name="Requester" rules="required" v-slot="{ errors }">
                                  <label for="requester_id">
                                      <span class="required">*</span> {{ $t('Requester') }} :
                                  </label>
                                  
                                  <v-select :options="professionals" :reduce="professional => professional.id" :label="'name'" v-model="licensing.requester_id" class="vselect" 
                                    :class="{ 'is-invalid': errors.length }" />
                                    <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                  </ValidationProvider>
                                </div>
                                <div class="form-group col-md-12">
                                  <ValidationProvider name="Files"  v-slot="{ errors }">
                                    <label for="files">
                                        <span class="required">*</span> {{ $t('Attach files') }} :
                                    </label>
                              
                              <upload-file :fileId="licensing.files" @uploaded="(event) => { licensing.files = event }"  :class="{ 'is-invalid': errors.length }" />
                                <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                      <span class="required_fields">
                        <span class="required">*</span>
                        <strong>{{ $t('Required Fields') }}</strong>
                      </span>
                      <div class="block text-right">
                        <router-link to="/licensings" class="btn btn-back">
                          <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                        </router-link>
                        <b-button type="submit" variant="primary" class="ml-1">
                          {{ $t('Save') }}
                          <i class="fa fa-save"></i>
                        </b-button>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { BForm, BFormGroup, BFormInput, BFormTextarea, BFormSelect, BFormInvalidFeedback, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import UploadFile from "../../layouts/components/UploadFile";
import axios from "axios";

extend("required", required);

export default {
  data() {
    return {
      licensing: {
        status: "1",
        center_id: "",
        unit_id: "",
        vehicle_id: "",
        ipva_value: "",
        licensing_value: "",
        liability_insurance: "",
        payment_date: "",
        situation: "",
        requester_id: "",
        description: "",
        files: null,
      },
      centers: [],
      units: [],
      vehicles: [],
      professionals: [],
      errors: undefined,
      previewImage: null,
    };
  },
  computed: {},
  methods: {
    async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
    changetUnitsCenter(center_id) {
      this.licensing.unit_id = ''
      this.getUnitsCenter(center_id)
    },
    changeVehicleByUnits(unit_id) {
      this.licensing.vehicle_id = ''
      this.getVehicles(unit_id)
    },
    save() {
      const api = `${this.$store.state.api}licensings`;

      if (this.licensing.id) {
        this.licensing._method = "PUT";
        api += "/" + this.licensing.id;
      }

      axios.post(api, this.licensing)
        .then(() => {
          this.$message(
            this.$t('Success'),
            this.$t('Information saved successfully'),
            "success"
          );
          this.$router.push("/licensings");
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getLicensing(id) {
      const api = `${this.$store.state.api}licensings/${id}`;

      axios.get(api)
        .then((response) => {
          this.licensing = response.data.data[0];
          this.getUnitsCenter(this.licensing.center_id)
          this.getVehicles(this.licensing.unit_id)
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getCenters() {
      const api = `${this.$store.state.api}centers/`;

      axios.get(api)
        .then((response) => {
          this.centers = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getUnitsCenter(center_id) {
      const api = `${this.$store.state.api}centers/unit/${center_id}`;
      axios.get(api)
        .then((response) => {
          this.units = response.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getVehicles(unit_id) {
      const api = `${this.$store.state.api}units/vehicles/${unit_id}`;

      axios.get(api)
        .then((response) => {
          this.vehicles = response.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getProfessionals() {
      const api = `${this.$store.state.api}professionals/`;

      axios.get(api)
        .then((response) => {
          this.professionals = response.data.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
  },
  mounted() {
    const id = this.$route.params.id;

    this.getCenters();
    this.getProfessionals();

    if (id) {
      this.getLicensing(id);
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    UploadFile,
  },
};
</script>

<style scoped>
.required {
  color: red !important;
}
</style>
