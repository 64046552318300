var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationObserver',{ref:"formObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(validate)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group input-group-sm col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Name"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Name'))+": ")]),_c('b-form-input',{attrs:{"type":"text","id":"Name","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.operator.name),callback:function ($$v) {_vm.$set(_vm.operator, "name", $$v)},expression:"operator.name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group input-group-sm col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Url","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"apn_url"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Url'))+": ")]),_c('b-form-input',{attrs:{"type":"text","id":"apn_url","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.operator.apn_url),callback:function ($$v) {_vm.$set(_vm.operator, "apn_url", $$v)},expression:"operator.apn_url"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group input-group-sm col-md-6"},[_c('ValidationProvider',{attrs:{"name":"User","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"apn_user"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('User'))+": ")]),_c('b-form-input',{attrs:{"type":"text","id":"apn_user","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.operator.apn_user),callback:function ($$v) {_vm.$set(_vm.operator, "apn_user", $$v)},expression:"operator.apn_user"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group input-group-sm col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Name"}},[_c('span',{staticClass:"apn_password"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Password'))+": ")]),_c('b-form-input',{attrs:{"type":"text","id":"apn_password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.operator.apn_password),callback:function ($$v) {_vm.$set(_vm.operator, "apn_password", $$v)},expression:"operator.apn_password"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)]),_c('span',{staticClass:"required_fields"},[_c('span',{staticClass:"required"},[_vm._v("*")]),_c('strong',[_vm._v(_vm._s(_vm.$t('Required Fields')))])]),_c('div',{staticClass:"block text-right"},[_c('router-link',{staticClass:"btn btn-back",attrs:{"to":"/operators"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t('To Go Back'))+" ")]),_c('b-button',{staticClass:"ml-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" "),_c('i',{staticClass:"fa fa-save"})])],1)])]}}])})],1)])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }