
import Crud from '../views/Companies/Crud'
import Entity from '../views/Companies/Add'

let routes = {
    path: '/companies',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/companies/add',
            name: 'NewCompany',
            component: Entity,
            meta: {
                pageTitle: 'Company',
                access_level: ['1'],
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/companies'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/companies/edit/:id?',
            name: 'EditCompany',
            component: Entity,
            meta: {
                pageTitle: 'Company',
                access_level: ['1'],
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/companies'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/companies',
            name: 'list-companies',
            component: Crud,
            meta: {
                pageTitle: 'Company',
                access_level: ['1'],
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
