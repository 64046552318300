<template>
    <div>
        <div class="main-content">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <ValidationObserver ref="formObserver" v-slot="{ validate }">
                                        <b-form @submit.prevent="handleSubmit(validate)">
                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                <ValidationProvider name="Phone Type" rules="required" v-slot="{ errors }">
                                                        <b-form-group label-for="Name" :label="$t('Phone Type')">
                                                            
                                                            <b-form-input
                                                                type="text"
                                                                id="Name"
                                                                v-model="telephone_type.name"
                                                                :state="errors.length ? false : null"
                                                                placeholder=""
                                                            />
                                                            <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <span class="required_fields">
                                                <span class="required">*</span>
                                                <strong> {{ $t('Required Fields') }}</strong>
                                            </span>
                                            <div class="block text-right">
                                                <router-link to="/telephone_type" class="btn btn-back">
                                                    <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                                                </router-link>
                                                <b-button type="submit" variant="primary" class="ml-1">
                                                    {{ $t('Save') }}
                                                    <i class="fa fa-save"></i>
                                                </b-button>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
    import { required } from "vee-validate/dist/rules";
    import { BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton } from "bootstrap-vue";
    import axios from "axios";
    
    extend("required", required);
    
    export default {
        data() {
            return {
                telephone_type: {
                    name: ""
                },
                errors: undefined,
                previewImage: null,
            };
        },
        computed: {},
        methods: {
            async handleSubmit(validate) {
                const isValid = await validate();
                if (isValid) {
                    this.save();
                }
            },
            save() {
                const self = this;
                let api = self.$store.state.api + "telephone_type";
    
                if (self.telephone_type.id) {
                    self.telephone_type._method = "PUT";
                    api += "/" + self.telephone_type.id;
                }
    
                axios
                    .post(api, self.telephone_type)
                    .then(() => {
                        self.$message(
                            this.$t('Success'),
                            this.$t('Information saved successfully'),
                            "success"
                        );
                        self.$router.push("/telephone_type");
                    })
                    .catch((error) => {
                        self.$error(error);
                    });
            },
            getTelephoneType(id) {
                const self = this;
                const api = self.$store.state.api + "telephone_type/" + id;
    
                axios
                    .get(api)
                    .then((response) => {
                        self.telephone_type = response.data.data[0];
                    })
                    .catch((error) => {
                        self.$error(error);
                    });
            },
        },
        mounted() {
            const self = this;
            let id = self.$route.params.id;
    
            if (id) {
                self.getTelephoneType(id);
            }
        },
        components: {
            ValidationObserver,
            ValidationProvider,
            BForm,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton,
        },
    };
    </script>
    
    <style scoped>
    .required {
        color: red !important;
    }
    </style>
    