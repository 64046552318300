<template>
<div class="card content-crud">
    <div class="ibox" v-if="!$route.params.operation">
        <div class="ibox-title " style="padding: 16px">
            <div class="row mb-n2">
                <h3  :class="classForLanguage?'col-10 order-first':'col-10 order-last'">
                    {{ crudName }}
                </h3>
                <h3  :class="!classForLanguage?'col-auto order-first':'col-auto order-last'">
                    <router-link :to="endPoint + '/add'" class="btn btn-primary" v-show="enableAdd" >{{ newText }}</router-link>
                </h3>
            </div>
        </div>

        <div class="ibox-content">
            <div class="block m-b-md text-right" v-if="enableAddModal">
                <button type="button" class="btn btn-success" @click="addModal">
                    Adicionar {{ crudName }}
                </button>
            </div>

            <v-server-table style="padding: 10px" ref="table" :tableData="tableData" :name="table" :url="url" :columns="columns" :options="options">
                <div :slot="'filter__' + filter.name" v-for="filter in customFilters" :key="filter.name">
                    <template v-if="filter.type == 'calendar'">
                        <input type="date" class="form-control" :name="filter.call" :id="filter.name" @change="filterChange(filter.call, filter.name)" />
                    </template>
                </div>

                <div slot="actions" slot-scope="props">
                    <a v-show="enableDownload" data-toggle="tooltip" :title="$t('Download')" class="btn btn-crud check" @click="download(props.row.file)"><i class="fas fa-file-download"></i></a>
                    <button v-show="enableView" data-toggle="tooltip" :title="$t('view')" class="btn btn-crud view" @click="view(props.row.id)">
                        <i class="fas fa-eye" data-toggle="tooltip" :title="$t('view')"></i>
                    </button>
                    <button v-show="enableEdit" data-toggle="tooltip" :title="$t('To Edit')" type="button" class="btn btn-crud edit" @click="edit(props.row.id)">
                        <i class="fas fa-pencil-alt" data-toggle="tooltip" :title="$t('To Edit')"></i>
                    </button>
                    <a v-show="enableEditModal" @click="editModal(props.row.id)" data-toggle="tooltip" title="Editar" class="btn btn-warning fa fa-pencil"></a>
                    <button v-show="enableDelete" data-toggle="tooltip" :title="$t('delete')" type="button" class="btn btn-crud delete" @click="destroy(props, props.row, props.index)">
                        <i class="far fa-trash-alt" data-toggle="tooltip" :title="$t('delete')"></i>
                    </button>
                    <button v-show="enableReset" data-toggle="tooltip" :title="$t('Change Password')" class="btn btn-crud view key" @click="resetpassword(props.row.id)">
                        <i class="fas fa-key" data-toggle="tooltip" :title="$t('Change Password')"></i>
                    </button>
                    <template v-for="extra in extra">
                        <button data-toggle="tooltip" :title="extra.title" :key="extra.id" :class="extra.class" @click="callExtra(extra.name, props, props.row, props.index)">
                            <i :class="extra.icon" v-if="extra.icon.length > 0"></i>
                            {{ extra.label }}
                        </button>
                    </template>
                </div>
                <div slot="photo" slot-scope="props">
                    <img :src="props.row.photo" alt class="photo" />
                </div>
                <div slot="cover_photo" slot-scope="props">
                    <img :src="props.row.cover_photo" alt class="photo" />
                </div>
                <div slot="color" slot-scope="props">
                    <div class="box" v-bind:style="{ 'background-color': props.row.color }"></div>
                    <span class="bold">{{ props.row.color }}</span>
                </div>
                <div slot="qrcode" slot-scope="props">
                    <img :src="props.row.qrcode + ''" alt class="qrcode" />
                    <br />
                    <span class="bold">{{ props.row.qrcode_name }}</span>
                </div>
                <div slot="qrcodeid" slot-scope="props">
                    <qrcode-vue class="code_crud" size="70" :value="props.row.qrcodeid ? props.row.qrcodeid + '' : props.row.id + ''
              " level="H"></qrcode-vue>
                </div>
            </v-server-table>
        </div>
    </div>
</div>
</template>

<script>
import {
    Pagination
} from "vue-pagination-2";
// import BaseForm from "./BaseForm";
// import QrcodeVue from "qrcode.vue";

export default {
    data: function () {
        return {
            records: [],
            pages: [],
            tableData: [],
            model: [],
        };
    },
    props: {
        crudName: String,
        fieldStatus: String,
        status: String,
        newText: String,
        table: String,
        customFilters: Array,
        columns: Array,
        options: Object,
        endPoint: String,
        with: String,
        enableAdd: Boolean,
        enableAddModal: Boolean,
        enableEditModal: Boolean,
        enableEdit: Boolean,
        enableDelete: Boolean,
        enableView: Boolean,
        enableReset: Boolean,
        enableDownload: Boolean,
        extra: Array,
        dataForm: Object,
    },
    mounted() {
        this.enableAddModal = this.enableAddModal || false;
        this.enableEditModal = this.enableEditModal || false;
    },
    computed: {
        url() {
            return (
                this.$store.getters.api + this.endPoint + (this.with ? this.with : "")
            );
        },
        classForLanguage() {
            return !['he', 'ar'].includes(this.$i18n.locale);
        },
        confirmDelete()
        {
            return this.$t('confirm_delete')
        },
        undoImpossible()
        {
            return this.$t('undo_impossible')
        },
        no()
        {
            return this.$t('No')
        },
        yes()
        {
            return this.$t('Yes')
        },
        canceled()
        {
            return this.$t('canceled')
        },
        deleteFailed()
        {
            return this.$t('delete_failed')
        },
        success()
        {
            return this.$t('success')
        },
        deleteSuccess()
        {
            return this.$t('delete_success')
        },
        serverError()
        {
            return this.$t('server_error')
        },
        errorTitle(){
            return this.$t('Error')
        }
    },
    methods: {
        filterChange(call, date) {
            let valor = $("#" + date).val();
            valor = valor.replace("T", " ");

            // console.log(valor)

            this.$emit(call, valor);
            this.$refs.table.refresh();
        },
        callExtra(name, props, row, index) {
            this.$emit(name, {
                props,
                row,
                index,
            });
        },
        edit(id) {
            this.$router.push({
                path: this.endPoint + "/edit/" + id,
            });
        },
        resetpassword(id) {
            this.$router.push({
                path: this.endPoint + "/resetpassword/" + id,
            });
        },
        view(id) {
            this.$router.push({
                path: this.endPoint + "/view/" + id,
            });
        },
        download(file) {
            window.open(file, "_blank");
        },
        destroy(props, row, index) {
            const self = this;

            swal({
                title: self.confirmDelete,
                text: self.undoImpossible,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Sim, deletar!",
                showLoaderOnConfirm: true,
                buttons: {
                    catch: {
                        text: self.no,
                        value: "cancel",
                        className: "btn-danger",
                    },
                    confirm: {
                        text: self.yes,
                        value: "confirm",
                    },
                },
            }).then((value) => {
                switch (value) {
                    case "cancel":
                        swal(self.canceled, self.deleteFailed, "info");
                        break;

                    case "confirm":
                        axios
                            .delete(this.url + "/" + row.id, {
                                id: row.id,
                            })
                            .then((response) => {
                                // self.$refs.table.tableData.splice(index - 1, 1);
                                self.$refs.table.data.splice(index - 1, 1);
                                swal(self.success(), self.deleteSuccess, "success");
                            }).catch((error) => {
                                // Manipular erros
                                if (error.response) {
                                    // O servidor respondeu com um status de erro
                                    console.log("Erro de resposta do servidor:", error.response.data);
                                    console.log("Status do erro:", error.response.status);
                                    console.log("Cabeçalhos de resposta:", error.response.headers);
                                    // Aqui pode exibir uma mensagem de erro para o usuário, usando o swal ou outro método
                                    // swal("Erro", error.response.data, "error");
                                    swal(self.errorTitle, self.serverError, "error");
                                } else if (error.request) {
                                    // A solicitação foi feita, mas não houve resposta do servidor
                                    console.log("Sem resposta do servidor:", error.request);
                                } else {
                                    // Ocorreu um erro durante a configuração da solicitação
                                    console.log("Erro ao configurar a solicitação:", error.message);
                                }
                                console.log("Erro durante a solicitação:", error.config);
                            });
                        break;
                }
            });
        },
        addModal: function () {
            this.$emit("addModal");
        },
        editModal: function (id) {
            this.$emit("editModal", id);
        },
    },
    components: {
        Pagination,
    },
};
</script>

<style scoped>
.photo {
    max-height: 90px;
    max-width: 90px;
}

.key {
    border: 2px solid #ff7301;
}
</style>
