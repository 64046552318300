var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationObserver',{ref:"formObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(validate)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Centrals","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"center_id"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Centrals'))+" : ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.centers,"reduce":function (center) { return center.id; },"label":'name'},on:{"input":_vm.getUnitsCenter},model:{value:(_vm.licensing.center_id),callback:function ($$v) {_vm.$set(_vm.licensing, "center_id", $$v)},expression:"licensing.center_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"unit_id"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Unit'))+" : ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.units,"reduce":function (unit) { return unit.id; },"label":'name'},on:{"input":_vm.getVehicles},model:{value:(_vm.licensing.unit_id),callback:function ($$v) {_vm.$set(_vm.licensing, "unit_id", $$v)},expression:"licensing.unit_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Vehicle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"vehicle_id"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Vehicle'))+" : ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.vehicles,"reduce":function (vehicle) { return vehicle.id; },"label":'name'},model:{value:(_vm.licensing.vehicle_id),callback:function ($$v) {_vm.$set(_vm.licensing, "vehicle_id", $$v)},expression:"licensing.vehicle_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-2"},[_c('ValidationProvider',{attrs:{"name":"Ipva value","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"ipva_value"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Ipva value'))+" : ")]),_c('money',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"autocomplete":"new-password","type":"text","id":"ipva_value","placeholder":""},model:{value:(_vm.licensing.ipva_value),callback:function ($$v) {_vm.$set(_vm.licensing, "ipva_value", $$v)},expression:"licensing.ipva_value"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-2"},[_c('ValidationProvider',{attrs:{"name":"Licensing value","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"licensing_value"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Licensing value'))+" : ")]),_c('money',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"autocomplete":"new-password","type":"text","id":"licensing_value","placeholder":""},model:{value:(_vm.licensing.licensing_value),callback:function ($$v) {_vm.$set(_vm.licensing, "licensing_value", $$v)},expression:"licensing.licensing_value"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-2"},[_c('ValidationProvider',{attrs:{"name":"Liability insurance","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"liability_insurance"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Liability insurance'))+" : ")]),_c('money',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"autocomplete":"new-password","type":"text","id":"liability_insurance","placeholder":""},model:{value:(_vm.licensing.liability_insurance),callback:function ($$v) {_vm.$set(_vm.licensing, "liability_insurance", $$v)},expression:"licensing.liability_insurance"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Payment date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"payment_date"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Payment date'))+" : ")]),_c('b-form-input',{staticClass:"form-control",attrs:{"autocomplete":"new-password","type":"date","id":"payment_date","placeholder":"","state":errors.length ? false : null},model:{value:(_vm.licensing.payment_date),callback:function ($$v) {_vm.$set(_vm.licensing, "payment_date", $$v)},expression:"licensing.payment_date"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Situation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"situation"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Situation'))+" : ")]),_c('b-form-select',{staticClass:"form-control",attrs:{"state":errors.length ? false : null},model:{value:(_vm.licensing.situation),callback:function ($$v) {_vm.$set(_vm.licensing, "situation", $$v)},expression:"licensing.situation"}},[_c('option',{domProps:{"value":1}},[_vm._v(_vm._s(_vm.$t('Expired')))]),_c('option',{domProps:{"value":0}},[_vm._v(_vm._s(_vm.$t('Expiry on')))])]),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"description"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Description'))+" : ")]),_c('b-form-textarea',{staticClass:"form-control",attrs:{"id":"description","cols":"30","rows":"10","state":errors.length ? false : null},model:{value:(_vm.licensing.description),callback:function ($$v) {_vm.$set(_vm.licensing, "description", $$v)},expression:"licensing.description"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Requester","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"requester_id"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Requester'))+" : ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.professionals,"reduce":function (professional) { return professional.id; },"label":'name'},model:{value:(_vm.licensing.requester_id),callback:function ($$v) {_vm.$set(_vm.licensing, "requester_id", $$v)},expression:"licensing.requester_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Files"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"files"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Attach files'))+" : ")]),_c('upload-file',{class:{ 'is-invalid': errors.length },attrs:{"fileId":_vm.licensing.files},on:{"uploaded":function (event) { _vm.licensing.files = event }}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)]),_c('span',{staticClass:"required_fields"},[_c('span',{staticClass:"required"},[_vm._v("*")]),_c('strong',[_vm._v(_vm._s(_vm.$t('Required Fields')))])]),_c('div',{staticClass:"block text-right"},[_c('router-link',{staticClass:"btn btn-back",attrs:{"to":"/licensings"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t('To Go Back'))+" ")]),_c('b-button',{staticClass:"ml-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" "),_c('i',{staticClass:"fa fa-save"})])],1)])]}}])})],1)])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }