import Crud from '../views/Vehicle/Crud'
import Entity from '../views/Vehicle/Add'
import View from '../views/Vehicle/View'


let routes = {
    path: '/vehicles',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/vehicles/view/:id?',
            name: 'viewVehicle',
            component: View,
            meta: {
                pageTitle: 'Vehicle',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/vehicles'
                    },
                    {
                        text: 'View',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/vehicles/add',
            name: 'NewVehicle',
            component: Entity,
            meta: {
                pageTitle: 'Vehicle',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/vehicles'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/vehicles/edit/:id?',
            name: 'EditVehicle',
            component: Entity,
            meta: {
                pageTitle: 'Vehicle',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/vehicles'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/vehicles',
            name: 'list-vehicles',
            component: Crud,
            meta: {
                pageTitle: 'Vehicles',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
