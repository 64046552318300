<template>
<div>
    <!-- <div class="row mr-1">
      <div class="col-12" align="right">
        <a
          :href="$store.state.api + 'download/import-model'"
          target="_blank"
          class="btn btn-add"
          >Modelo de Importação</a
        >
        <button class="btn btn-add" @click="openInput">
          Importar Usuários
        </button>
        <input
          name="fileImport"
          id="fileImport"
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          style="display: none"
          value="null"
          @change="importFile"
        />
      </div>
      <div class="col-md-12 mt-4" v-if="showMessageImport">
        <div class="alert alert-success" role="alert">
          <strong>Planilha importada com sucesso!</strong>
          <br />
          Definimos uma senha padrão para todos os usuários, a senha definida é:
          <strong>Alterar123</strong>, sugerimos que os usuários alterem-a logo
          no primeiro acesso.
        </div>
      </div>
    </div> -->

    <div class="row">
        <div class="col-md-12">
            <base-crud :ref="'usersCrud'" :crudName="$t('Users')" :newText="$t('Add')+ '  '+$t('New')" :table="table" :columns="columns" :options="options" :endPoint="endPoint" :enableAdd="true" :enableEdit="true" :enableDelete="true" :enableView="false" :enableReset="false" :dataForm="dataForm">
            </base-crud>
        </div>
    </div>
</div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
    data: function () {
        return {
            table: "usersTable",
            showMessageImport: false,
            usersCrud: {},
            pages: [],
            url: "",
            columns: [
                "id",
                "photo",
                "name",
                "access_level",
                "email",
                "company_id",
                "actions",
            ],
            tableData: ["id", "photo", "name", "access_level", "email", "company_id"],
            optionsOrigin: {
                filterByColumn: true,
                debounce: 1000,
                filterable: ["name", "access_level", "email", "company_id"],
                pagination: {
                    chunk: 10,
                    dropdown: false,
                    nav: "scroll"
                },
                perPage: 10,
                perPageValues: [10, 25, 50, 100],
                headings: {
                    id: this.$t('id'),
                    photo: this.$t('image'),
                    name: this.$t('Name'),
                    access_level: this.$t('Access Level'),
                    email: this.$t('Email'),
                    company_id: this.$t('Company'),
                    actions: this.$t('action'),
                },

                texts: {
                    filterBy: this.$t('Filter'),
                    defaultOption: this.$t('Select'),
                },
                listColumns: {
                    access_level: [{
                            id: 1,
                            text: "Usuario Padrão",
                        },
                        {
                            id: 2,
                            text: "Gestor Empresa",
                        },
                        {
                            id: 6,
                            text: "Administrador",
                        },
                    ],
                },
                templates: {
                    access_level: function (h, row, index) {
                        return row.profile_type?.name??''
                    },
                    company_id: function (h, row, index) {
                        return row.company ? row.company.fantasy_name : "Sem Nome";
                    },
                    photo:function (h, row, index) {
                        return row.upload_files ? h('b-avatar', { props: { src: row.upload_files.url, size: '2rem' } }) : '';
                    },
                },
                requestFunction: function (data) {
                    let requestData = {};

                    let query = this.$parent.$parent.$parent.query(data.query);
                    requestData.params = data;
                    requestData.params.query = "";
                    requestData.params.with = ["company","uploadFiles","profileType"];

                    return axios.get(this.url + "?" + query, requestData).catch(
                        function (e) {
                            this.dispatch("error", e);
                        }.bind(this)
                    );
                },
            },
            endPoint: "users",
            dataForm: {},
        };
    },
    components: {
        BaseCrud,
    },
    computed: {
        options() {
            const headings = {
                id: this.$t('id'),
                photo: this.$t('image'),
                name: this.$t('Name'),
                access_level: this.$t('Access Level'),
                email: this.$t('Email'),
                company_id: this.$t('Company'),
                actions: this.$t('action'),
            }

            const texts = {
                filterBy: this.$t('Filter'),
                defaultOption: this.$t('Select'),
            }
            return {
                ...this.optionsOrigin,
                texts,
                headings,
            }

        }

    },
    methods: {
        makeFormData: function () {
            const self = this;
            let fd = new FormData();

            let fileImport = document.getElementById("fileImport");

            fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

            return fd;
        },
        importFile() {
            const self = this;
            let api = self.$store.state.api + "import-users";

            let fd = self.makeFormData();

            axios
                .post(api, fd)
                .then((response) => {
                    self.$message(
                        this.$t('Success'),
                        this.$t('Information saved successfully'),
                        "success"
                    );
                    self.showMessageImport = true;
                    self.$refs.usersCrud.$refs.table.refresh();
                })
                .catch((error) => {
                    self.$message(
                        "Ops",
                        "Houve um erro durante a importação da planilha, verifique a planilha e tente novamente.",
                        "error"
                    );
                });
        },
        query: function (query) {
            let columns = {
                id: "id",
                photo: "photo",
                name: "name",
                access_level: "access_level",
                email: "email",
                company_id: "company[fantasy_name]",
            };
            let filters = "";
            $.each(query, function (index, value) {
                filters += columns[index] + "=" + value + "&";
            });
            return filters;
        },
        openInput() {
            document.getElementById("fileImport").click();
        },
    },
};
</script>

<style scoped>
.VuePagination {
    text-align: center;
}

.uploading-image {
    display: flex;
    border-radius: 10px;
    width: 100px;
    height: 100px;
}
</style>
