import Crud from '../views/AddressType/Crud'
import Entity from '../views/AddressType/Add'

let routes = {
    path: '/address_type',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/address_type/add',
            name: 'NewAddressType',
            component: Entity,
            meta: {
                pageTitle: 'Type of address',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/address_type'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/address_type/edit/:id?',
            name: 'EditAddressType',
            component: Entity,
            meta: {
                pageTitle: 'Type of address',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/address_type'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/address_type',
            name: 'list-address_type',
            component: Crud,
            meta: {
                pageTitle: 'Types of address',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
