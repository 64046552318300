import Crud from '../views/AreaType/Crud'
import Entity from '../views/AreaType/Add'

let routes = {
    path: '/areatypes',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/areatypes/add',
            name: 'NewAreaType',
            component: Entity,
            meta: {
                pageTitle: 'Types of area',
                access_level: ['1'],
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/areatypes'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/areatypes/edit/:id?',
            name: 'EditAreaType',
            component: Entity,
            meta: {
                pageTitle: 'Types of area',
                access_level: ['1'],
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/areatypes'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/areatypes',
            name: 'list-areatypes',
            component: Crud,
            meta: {
                pageTitle: 'Types of area',
                access_level: ['1'],
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
