<template>
<div class="row">
    <div class="col-md-8" v-if="!viewOnly">
        <!-- :state="state"  -->
        <b-form-file v-model="itemFile" ref="file-input" variant="outline-primary" :accept="acceptFile" @change="previewImage" :placeholder="$t('Choose a file or drop it here...')" :drop-placeholder="$t('Drop file here...')"></b-form-file>

    </div>
    <div class="col-md-2 " v-if="!viewOnly">
        <b-button v-if="!Boolean(uploadFile.id)" variant="outline-danger" @click="clearFiles">{{ $t('Cancel')}}</b-button>
    </div>
    <div class="col-md-2 " v-if="!viewOnly">

        <b-button v-if="!Boolean(uploadFile.id)" variant="outline-primary" @click="uploading">{{ $t('Add')}}</b-button>
    </div>

    <div class="row m-1" v-if="uploadFile.name">
        <b-button v-if="isImage" pill variant="outline-primary" @click="showModal">
            <b-avatar :src="uploadFile.url" :size="size"></b-avatar>  
        </b-button>
        <b-button v-else pill variant="outline-primary" :href="uploadFile.url" download target="_blank">
            {{uploadFile.name}} <i class="fa fa-download"></i>
        </b-button>

    </div>
    <b-modal ref="image-modal" hide-footer title="">
        <b-img thumbnail fluid :src="uploadFile.url" v-if="uploadFile.url" :alt="uploadFile.name"></b-img>

    </b-modal>

</div>
</template>

<script>
export default {
    data() {
        return {
            itemFile: null,
            fileUrl: '',
            uploadFile: {
                name: '',
                url: '',
            }

        };
    },
    props: {
        fileId: {
            type: String | Number,
            default: '',
        },
        isImage: {
            type: Boolean,
            default: false,

        },
        size: {
            type: String,
            default: '6rem',
        },
        viewOnly: {
            type: Boolean,
            default: false,
        }

    },
    computed: {
        state() {
            return  Boolean(this.uploadFile.id );
        },
        filePath() {
            return this.uploadFile.url

        },
        id() {
            return this.fileId;

        },
        acceptFile() {
            return this.isIamge ? ' .jpg, .jpeg, .png, ' : '.jpg, .jpeg, .png, .pdf, .doc, .docx'

        }

    },
    methods: {
        showModal() {
            this.$refs['image-modal'].show()
        },
        clearFiles() {
            this.$refs['file-input'].reset()
            this.uploadFile = {
                name: '',
                url: '',
            }
        },
        previewImage(event) {
            this.uploadFile = {
                name: '',
                url: '',
            }
            const file = event.target.files[0];
            this.uploadFile.name = file.name
            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    this.uploadFile.url = reader.result;
                };
            }
        },
        uploading() {
            const formData = new FormData();
            formData.append('file', this.itemFile);
            const api = `${this.$store.state.api}upload-file`;
            axios.post(api, formData)
                .then(response => {
                    this.uploadFile = response.data.data;
                    this.$emit('uploaded', response.data.data.id)
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getFile(id) {
            let api = this.$store.state.api + "uploadfiles/" + id;
            axios
                .get(api)
                .then((response) => {
                    this.uploadFile = response.data.data[0];
                })
                .catch((error) => {
                    this.$error(error);
                });

        }
    },
    watch: {
        id: function (newId, oldId) {
            // Faça uma solicitação ao backend para carregar dados com base no novo id
            if (newId && newId != oldId) {

                this.getFile(newId);
            }
        }
    }
};
</script>
