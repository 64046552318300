<template>
    <div>
        <div class="main-content">
            <div class="card">
                <div class="card-body">
                    <ValidationObserver ref="formObserver" v-slot="{ validate }">
                        <b-form @submit.prevent="handleSubmit(validate)">
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                <ValidationProvider name="Type Of Professional" rules="required" v-slot="{ errors }">
                                            <label for="Name">
                                                <span class="required">*</span> {{ $t('Type Of Professional') }}:
                                            </label>
                                            <b-form-input
                                                type="text"
                                                id="Name"
                                                v-model="typeProfessional.name"
                                                :state="errors.length ? false : null"
                                                placeholder=""
                                            />
                                            <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                        </ValidationProvider>
                                    </div>
                            </div>
                            <span class="required_fields">
                                <span class="required">*</span>
                                <strong> {{ $t('Required Fields') }}</strong>
                            </span>
                            <div class="block text-right">
                                <router-link to="/typeprofessionals" class="btn btn-back">
                                    <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                                </router-link>
                                <b-button type="submit" variant="primary" class="ml-1">
                                    {{ $t('Save') }}
                                    <i class="fa fa-save"></i>
                                </b-button>
                            </div>
                        </b-form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
    import { required } from "vee-validate/dist/rules";
    import { BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton } from "bootstrap-vue";
    import axios from "axios";
    
    extend("required", required);
    
    export default {
        data() {
            return {
                typeProfessional: {
                    name: ""
                },
                errors: undefined,
                previewImage: null,
            };
        },
        computed: {},
        methods: {
            async handleSubmit(validate) {
                const isValid = await validate();
                if (isValid) {
                    this.save();
                }
            },
            save() {
                const self = this;
                let api = self.$store.state.api + "typeprofessionals";
    
                if (self.typeProfessional.id) {
                    self.typeProfessional._method = "PUT";
                    api += "/" + self.typeProfessional.id;
                }
    
                axios
                    .post(api, self.typeProfessional)
                    .then(() => {
                        self.$message(
                            this.$t('Success'),
                            this.$t('Information saved successfully'),
                            "success"
                        );
                        self.$router.push("/typeprofessionals");
                    })
                    .catch((error) => {
                        self.$error(error);
                    });
            },
            getTypeProfessionals(id) {
                const self = this;
                const api = self.$store.state.api + "typeprofessionals/" + id;
    
                axios
                    .get(api)
                    .then((response) => {
                        self.typeProfessional = response.data.data[0];
                    })
                    .catch((error) => {
                        self.$error(error);
                    });
            },
        },
        mounted() {
            const self = this;
            let id = self.$route.params.id;
    
            if (id) {
                self.getTypeProfessionals(id);
            }
        },
        components: {
            ValidationObserver,
            ValidationProvider,
            BForm,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton,
        },
    };
    </script>
    
      