<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <ValidationObserver ref="formObserver" v-slot="{ invalid, validate }">
                    <b-form @submit.prevent="handleSubmit(validate)">
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                            <b-form-group label-for="Name" :label="$t('Name')">
                              
                              <b-form-input
                                type="text"
                                id="Name"
                                v-model="criticalitytype.name"
                                :state="errors.length ? false : null"
                                placeholder=""
                              />
                              <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </b-form-group>
                          </ValidationProvider>
                        </div>
                      </div>
                      <span class="required_fields">
                        <span class="required">*</span>
                        <strong>{{ $t('Required Fields') }}</strong>
                      </span>
                      <div class="block text-right">
                        <router-link to="/criticalitytypes" class="btn btn-back">
                          <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                        </router-link>
                        <b-button type="submit" variant="primary" class="ml-1" :disabled="invalid">
                          {{ $t('Save') }}
                          <i class="fa fa-save"></i>
                        </b-button>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { BButton, BForm, BFormInput, BFormGroup, BFormInvalidFeedback } from "bootstrap-vue";
import axios from 'axios';

extend("required", required);

export default {
  data() {
    return {
      criticalitytype: {
        name: ""
      },
      errors: undefined,
      previewImage: null,
    };
  },
  methods: {
    async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
    save() {
      let api = this.$store.state.api + "criticalitytypes";
      if (this.criticalitytype.id) {
        this.criticalitytype._method = "PUT";
        api += "/" + this.criticalitytype.id;
      }
      axios
        .post(api, this.criticalitytype)
        .then(() => {
          this.$message(
            this.$t('Success'),
            this.$t('Information saved successfully'),
            "success"
          );
          this.$router.push("/criticalitytypes");
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getCriticalityType(id) {
      let api = this.$store.state.api + "criticalitytypes/" + id;
      axios
        .get(api)
        .then((response) => {
          this.criticalitytype = response.data.data[0];
        })
        .catch((error) => {
          this.$error(error);
        });
    },
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.getCriticalityType(id);
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
  },
};
</script>

<style scoped>
.required {
  color: red !important;
}
</style>
