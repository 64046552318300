<template>
    <div>
      <div class="main-content">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <ValidationObserver ref="formObserver" v-slot="{ invalid, validate }">
                      <b-form @submit.prevent="handleSubmit(validate)">
                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <ValidationProvider name="Operator" rules="required" v-slot="{ errors }">
                                <label for="operator">
                                    <span class="required">*</span> {{ $t('Operator') }}:
                                </label>
                                <v-select :options="operators" :reduce="(operator) => operator.id" :label="'name'" v-model="chip.operator_id" class="vselect" 
                                  :class="{ 'is-invalid': errors.length }" />
                                  <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                            </ValidationProvider>
                          </div>
                          <div class="form-group input-group-sm col-md-6">
                            <ValidationProvider name="ICCID" rules="required" v-slot="{ errors }">
                                <label for="iccid">
                                    <span class="required">*</span> {{ $t('Integrated Circuit Card Identifier') }}:
                                </label>
                                
                                <b-form-input type="text" id="iccid" v-model="chip.iccid" :state="errors.length ? false : null" />
                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                          <div class="form-group input-group-sm col-md-6">
                            <ValidationProvider name="Telephone" rules="required" v-slot="{ errors }">
                                <label for="Phone">
                                    <span class="required">*</span> {{ $t('Telephone') }}:
                                </label>
                                
                                <b-form-input type="text" id="Phone" v-model="chip.telephone" v-mask="'(99) 9999-9999'" :state="errors.length ? false : null" />
                                <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                        </div>
                        <span class="required_fields">
                          <span class="required">*</span>
                          <strong>{{ $t('Required Fields') }}</strong>
                        </span>
                        <div class="block text-right">
                          <router-link to="/chips" class="btn btn-back">
                            <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                          </router-link>
                          <b-button type="submit" variant="primary" class="ml-1">
                            {{ $t('Save') }}
                            <i class="fa fa-save"></i>
                          </b-button>
                        </div>
                      </b-form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  import { BButton, BForm, BFormInput, BFormGroup, BFormInvalidFeedback } from "bootstrap-vue";
  import vSelect from "vue-select";
  
  extend("required", required);
  
  export default {
    data() {
      return {
        chip: {
          operator_id: '',
          iccid: '',
          telephone: '',
        },
        operators: [],
        errors: undefined,
      };
    },
    methods: {
      async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
      save() {
        let api = this.$store.state.api + "chips";
        if (this.chip.id) {
          this.chip._method = "PUT";
          api += "/" + this.chip.id;
        }
        axios
          .post(api, this.chip)
          .then(() => {
            this.$message(
              this.$t('Success'),
              this.$t('Information saved successfully'),
              "success"
            );
            this.$router.push("/chips");
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getChip(id) {
        let api = this.$store.state.api + "chips/" + id;
        axios
          .get(api)
          .then((response) => {
            this.chip = response.data.data[0];
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getOperators() {
        let api = this.$store.state.api + "operators";
        axios
          .get(api)
          .then((response) => {
            this.operators = response.data.data;
          })
          .catch((error) => {
            this.$error(error);
          });
      },
    },
    mounted() {
      const id = this.$route.params.id;
      if (id) {
        this.getChip(id);
      }
      this.getOperators();
    },
    components: {
      ValidationObserver,
      ValidationProvider,
      BButton,
      BForm,
      BFormInput,
      BFormGroup,
      BFormInvalidFeedback,
      vSelect,
    },
  };
  </script>
  
  <style scoped>
  .required {
    color: red !important;
  }
  </style>
  