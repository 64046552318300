import Crud from '../views/VehicleType/Crud'
import Entity from '../views/VehicleType/Add'

let routes = {
    path: '/vehicle_types',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/vehicle_types/add',
            name: 'NewVehicleType',
            component: Entity,
            meta: {
                pageTitle: 'Vehicle Type',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/vehicle_types'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/vehicle_types/edit/:id?',
            name: 'EditVehicleType',
            component: Entity,
            meta: {
                pageTitle: 'Vehicle Type',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/vehicle_types'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/vehicle_types',
            name: 'list-vehicle_types',
            component: Crud,
            meta: {
                pageTitle: 'Vehicle Types',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
