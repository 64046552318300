<template>
<div>
    <div class="row">
        <div class="col-md-12">
            <base-crud :ref="'deviceCrud'" :crudName="$t('Devices')" :newText="$t('Add')+ '  '+$t('New')" :table="table" :columns="columns" :options="options" :endPoint="endPoint" :enableAdd="true" :enableEdit="true" :enableDelete="true" :enableView="false" :enableReset="false" :dataForm="dataForm">
            </base-crud>
        </div>
    </div>
</div>
</template>

    
    
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
    data: function () {
        return {
            table: "deviceTable",
            showMessageImport: false,
            deviceCrud: {},
            pages: [],
            url: "",
            columns: [
                "id",
                // "center_id",
                "unit_id",
                "supplier_id",
                "device_model_id",
                // "chip_id",
                // "firmware",
                // "serial_number",
                "imei",
                "acquisition_date",
                "actions",
            ],
            tableData: ["id",
                // "center_id",
                "unit_id",
                "supplier_id",
                "device_model_id",
                // "chip_id",
                // "firmware",
                // "serial_number",
                "imei",
                "acquisition_date",
            ],
            optionsOrigin: {
                filterByColumn: true,
                debounce: 1000,
                filterable: ["device_model_id","supplier_id", "unit_id","imei"],
                pagination: {
                    chunk: 10,
                    dropdown: false,
                    nav: "scroll"
                },
                perPage: 10,
                perPageValues: [10, 25, 50, 100],
                headings: {
                    id: this.$t('id'),
                    // center_id: this.$t('Central'),
                    unit_id: this.$t('Unit'),
                    supplier_id: this.$t('Supplier'),
                    device_model_id: this.$t('Device Model'),
                    // chip_id: this.$t('Chip'),
                    imei: this.$t('Imei'),
                    // firmware: this.$t('Firmware'),
                    // serial_number: this.$t('Serial Number'),
                    acquisition_date: this.$t('acquisition date'),
                    actions: this.$t('action'),
                },

                texts: {
                    filterBy: this.$t('Filter'),
                    defaultOption: this.$t('Select'),
                },
                listColumns: {

                },
                templates: {
                    supplier_id: function (h, row, index) {
                        return row.supplier?.name?? ''
                    },
                    center_id: function (h, row, index) {
                        return row.center?.name?? ''
                    },
                    unit_id: function (h, row, index) {
                        return row.unit?.name?? ''
                    },
                    supplier_id: function (h, row, index) {
                        return row.supplier?.name?? ''
                    },
                    device_model_id: function (h, row, index) {
                        return row.device_model?.name?? ''
                    },
                    chip_id: function (h, row, index) {
                        return row.chip?.operator?.name?? ''
                    },
                },
                requestFunction: function (data) {
                    let requestData = {};

                   

                    let query = this.$parent.$parent.$parent.query(data.query);
                    requestData.params = data;
                    requestData.params.query = "";
                    requestData.params.with = ["supplier","center","unit","deviceModel","chip"];

                    return axios.get(this.url + "?" + query, requestData).catch(
                        function (e) {
                            this.dispatch("error", e);
                        }.bind(this)
                    );
                },
            },
            endPoint: "devices",
            dataForm: {},
        };
    },
    components: {
        BaseCrud,
    },
    computed: {
        options() {
            const headings = {
                id: this.$t('id'),
                // center_id: this.$t('Central'),
                unit_id: this.$t('Unit'),
                supplier_id: this.$t('Supplier'),
                device_model_id: this.$t('Device Model'),
                // chip_id: this.$t('Chip'),
                imei: this.$t('IMEI'),
                // firmware: this.$t('Firmware'),
                // serial_number: this.$t('Serial Number'),
                acquisition_date: this.$t('acquisition date'),
                actions: this.$t('action'),
            }

            const texts = {
                filterBy: this.$t('Filter'),
                defaultOption: this.$t('Select'),
            }
            return {
                ...this.optionsOrigin,
                texts,
                headings,
            }

        }

    },
    methods: {

        query: function (query) {
            let columns = {
                id: 'id',
                center_id: 'center_id',
                unit_id: 'unit_id',
                supplier_id: 'supplier_id',
                device_model_id: 'device_model_id',
                chip_id: 'chip_id',
                imei: 'imei',
                firmware: 'firmware',
                acquisition_date: 'acquisition_date',

            };
            let filters = "";
            $.each(query, function (index, value) {
                filters += columns[index] + "=" + value + "&";
            });
            return filters;
        },

    },
};
</script>
    
    
<style scoped>
.VuePagination {
    text-align: center;
}
</style>
