
import Crud from '../views/DeviceModel/Crud'
import Entity from '../views/DeviceModel/Add'

const routes = {
    path: '/deviceModels',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/deviceModels/add',
            name: 'NewdeviceModels',
            component: Entity,
            meta: {
                pageTitle: 'Device Model',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/deviceModels'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/deviceModels/edit/:id?',
            name: 'EditdeviceModels',
            component: Entity,
            meta: {
                pageTitle: 'Device Model',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/deviceModels'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/deviceModels',
            name: 'list-deviceModels',
            component: Crud,
            meta: {
                pageTitle: 'Devices Model',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
