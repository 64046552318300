<template>
    <div class="main-content">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <ValidationObserver ref="formObserver" v-slot="{ validate }">
                <b-form @submit.prevent="handleSubmit(validate)">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <ValidationProvider name="Central" rules="required" v-slot="{ errors }">
                        <label for="Central">
                                    <span class="required">*</span> {{ $t('Central') }}:
                                </label>
                          <v-select
                            :options="centers"
                            :reduce="(center) => center.id"
                            :label="'name'"
                            v-model="data.center_id"
                            class="vselect"
                            @input="changetUnitsCenter"
                            :class="{ 'is-invalid': errors.length }" />
                            <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-6">
                      <ValidationProvider name="Unit" rules="required" v-slot="{ errors }">
                          <label for="Unit">
                              <span class="required">*</span> {{ $t('Unit') }}:
                          </label>
                          <v-select
                            :options="units"
                            :reduce="(unit) => unit.id"
                            :label="'name'"
                            v-model="data.unit_id"
                            class="vselect"
                            @input="changeVehicleByUnits"
                            :class="{ 'is-invalid': errors.length }" />
                            <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-6">
                      <ValidationProvider name="Device" rules="required" v-slot="{ errors }">
                          <label for="Device">
                              <span class="required">*</span> {{ $t('Device') }} ({{ $t('IMEI') }})
                          </label>
                          <v-select
                            :options="devices"
                            :reduce="(device) => device.id"
                            :label="'imei'"
                            v-model="data.device_id"
                            class="vselect"
                            :class="{ 'is-invalid': errors.length }" />
                              <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-6">
                      <ValidationProvider name="Vehicle" rules="required" v-slot="{ errors }">
                        <label for="Vehicle">
                              <span class="required">*</span> {{ $t('Vehicle') }} ({{ $t('Surname') }}):
                          </label>
                        <v-select
                          :options="vehicles"
                          :reduce="(vehicle) => vehicle.id"
                          :label="'name'"
                          v-model="data.vehicle_id"
                          class="vselect"
                          :class="{ 'is-invalid': errors.length }" />
                          <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-2 mt-2">
                      <b-form-checkbox
                        id="status"
                        v-model="data.status"
                        name="status"
                        :value="1"
                        :unchecked-value="0"
                      >
                        <label for="status">
                          <span class="required">*</span> {{ $t('Status') }} : {{ checkStatus }}
                        </label>
                      </b-form-checkbox>
                    </div>
                    <div class="form-group col-md-2" v-if="Boolean(data.status)">
                      <ValidationProvider name="Activation Date" rules="required|date_format:YYYY-MM-DD" v-slot="{ errors }">
                          <label for="ActivationDate">
                              <span class="required">*</span> {{ $t('Activation Date') }}:
                          </label>
                          <b-form-input
                            autocomplete="new-password"
                            class="form-control"
                            id="activation_date"
                            type="date"
                            v-model="data.activation_date"
                            placeholder=""
                            :state="errors.length ? false : null"
                          />
                          <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-2" v-if="Boolean(!data.status)">
                      <ValidationProvider name="Deactivation Date" rules="required|date_format:YYYY-MM-DD" v-slot="{ errors }">
                          <label for="DeactivationDate">
                              <span class="required">*</span> {{ $t('Deactivation Date') }}:
                          </label>
                          <b-form-input
                            autocomplete="new-password"
                            class="form-control"
                            id="deactivation_date"
                            type="date"
                            v-model="data.deactivation_date"
                            placeholder=""
                            :state="errors.length ? false : null"
                          />
                          <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-8" v-if="Boolean(data.status)">
                      <b-form-group :label="$t('Activation Description')">
                        <b-form-input
                          type="text"
                          id="activation_description"
                          class="form-control"
                          v-model="data.activation_description"
                          placeholder=""
                        />
                      </b-form-group>
                    </div>
                    <div class="form-group col-md-8" v-if="Boolean(!data.status)">
                      <b-form-group :label="$t('Deactivation Description')">
                        <b-form-input
                          type="text"
                          id="deactivation_description"
                          class="form-control"
                          v-model="data.deactivation_description"
                          placeholder=""
                        />
                      </b-form-group>
                    </div>
                  </div>
                  <span class="required_fields">
                    <span class="required">*</span>
                    <strong> {{ $t('Required Fields') }}</strong>
                  </span>
                  <div class="block text-right">
                    <router-link to="/deviceactivations" class="btn btn-back">
                      <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                    </router-link>
                    <b-button type="submit" variant="primary" class="ml-1">
                      {{ $t('Save') }}
                      <i class="fa fa-save"></i>
                    </b-button>
                  </div>
                </b-form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
  import { required } from 'vee-validate/dist/rules';
  import { BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton } from 'bootstrap-vue';
  import vSelect from "vue-select";
  import axios from 'axios';
  
  extend('required', required);
  extend('date_format', {
    validate(value, { format }) {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      return regex.test(value);
    },
    params: ['format'],
    message: 'The {_field_} field must be in the format YYYY-MM-DD'
  });
  
  export default {
    data() {
      return {
        data: {
          center_id: '',
          unit_id: '',
          vehicle_id: '',
          device_id: '',
          activation_date: '',
          deactivation_date: '',
          activation_description: '',
          deactivation_description: '',
          status: 0,
        },
        centers: [],
        units: [],
        devices: [],
        vehicles: [],
        errors: undefined,
      };
    },
    computed: {
      checkStatus() {
        const statusLabel = [this.$t('Inactive'), this.$t('Active')];
        return statusLabel[this.data.status];
      },
    },
    methods: {
      async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
      changetUnitsCenter(center_id) {
        this.data.unit_id = '';
        this.getUnitsCenter(center_id);
      },
      changeVehicleByUnits(unit_id) {
        this.data.vehicle_id = '';
        this.data.device_id = '';
        this.getVehicles(unit_id);
        this.getDevicesByUnit(unit_id);
      },
      save() {
        let api = `${this.$store.state.api}deviceactivations/data`;
  
        if (this.data.id) {
          this.data._method = "PUT";
          api += "/" + this.data.id;
        }
  
        axios
          .post(api, this.data)
          .then(() => {
            this.$message(
              this.$t('Success'),
              this.$t('Information saved successfully'),
              "success"
            );
            this.$router.push("/deviceactivations");
          })
          .catch((error) => {
            console.log('error', error.response.data)
            let errors = error?.response?.data?? null;

          if(errors){
            this.$message(
              this.$t('Error'),
              errors,
              "error"
            );
            return 
          } 

            this.$error(error);
          });
      },
      getDeviceActivations(id) {
        let api = this.$store.state.api + "deviceactivations/" + id;
  
        axios
          .get(api)
          .then((response) => {
            this.data = response.data.data[0];
            this.getUnitsCenter(this.data.center_id);
            this.getVehicles(this.data.unit_id);
            this.getDevicesByUnit(this.data.unit_id);
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getCenters() {
        let api = this.$store.state.api + "centers/";
  
        axios
          .get(api)
          .then((response) => {
            this.centers = response.data.data;
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getUnitsCenter(center_id) {
        const api = `${this.$store.state.api}centers/unit/${center_id}`;
        axios
          .get(api)
          .then((response) => {
            this.units = response.data;
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getVehicles(unit_id) {
        let api = this.$store.state.api + "units/vehicles/" + unit_id;
  
        axios
          .get(api)
          .then((response) => {
            this.vehicles = response.data;
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getDevicesByUnit(unit_id) {
        let api = this.$store.state.api + "units/devices/" + unit_id;
  
        axios
          .get(api)
          .then((response) => {
            this.devices = response.data;
          })
          .catch((error) => {
            this.$error(error);
          });
      },
    },
    mounted() {
      const id = this.$route.params.id;
      if (id) {
        this.getDeviceActivations(id);
      }
  
      this.getCenters();
    },
    components: {
      ValidationObserver,
      ValidationProvider,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      vSelect,
    },
  };
  </script>
  
  <style scoped>
  .required {
    color: red !important;
  }
  </style>
  