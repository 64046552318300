<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <ValidationObserver ref="formObserver" v-slot="{ invalid, validate }">
                    <b-form @submit.prevent="handleSubmit(validate)">
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                              <label for="Name">
                              <span class="required">*</span> {{ $t('Name') }}:
                            </label>
                              <b-form-input
                                id="Name"
                                type="text"
                                v-model="insurance.name"
                                autocomplete="new-password"
                                :state="errors.length ? false : null"
                                placeholder=""
                              />
                              <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-6">
                          <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                              <label for="status">
                              <span class="required">*</span> {{ $t('Status') }}:
                            </label>
                              <b-form-select
                                id="status"
                                v-model="insurance.status"
                                :state="errors.length ? false : null"
                              >
                                <option :value="1"> {{ $t('Active') }} </option>
                                <option :value="0"> {{ $t('Inactive') }} </option>
                              </b-form-select>
                              <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </div>
                      </div>
                      <span class="required_fields">
                        <span class="required">*</span>
                        <strong>{{ $t('Required Fields') }}</strong>
                      </span>
                      <div class="block text-right">
                        <router-link to="/insurances" class="btn btn-back">
                          <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                        </router-link>
                        <b-button type="submit" variant="primary" class="ml-1">
                          {{ $t('Save') }}
                          <i class="fa fa-save"></i>
                        </b-button>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { BButton, BForm, BFormInput, BFormGroup, BFormSelect, BFormInvalidFeedback } from "bootstrap-vue";

// Add the rules to vee-validate
extend("required", required);

export default {
  name: "insuranceForm",
  components: {
    BaseCrud,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormInvalidFeedback,
  },
  data() {
    return {
      insurance: {
        name: '',
        status: 1
      },
      errors: undefined,
      previewImage: null,
    };
  },
  methods: {
    async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
    save() {
      let api = this.$store.state.api + "insurances";
      if (this.insurance.id) {
        this.insurance._method = "PUT";
        api += "/" + this.insurance.id;
      }
      axios
        .post(api, this.insurance)
        .then(() => {
          this.$message(
            this.$t('Success'),
            this.$t('Information saved successfully'),
            "success"
          );
          this.$router.push("/insurances");
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    getInsurance(id) {
      let api = this.$store.state.api + "insurances/" + id;
      axios
        .get(api)
        .then((response) => {
          this.insurance = response.data.data[0];
        })
        .catch((error) => {
          this.$error(error);
        });
    },
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.getInsurance(id);
    }
  },
};
</script>

<style scoped>
.profile_user {
  text-align: insurance;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>
