<template>
    <div>
      <div class="main-content">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <ValidationObserver ref="formObserver" v-slot="{ invalid, validate }">
                      <b-form @submit.prevent="handleSubmit(validate)">
                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <ValidationProvider name="Company" rules="required" v-slot="{ errors }">
                                <label for="company">
                                      <span class="required">*</span> {{ $t('Company') }}:
                                </label>
                                <v-select
                                  id="company"
                                  :options="companies"
                                  :reduce="(company) => company.id"
                                  :label="'name'"
                                  v-model="center.company_id"
                                  class="vselect"
                                  :class="{ 'is-invalid': errors.length }" />
                                  <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-6">
                            <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                                <label for="Name">
                                      <span class="required">*</span> {{ $t('Name') }}:
                                </label>
                                <b-form-input
                                id="Name"
                                type="text"
                                v-model="center.name"
                                autocomplete="new-password"
                                :state="errors.length ? false : null"
                                placeholder=""
                                />
                                <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </div>
                            <div class="form-group col-md-6">
                              <ValidationProvider name="Contact" rules="required" v-slot="{ errors }">
                                <label for="contact">
                                      <span class="required">*</span> {{ $t('Name the person responsible') }}:
                                </label>
                                <b-form-input
                                id="contact"
                                type="text"
                                v-model="center.contact"
                                autocomplete="new-password"
                                :state="errors.length ? false : null"
                                placeholder=""
                                />
                                <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </div>
                            <div class="form-group col-md-6">
                              <ValidationProvider name="Phone" rules="required" v-slot="{ errors }">
                                <label for="phone">
                                      <span class="required">*</span> {{ $t('Telephone') }}:
                                </label>
                                <b-form-input
                                id="phone"
                                type="text"
                                v-mask="['(99) 99999-9999','(99) 9999-9999']"
                                v-model="center.phone"
                                autocomplete="new-password"
                                  :state="errors.length ? false : null"
                                  placeholder=""
                                  />
                                  <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                                </ValidationProvider>
                              </div>
                              <div class="form-group col-md-6">
                                <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                                  <label for="email">
                                        <span class="required">*</span> {{ $t('Email') }}:
                                  </label>
                                  <b-form-input
                                  id="email"
                                  type="email"
                                  v-model="center.email"
                                  autocomplete="new-password"
                                  :state="errors.length ? false : null"
                                  placeholder=""
                                  />
                                <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-12">
                            <ValidationProvider name="Address"  v-slot="{ errors }">
                                <label for="address">
                                      <span class="required">*</span> {{ $t('Address') }}:
                                </label>
                                <AddressAutocomplete
                                  :autocompleteValueProp="center.address"
                                  @placeSelected="onPlaceSelected"
                                  @placeChange="(event)=>{center.address = event}"
                                  :class="{ 'is-invalid': errors.length }" />
                                  <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-6">
                            <ValidationProvider name="Application DNS" rules="required" v-slot="{ errors }">
                                <label for="application_dns">
                                      <span class="required">*</span> {{ $t('Application_dns') }}:
                                </label>
                                <b-form-input
                                  id="application_dns"
                                  type="text"
                                  v-model="center.application_dns"
                                  autocomplete="new-password"
                                  :state="errors.length ? false : null"
                                  placeholder=""
                                />
                                <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-3">
                            <ValidationProvider name="Username WS" rules="required" v-slot="{ errors }">
                                <label for="username_ws">
                                      <span class="required">*</span> {{ $t('Username_ws') }}:
                                </label>
                                <b-form-input
                                  id="username_ws"
                                  type="text"
                                  v-model="center.username_ws"
                                  autocomplete="new-password"
                                  :state="errors.length ? false : null"
                                  placeholder=""
                                />
                                <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-3">
                            <ValidationProvider name="Password WS" rules="required" v-slot="{ errors }">
                                <label for="password_ws">
                                      <span class="required">*</span> {{ $t('Password_ws') }}:
                                </label>
                                <b-form-input
                                  id="password_ws"
                                  type="text"
                                  v-model="center.password_ws"
                                  autocomplete="new-password"
                                  :state="errors.length ? false : null"
                                  placeholder=""
                                />
                                <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-10">
                            <b-form-group label-for="area" :label="$t('Area')">
                              <add-to-list
                                :list="areas"
                                model="areas"
                                :listSelected="areasSelected"
                                @updateList="(event)=>{areaCenters = event}"
                                @removeItem="removeArea"
                              />
                            </b-form-group>
                          </div>
                          <div class="form-group col-md-2">
                            <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                                <label for="status">
                                      <span class="required">*</span> {{ $t('Status') }}:
                                </label>
                                <b-form-select
                                  id="status"
                                  v-model="center.status"
                                  :state="errors.length ? false : null"
                                >
                                  <option :value="1"> {{ $t('Active') }} </option>
                                  <option :value="0"> {{ $t('Inactive') }} </option>
                                </b-form-select>
                                <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                        </div>
                        <span class="required_fields">
                          <span class="required">*</span>
                          <strong>{{ $t('Required Fields') }}</strong>
                        </span>
                        <div class="block text-right">
                          <router-link to="/centers" class="btn btn-back">
                            <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                          </router-link>
                          <b-button type="submit" variant="primary" class="ml-1">
                            {{ $t('Save') }}
                            <i class="fa fa-save"></i>
                          </b-button>
                        </div>
                      </b-form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import BaseCrud from "../../layouts/Base/BaseCrud";
  import vSelect from "vue-select";
  import AddressAutocomplete from "../../layouts/Mapa/AddressAutocomplete";
  import AddToList from "../../layouts/components/AddToList";
  import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";
  import { BButton, BForm, BFormInput, BFormGroup, BFormSelect, BFormInvalidFeedback } from "bootstrap-vue";
  
  // Add the rules to vee-validate
  extend("required", required);
  extend("email", email);
  
  export default {
    name: "centerForm",
    components: {
      BaseCrud,
      vSelect,
      AddressAutocomplete,
      AddToList,
      ValidationObserver,
      ValidationProvider,
      BButton,
      BForm,
      BFormInput,
      BFormGroup,
      BFormSelect,
      BFormInvalidFeedback,
    },
    data() {
      return {
        center: {
          name: '',
          state_id: '',
          neighborhood_id: '',
          municipality_id: '',
          address_geometry: '',
          area_id: '',
          address: '',
          phone: '',
          contact: '',
          email: '',
          application_dns: '',
          username_ws: '',
          password_ws: '',
          company_id: '',
          status: 1,
        },
        companies: [],
        areaCenters: [],
        areas: [],
        areaCentersSaved: [],
        errors: undefined,
        previewImage: null,
      };
    },
    computed: {
      areasSelected() {
        return this.areaCenters ?? [];
      },
    },
    methods: {
      async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
      onPlaceChanged(event) {
        this.center.address = event;
        this.center.state_id = '';
        this.center.address_geometry = '';
        this.center.municipality_id = '';
        this.center.neighborhood_id = '';
      },
      onPlaceSelected(place) {
        this.center.address = place.address;
        this.center.address_geometry = JSON.stringify(place.address_geometry);
        this.center.state_id = place.municipality.state_id;
        this.center.municipality_id = place.neighborhood.municipality_id;
        this.center.neighborhood_id = place.neighborhood.id;
      },
      removeArea(item) {
        if (item.id) {
          return this.deleteAreacenters(item.id);
        }
      },
      deleteAreacenters(id) {
        let api = this.$store.state.api + "areacenters/" + id;
        axios
          .delete(api)
          .then(() => {
            this.getAreasCenter(this.center.id);
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      save() {
        if (!this.center.address || this.center.address === '') {
          this.onPlaceChanged('');
        }
        let api = this.$store.state.api + "centers";
        if (this.center.id) {
          this.center._method = "PUT";
          api += "/" + this.center.id;
        }
        axios
          .post(api, this.center)
          .then((response) => {
            if (!this.center.id && response.data && response.data.id) {
              this.center = response.data;
            }
            this.saveAreaCenters();
            this.$message(
              this.$t('Success'),
              this.$t('Information saved successfully'),
              "success"
            );
            this.$router.push("/centers");
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      saveAreas(arreaCentres) {
        let api = this.$store.state.api + "areacenters";
        if (arreaCentres.id) {
          arreaCentres._method = "PUT";
          api += "/" + arreaCentres.id;
        }
        axios
          .post(api, arreaCentres)
          .then((response) => {
            if (!arreaCentres.id && response.data && response.data.id) {
              arreaCentres = response.data;
            }
            return arreaCentres;
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      saveAreaCenters() {
        this.areaCenters.forEach(item => {
          if (!item.id) {
            this.saveAreas({
              area_id: item.area_id,
              center_id: this.center.id
            });
          }
        });
      },
      getCenter(id) {
        let api = this.$store.state.api + "centers/" + id;
        axios
          .get(api)
          .then((response) => {
            this.center = response.data.data[0];
            this.getAreasCenter(this.center.id);
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getAreas() {
        let api = this.$store.state.api + "areas?paginated=false";
        axios
          .get(api)
          .then((response) => {
            this.areas = response.data.data;
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getAreasCenter(id) {
        let api = this.$store.state.api + "centers/areas/" + id;
        axios
          .get(api)
          .then((response) => {
            this.areaCenters = response.data.map(areaCenter => {
              return {
                name: areaCenter?.area?.name,
                ...areaCenter,
              };
            });
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getCompanies(id) {
        let api = this.$store.state.api + "companies/list/" + id;
        axios
          .get(api)
          .then((response) => {
            this.companies = response.data;
          })
          .catch((error) => {
            this.$error(error);
          });
      },
    },
    mounted() {
      let id = this.$route.params.id;
      this.getAreas();
      this.getCompanies(this.$store.state.user.company_id);
      if (id) {
        this.getCenter(id);
      }
    },
  };
  </script>
  
  <style scoped>
  .profile_user {
    text-align: center;
  }
  
  .photo {
    display: block;
    margin-left: 5px;
    width: 18%;
    border-radius: 10px;
    max-width: 400px;
    max-height: 400px;
    object-fit: cover;
  }
  
  .btn-user:hover,
  .btn-user[aria-expanded="true"] {
    background: linear-gradient(to right, #000, #666);
    color: #fff !important;
  }
  
  .photo-title {
    color: #000;
    font-size: 18px;
    display: block;
    margin-left: 5px;
    width: 18%;
  }
  </style>
  