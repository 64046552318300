var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationObserver',{ref:"formObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validate = ref.validate;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(validate)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Name"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Name'))+": ")]),_c('b-form-input',{attrs:{"id":"Name","type":"text","autocomplete":"new-password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.gas_station.name),callback:function ($$v) {_vm.$set(_vm.gas_station, "name", $$v)},expression:"gas_station.name"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"FantasyName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"FantasyName"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Fantasy name'))+": ")]),_c('b-form-input',{attrs:{"id":"FantasyName","type":"text","autocomplete":"new-password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.gas_station.fantasy_name),callback:function ($$v) {_vm.$set(_vm.gas_station, "fantasy_name", $$v)},expression:"gas_station.fantasy_name"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"CNPJ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"CNPJ"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('National Register of Legal Entities(CNPJ)'))+": ")]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],attrs:{"id":"CNPJ","type":"text","autocomplete":"new-password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.gas_station.cnpj),callback:function ($$v) {_vm.$set(_vm.gas_station, "cnpj", $$v)},expression:"gas_station.cnpj"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Contact","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"contact"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Name the person responsible'))+": ")]),_c('b-form-input',{attrs:{"id":"contact","type":"text","autocomplete":"new-password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.gas_station.contact),callback:function ($$v) {_vm.$set(_vm.gas_station, "contact", $$v)},expression:"gas_station.contact"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"phone"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Telephone'))+": ")]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(99) 99999-9999','(99) 9999-9999']),expression:"['(99) 99999-9999','(99) 9999-9999']"}],attrs:{"id":"phone","type":"text","autocomplete":"new-password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.gas_station.phone),callback:function ($$v) {_vm.$set(_vm.gas_station, "phone", $$v)},expression:"gas_station.phone"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"email"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Email'))+": ")]),_c('b-form-input',{attrs:{"id":"email","type":"email","autocomplete":"new-password","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.gas_station.email),callback:function ($$v) {_vm.$set(_vm.gas_station, "email", $$v)},expression:"gas_station.email"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"address"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Address'))+": ")]),_c('AddressAutocomplete',{class:{ 'is-invalid': errors.length },attrs:{"autocompleteValueProp":_vm.gas_station.address},on:{"placeSelected":_vm.onPlaceSelected,"placeChange":function (event){_vm.gas_station.address = event}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-2"},[_c('ValidationProvider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"status"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Status'))+": ")]),_c('b-form-select',{attrs:{"id":"status","state":errors.length ? false : null},model:{value:(_vm.gas_station.status),callback:function ($$v) {_vm.$set(_vm.gas_station, "status", $$v)},expression:"gas_station.status"}},[_c('option',{domProps:{"value":1}},[_vm._v(" "+_vm._s(_vm.$t('Active'))+" ")]),_c('option',{domProps:{"value":0}},[_vm._v(" "+_vm._s(_vm.$t('Inactive'))+" ")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length}},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)]),_c('span',{staticClass:"required_fields"},[_c('span',{staticClass:"required"},[_vm._v("*")]),_c('strong',[_vm._v(_vm._s(_vm.$t('Required Fields')))])]),_c('div',{staticClass:"block text-right"},[_c('router-link',{staticClass:"btn btn-back",attrs:{"to":"/gasstations"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t('To Go Back'))+" ")]),_c('b-button',{staticClass:"ml-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" "),_c('i',{staticClass:"fa fa-save"})])],1)])]}}])})],1)])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }