import Crud from '../views/VehicleBrand/Crud'
import Entity from '../views/VehicleBrand/Add'

let routes = {
    path: '/vehicle_brands',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/vehicle_brands/add',
            name: 'NewVehicleBrand',
            component: Entity,
            meta: {
                pageTitle: 'Vehicle Brand',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/vehicle_brands'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/vehicle_brands/edit/:id?',
            name: 'EditVehicleBrand',
            component: Entity,
            meta: {
                pageTitle: 'Vehicle Brand',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/vehicle_brands'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/vehicle_brands',
            name: 'list-vehicle_brands',
            component: Crud,
            meta: {
                pageTitle: 'Vehicle Brands',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
