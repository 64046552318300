import Crud from '../views/Insurance/Crud'
import Entity from '../views/Insurance/Add'

let routes = {
    path: '/insurances',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/insurances/add',
            name: 'NewInsurance',
            component: Entity,
            meta: {
                pageTitle: 'Insurer',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/insurances'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/insurances/edit/:id?',
            name: 'EditInsurance',
            component: Entity,
            meta: {
                pageTitle: 'Insurer',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/insurances'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/insurances',
            name: 'list-insurances',
            component: Crud,
            meta: {
                pageTitle: 'Insurers',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
