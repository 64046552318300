<template>
<div class="main-content">

    <div class="row text-no-wrap" v-if="!modalShow">

        <p>
            <span class="text-no-wrap  pl-1 font-weight-bold text-primary">{{$t('Registrations')}} : </span>
            <span class="badge bg-danger  text-no-wrap mb-1">{{items.length}} </span>
        </p>
        <div class="col-md-1">
            <a class="btn btn-light btn-sm pl-n2" data-toggle="tooltip" @click="filterShow = !filterShow" data-placement="bottom" :title="$t('Filter')">
                <i class="fa fa-filter"></i>
                <i v-if="!filterShow" class="fa fa-plus"></i>
                <i v-else class="fa fa-minus"></i>

            </a>

        </div>

        <div class="col-md-9 " v-if="filterShow">

            <div class="row text-no-wrap  pb-1">
                <!-- Agencia -->

                <v-select :options="centers" :reduce="(centers) => centers.id" v-model="filter.center_id" :label="'name'" class=" col-md-5" :placeholder="$t('Central')" v-on:input="getUnitsCenter" />
                <!-- unidade -->
                <v-select :options="filteredVehicleUnits" :reduce="(units) => units.id" v-model="filter.unit_id" :label="'name'" class=" col-md-7" :placeholder="$t('Unit')" />

            </div>
            <div class="row text-no-wrap pb-1">
                <!-- Apelido de vieculo -->
                <v-select :options="vehicle" :reduce="(vehicle) => vehicle.id" v-model="filter.vehicl_id" :label="'name'" class=" col-md-4" :placeholder="$t('Surname')" />
                <!-- Tipo -->
                <v-select :options="vehicle_types" :reduce="(vehicle_types) => vehicle_types.id" v-model="filter.vehicle_type_id" :label="'sigla'" class=" col-md-4 " :placeholder="$t('Type')" />
                <!-- Status -->
                <v-select class=" col-md-4" v-model="filter.status" :options="status" :label="'name'" :placeholder="$t('Status')" />
            </div>
        </div>

    </div>
    <div class="row" v-if="!modalShow">
        <div v-if="items.length" class="col-md-4 bg-light border border-white border-top-0 border-left-0 rounded fs-6 pr-1 rounded-lg " v-for="(item, index) in items" :key="index">

            <time-line :item="item" @handleSelectItem="handleSelectItem" />

        </div>
    </div>
    <time-item v-else :item="itemseleted" @handleCloseItem="handleCloseItem">
    </time-item>
    <MapPositionPoint v-if="modalShow " :centerProp="center" :ray="0.2" :zoom="16"  :areaTypeId="1" :polygonCoordinates="[]"  :url="imgUrl"   />
</div>
</template>

<script>
import dados from '../../../mock/OperationalPanel1.json'

import TimeLine from './components/TimeLineHorizontal'
import SelectItem from './components/SelectItem'
import TimeItem from './components/TimeItem'
import MapPositionPoint from "../../layouts/Mapa/MapPositionPoint";

const initialSrcImage ={
    'VIR': require("@/assets/images/resgate/ambulanceVIR.png"),
    'USA': require("@/assets/images/resgate/ambulanceUSA.png"),
    'USB': require("@/assets/images/resgate/ambulanceUSB.png"),
    'USI': require("@/assets/images/resgate/ambulanceUSI.png"),
    'VT': require("@/assets/images/resgate/ambulanceVT.png"),
    'MOT': require("@/assets/images/resgate/ambulanceMOT.png"),
    'AV': require("@/assets/images/resgate/aviao.png"),
    'HEL': require("@/assets/images/resgate/ambulanceH.png"),
    'DES': require("@/assets/images/resgate/ambulanceM.png"),
    'LAN': require("@/assets/images/resgate/ambulanceM.png"),
    'TNT': require("@/assets/images/resgate/ambulanceM.png"),
    
}
export default {
    data: function () {
        return {
            modalShow: false,
            filterShow: false,
            dados,
            itemseleted: {},
            imageIcon: '',
            centerSelected:{},
            items: [],
            vehicle: [],
            vehicle_types: [],
            centers: [],
            units: [],
            status: [{
                    name: this.$t('Courtyard'),
                    value: 'patio'
                }, {
                    name: this.$t('Free'),
                    value: 'livre'
                },
                {
                    name: this.$t('Under maintenance'),
                    value: 'Em manutenção'
                }, {
                    name: this.$t('Occurrence'),
                    value: 'ocorrencia'
                }
            ],
            filter: {
                center_id: '',
                unit_id: '',
                vehicl_id: '',
                vehicle_type_id: '',
                status: '',

            }
        }

    },
    components: {
        TimeLine,
        SelectItem,
        TimeItem,
        MapPositionPoint,
    },
    computed: {
        filteredVehicleUnits: function () {
            let newListUnits = !this.filter.center_id ? [] : this.units.filter(models => models.center_id === this.filter.center_id)
            this.filter.unit_id = newListUnits.length ? newListUnits[0].id : ''
            return newListUnits
        },
        filtredList: function () {
            let newList = !this.filter.vehicl_id ? this.items : this.items.filter(models => models.veiculo_numero.includes(trim(this.filter.vehicl_id)))
            return newList
        },
        center() {
            return this.centerSelected;
        },
        imgUrl() {
            return this.itemseleted ? initialSrcImage[this.itemseleted.especialidade_veiculo_sigla]: '';
        }
    },
    methods: {
        handleCloseItem() {
            this.modalShow = false
            this.centerSelected = null
        },
        readFileJson() {
            this.items = this.dados.data
        },
        getVehicleTypes: function () {
            const self = this
            const api = self.$store.state.api + 'vehicle_types/'

            axios
                .get(api)
                .then((response) => {
                    self.vehicle_types = response.data.data
                })
                .catch((error) => {
                    self.$message(null, error.response.data, 'error')
                })
        },
        getCenters: function () {
            const self = this
            const api = self.$store.state.api + 'centers/'

            axios
                .get(api)
                .then((response) => {
                    self.centers = response.data.data
                })
                .catch((error) => {
                    self.$message(null, error.response.data, 'error')
                })
        },
        getUnitsCenter: function () {
            const self = this
            // const api = self.$store.state.api + "units?center_id=" + self.vehicle.center_id; Precisar corrigir no API para aceitar parametros 
            const api = self.$store.state.api + 'units'

            axios
                .get(api)
                .then((response) => {
                    self.units = response.data.data
                })
                .catch((error) => {
                    self.$message(null, error.response.data, 'error')
                })
        },
        getVehicle: function () {
            const self = this
            const api = self.$store.state.api + 'vehicles'

            axios
                .get(api)
                .then((response) => {
                    self.vehicle = response.data.data
                })
                .catch((error) => {
                    self.$message(null, error.response.data, 'error')
                })
        },
        handleSelectItem(item) {
            this.itemseleted = null
            this.modalShow = false
            if (item) {
                this.itemseleted = item
            if (item.extcoordenada) {
                const extcoordenada = item.extcoordenada.split(' ');
              
                this.centerSelected = {
                    lat: Number(extcoordenada[1]),
                    lng: Number(extcoordenada[0])
                }


            }
                this.modalShow = true
                return
            }
        }

    },
    mounted: function () {

        this.readFileJson()
        this.getVehicleTypes()
        this.getCenters()
        this.getUnitsCenter()
        this.getVehicle()

    },
}
</script>
