<template>
<div class="bg-secondary" style="width:60px; max-height:15px; margin-top:35px; margin-right:-28px; margin-left:-23px;"></div>

</template>

<script>
export default {

}
</script>

<style scoped>
</style>
