

import Crud from '../views/Sensors/Crud'
import Entity from '../views/Sensors/Add'

const routes = {
    path: '/sensors',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/sensors/add',
            name: 'NewSensor',
            component: Entity,
            meta: {
                pageTitle: 'Sensors',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to: '/sensors',
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/sensors/edit/:id?',
            name: 'EditSensor',
            component: Entity,
            meta: {
                pageTitle: 'Sensors',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to: '/sensors',
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/sensors',
            name: 'list-Sensors',
            component: Crud,
            meta: {
                pageTitle: 'Sensors',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
