<template>
<div class="row">
    <div class="col-md-12">
        <base-crud :ref="'chipsCrud'" :crudName="$t('Chips')" :newText="$t('Add')+ '  '+$t('New')" :table="table" :columns="columns" :options="options" :endPoint="endPoint" :enableAdd="true" :enableEdit="true" :enableDelete="true" :enableView="false" :enableReset="false" :dataForm="dataForm">
        </base-crud>
    </div>
</div>
</template>

    
    
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
    data: function () {
        return {
            table: "chipsTable",
            showMessageImport: false,
            chipsCrud: {},
            pages: [],
            url: "",
            columns: [
                "id",
                "telephone",
                "operator_id",
                "iccid",
                "actions",
            ],
            tableData: ["id", "telephone"],
            optionsOrigin: {
                filterByColumn: true,
                debounce: 1000,
                filterable: [ "telephone","operator_id"],
                pagination: {
                    chunk: 10,
                    dropdown: false,
                    nav: "scroll"
                },
                perPage: 10,
                perPageValues: [10, 25, 50, 100],
                headings: {
                    id: this.$t('id'),
                    telephone: this.$t('Telephone'),
                    operator_id: this.$t('Operator'),
                    iccid: this.$t('Integrated Circuit Card Identifier'),
                    actions: this.$t('action'),
                },

                texts: {
                    filterBy: this.$t('Filter'),
                    defaultOption: this.$t('Select'),
                },
                listColumns: {

                },
                templates: {
                    operator_id: function (h, row, index) {
                        return row.operator ? row.operator.name : ""
                    },
                },
                requestFunction: function (data) {
                    let requestData = {};

                   

                    let query = this.$parent.$parent.$parent.query(data.query);
                    requestData.params = data;
                    requestData.params.query = "";
                    requestData.params.with = ["operator"];

                    return axios.get(this.url + "?" + query, requestData).catch(
                        function (e) {
                            this.dispatch("error", e);
                        }.bind(this)
                    );
                },
            },
            endPoint: "chips",
            dataForm: {},
        };
    },
    components: {
        BaseCrud,
    },
    computed: {
        options() {
            const headings = {
                id: this.$t('id'),
                telephone: this.$t('Telephone'),
                operator_id: this.$t('Operator'),
                iccid: this.$t('Integrated Circuit Card Identifier'),
                actions: this.$t('action'),
            }

            const texts = {
                filterBy: this.$t('Filter'),
                defaultOption: this.$t('Select'),
            }
            return {
                ...this.optionsOrigin,
                texts,
                headings,
            }

        }

    },
    methods: {
        query: function (query) {
            let columns = {
                id: "id",
                telephone: "telephone",
                operator_id: "operator_id",
                iccid: "iccid",
            };
            let filters = "";
            $.each(query, function (index, value) {
                filters += columns[index] + "=" + value + "&";
            });
            return filters;
        },

    },
};
</script>
    
    
<style scoped>
.VuePagination {
    text-align: center;
}
</style>
