<template>
<div class="card bg-light">

    <div class="card-body  row">

         <div class="col-md-4"> {{ $t('Fine type') }} : {{ props.fineType }} </div>
         <div class="col-md-4"> {{ $t('Infraction date') }} : {{ formtaDateTime(props.infraction_date) }} </div>
         <div class="col-md-4"> {{ $t('Transfer date') }} : {{ formtaDate(props.transfer_date) }} </div>
         
         <div class="col-md-4"> {{ $t('Professional') }} : {{ props.professional }} </div>
         <div class="col-md-4"> {{ $t('Notification date') }} : {{ formtaDateTime(props.notification_date) }} </div>
         <div class="col-md-4"> {{ $t('Due date') }} : {{ formtaDate(props.due_date) }} </div>
         
         <div class="col-md-4"> {{ $t('Description') }} : {{ props.description }} </div>

    </div>
    <div class="card-footer">
        <div class="form-group col-md-12">
            <b-button v-if="props.file_url" pill variant="outline-primary" :href="props.file_url" download target="_blank">
                {{ $t('Fine')}}  <i class="fa fa-download"></i>
            </b-button>
        </div>

    </div>
</div>
</template>

<script>
export default {
    props: {
        props: {
            type: Object,
            required: true,
        }

    },
    methods:{
        formtaDate(date) {
            if(date){
                return moment(date).format('DD/MM/YYYY ')

            }
            return ''

        },
        formtaDateTime(date) {
            if(date){
                return moment(date).format('DD/MM/YYYY HH:mm:ss')

            }
            return ''

        }
    }
}
</script>

    <!-- "infraction_date": "2024-04-23 00:00:00",
    "notification_date": "2024-04-23 00:00:00",
    "transfer_date": "2024-04-26",
    "due_date": "2024-04-29",
    "description": "TEST",
    "fineType": "Avançou sinal vermelho",
    "professional": "Luan de Camargo",
    "file_name": "Captura de tela 2023-12-29 150514.png",
    "file_url": "http://127.0.0.1:8989/uploads/LxK1nbPXYAirjCbrghZz_Captura de tela 2023-12-29 150514.png" -->

    <!-- infraction_date: {
        type: String,
        default: '',
    },
    notification_date: {
        type: String,
        default: '',
    },
    transfer_date: {
        type: String,
        default: '',
    },
    due_date: {
        type: String,
        default: '',
    },
    description: {
        type: String,
        default: '',
    },
    fineType: {
        type: String,
        default: '',
    },
    professional: {
        type: String,
        default: '',
    },
    file_name: {
        type: String,
        default: '',
    },
    file_url: {
        type: String,
        default: '',
    }, -->
