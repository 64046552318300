<template>
<div class="form-row">
    <div class="form-group col-md-12 pl-2 pr-2" v-if="centerProp">
        <div class="text-center m-1">
            <b-button size="sm" @click="init" variant="outline-info" class="rounded-circle p-1" v-b-tooltip.hover :title=" $t('Search')" >
                <refresh-ccw-icon size="1.5x" class="custom-class"></refresh-ccw-icon>

          </b-button>

        </div>
        <GmapMap @click="mapClicked" :center="center" :zoom="getZoom" style="width: 100%; height: 500px"  @zoom_changed="handleZoomChanged" >
            <GmapMarker :position="markerPosition" @dragend="markerDragged" draggable :icon="srcIcon" />
            <GmapMarker v-if="!isCircl" v-for="(position, index) in polygonCoordinatesNow" :key="index" :position="position" 
            :icon="imagePinSrc" @dragend="(event)=>markerPolygonCoordinatesChangeDragged(event,index)" draggable
            @click="markerClicked(index)"
             @dblclick="(event)=>markerPolygonCoordinatesChangeDblclick(event,index)" />
            <GmapCircle v-if="isCircl" :center="circleCenter" :radius="rayKm * 1000" :options="circleOptions" />
            <GmapPolygon v-else :paths="polygonCoordinatesNow" :options="circleOptions"></GmapPolygon>

        </GmapMap>
    </div>
    <div class="form-group col-md-12 pl-2 pr-2 text-center" v-else>
        <p class="badge badge-warning">{{ $t('location not specified')  }}</p>
    </div>
</div>
</template>

<script>
const HAS_PIN = require("@/assets/images/resgate/pin_24.png")
import { RefreshCcwIcon } from 'vue-feather-icons'

export default {
    data() {
        return {
            latitude: 0,
            longitude: 0,
            zoomData: 13,
            circleRadius: 1000, // Defina o raio do círculo em metros
            circleOptions: {
                fillColor: '#00FF00',
                fillOpacity: 0.35,
            },
            polygonCoordinatesData: [],
            indexMarker: null,

        }
    },
    props: {
        centerProp: {
            type: Object,
            default: {
                lat: 0,
                lng: 0
            }
        },
        ray: {
            type: Number,
            default: 5
        },
        url: {
            type: String,
            default: ''
        },
        areaTypeId: {
            type: Number,
            default: 1

        },
        zoom: {
            type: Number,
            default: 13

        },
        polygonCoordinates: {
            type: Array,
            default: []
        }

    },
    computed: {
        getZoom(){
            return this.zoomData;

        },
        isCircl() {
            return this.areaTypeId === 1
        },
        center() {
            return this.centerProp

        },
        srcIcon() {
            return this.url ?? null
        },
        markerPosition() {
            return this.centerProp
        },
        circleCenter() {
            return this.centerProp
        },
        rayKm() {
            return Number(this.ray) ?? 5
        },
        polygonCoordinatesNow() {
            return this.polygonCoordinatesData;
        },
        imagePinSrc() {
            return HAS_PIN;
        }

    },
    methods: {

        init() {

        this.$emit('reload', true)
        },
        handleZoomChanged(newZoom) {

        this.$emit('zoomChanged', newZoom)
        },
        mapClicked(event) {
            // this.markerPosition = {
            //     lat: event.latLng.lat(),
            //     lng: event.latLng.lng(),
            // };
            if (this.isCircl) {
                this.latitude = event.latLng.lat();
                this.longitude = event.latLng.lng();
                this.circleCenter = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                };
            }
            if (!this.isCircl) {

                this.polygonCoordinatesData.push({
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                })
                this.$emit('polygonCoordinatesChange', this.polygonCoordinatesData)
            }

        },
        markerPolygonCoordinatesChangeDragged(event, index) {

            this.polygonCoordinatesData[index] = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            }
            this.$emit('polygonCoordinatesChange', this.polygonCoordinatesData)
            this.polygonCoordinatesData = this.polygonCoordinates
            this.indexMarker = null;
        },
        markerPolygonCoordinatesChangeDblclick(event, index) {
           this.deleteMarker(index)
        },
        deleteMarker(index){
            this.polygonCoordinatesData.splice(index, 1)
            this.$emit('polygonCoordinatesChange', this.polygonCoordinatesData)
            // this.polygonCoordinatesData = this.polygonCoordinates 
            this.indexMarker = null;
        },
        markerDragged(event) {
            this.markerPosition = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            };
            this.latitude = event.latLng.lat();
            this.longitude = event.latLng.lng();
            this.circleCenter = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            };

            if (this.isCircl) {
                this.$emit('circleCenterChange', this.circleCenter);
            }
        },
        handleKeyDown(event) {
      if (event.key === 'Delete') {
        if(this.indexMarker != null) {
            this.deleteMarker(this.indexMarker)
        }
        
      }
    },
    markerClicked(index) {
      this.indexMarker = index;
    },

    },
    mounted() {
        window.addEventListener('keydown', this.handleKeyDown)
        this.polygonCoordinatesData = this.polygonCoordinates
        this.zoomData = this.zoom

    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyDown);
    },
    components: {
        RefreshCcwIcon,
  }

};
</script>
