<template>
    <div>
      <div class="main-content">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <ValidationObserver ref="formObserver" v-slot="{ invalid, validate }">
                      <b-form @submit.prevent="handleSubmit(validate)">
                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                              <b-form-group label-for="Name" :label="$t('Name')">
                                <b-form-input
                                  id="Name"
                                  type="text"
                                  v-model="area_catagory.name"
                                  autocomplete="new-password"
                                  :state="errors.length ? false : null"
                                  placeholder=""
                                />
                                <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-12">
                            <ValidationProvider name="Image"  v-slot="{ errors }">
                              <b-form-group label-for="Image" :label="$t('Image')">
                                <upload-file
                                  :fileId="area_catagory.image"
                                  :isImage="true"
                                  @uploaded="(event) => { area_catagory.image = event }"
                                  size="2rem"
                                />
                                <b-form-invalid-feedback :state="errors.length">{{ $t(errors[0]) }}</b-form-invalid-feedback>
                              </b-form-group>
                            </ValidationProvider>
                          </div>
                        </div>
                        <span class="required_fields">
                          <span class="required">*</span>
                          <strong>{{ $t('Required Fields') }}</strong>
                        </span>
                        <div class="block text-right">
                          <router-link to="/areacatagories" class="btn btn-back">
                            <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                          </router-link>
                          <b-button type="submit" variant="primary" class="ml-1">
                            {{ $t('Save') }}
                            <i class="fa fa-save"></i>
                          </b-button>
                        </div>
                      </b-form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import UploadFile from "../../layouts/components/UploadFile";
  import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  import { BButton, BForm, BFormInput, BFormGroup, BFormInvalidFeedback } from "bootstrap-vue";
  
  // Add the rules to vee-validate
  extend("required", required);
  
  export default {
    data() {
      return {
        area_catagory: {
          name: "",
          image: null
        },
        errors: undefined,
        previewImage: null,
      };
    },
    methods: {
      async handleSubmit(validate) {
      const isValid = await validate();
      if (isValid) {
        this.save();
      }
    },
      save() {
        let api = this.$store.state.api + "areacatagories";
        if (this.area_catagory.id) {
          this.area_catagory._method = "PUT";
          api += "/" + this.area_catagory.id;
        }
  
        axios
          .post(api, this.area_catagory)
          .then(() => {
            this.$message(
              this.$t('Success'),
              this.$t('Information saved successfully'),
              "success"
            );
            this.$router.push("/areacatagories");
          })
          .catch((error) => {
            this.$error(error);
          });
      },
      getAreaeType(id) {
        let api = this.$store.state.api + "areacatagories/" + id;
        axios
          .get(api)
          .then((response) => {
            this.area_catagory = response.data.data[0];
          })
          .catch((error) => {
            this.$error(error);
          });
      },
    },
    mounted() {
      const id = this.$route.params.id;
      if (id) {
        this.getAreaeType(id);
      }
    },
    components: {
      UploadFile,
      ValidationObserver,
      ValidationProvider,
      BButton,
      BForm,
      BFormInput,
      BFormGroup,
      BFormInvalidFeedback,
    },
  };
  </script>
  
  <style scoped>
  </style>
  