import Vue from 'vue'
import VueRouter from 'vue-router'

import User from './user'
import Center from './center'
import Unit from './unit'
import Profile from './profile'
import VehicleBrand from './vehicle_brand'
import VehicleModel from './vehicle_model'
import VehicleColor from './vehicle_color'
import VehicleType from './vehicle_type'
import FineType from './fine_type'
import ProfileType from './profile_type'
import Insurance from './insurance'
import Vehicle from './vehicle'
import Professional from './professional'
import Refueling from './refueling'
import Licensing from './licensing'
import Fine from './fine'
import AutoInsurance from './auto_insurance'
import TelephoneType from './telephone_type'
import AddressType from './address_type'
import OperationalPanel from './operational_panel'
import  Supplier from './supplier'
import  DeviceModel from './device_model'
import  Operators from './operators'
import Chips from './chips'
import CriticalityTypes from './criticalityTypes'
import EventTypes from './eventTypes'
import Sensors from './sensors'
import Devices from './devices'
import DevicesActivation from './devices_activation'
import AreaType from './area_type'
import AreaCatagories from './area_catagory'
import Areas from './areas'
import Companies from './companies'
import GasStations from'./gas_stations'
import TypeProfessionals from './type_professionals'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    User,
    Center,
    Unit,
    VehicleBrand,
    VehicleModel,
    VehicleColor,
    VehicleType,
    FineType,
    ProfileType,
    Insurance,
    Vehicle,
    Professional,
    Profile,
    Refueling,
    Licensing,
    Fine,
    AutoInsurance,
    TelephoneType,
    AddressType,
    OperationalPanel,
    Supplier,
    DeviceModel,
    Operators,
    Chips,
    CriticalityTypes,
    EventTypes,
    Sensors,
    Devices,
    DevicesActivation,
    AreaType,
    AreaCatagories,
    Areas,
    Companies,
    GasStations,
    TypeProfessionals,
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/auth-forgot-password',
      name: 'auth-forgot-password',
      component: () => import('@/views/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

let redirectedToForgotPassword = false;

router.beforeEach((to, from, next) => {
  const access_level = localStorage.getItem('access_level');
  const authenticated = localStorage.getItem('token');
  let to_splited = to.path.toString().split('/')
  to_splited = to_splited.filter(part => part !== '')

  if (
    !authenticated   
 &&
    (
      !(['/login', '/auth-forgot-password'].includes(to.path.toString())) &&
      !(['public'].includes(to_splited[0]))
    )
  ) {
    next('/login');
    return true;
  }

  if (to.path === '/auth-forgot-password' && !redirectedToForgotPassword) {
    redirectedToForgotPassword = true;
    next();
  } else {
    redirectedToForgotPassword = false;
  }

  if (to.meta.access_level) {
    if (!to.meta.access_level.includes(access_level)) {
      next('/');
    }
  }

  next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
