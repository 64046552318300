<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <base-crud :ref="'devicesActivationCrud'" :crudName="$t('Devices Activation')" :newText="$t('Add')+ '  '+$t('New')" :table="table" :columns="columns" :options="options" :endPoint="endPoint" :enableAdd="true" :enableEdit="true" :enableDelete="true" :enableView="false" :enableReset="false" :dataForm="dataForm">
                </base-crud>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import BaseCrud from "../../layouts/Base/BaseCrud";
    
    export default {
        data: function () {
            return {
                table: "devicesActivationTable",
                showMessageImport: false,
                devicesActivationCrud: {},
                pages: [],
                url: "",
                columns: [
                    "id",
                    "unit_id",
                    "device_id",
                    "vehicle_id",
                    "status",
                    "actions",
                ],
                tableData: ["id",
                    "unit_id",
                    "device_id",
                    "vehicle_id",
                    "status",],
                optionsOrigin: {
                    filterByColumn: true,
                    debounce: 1000,
                    filterable: ["unit_id","device_id","vehicle_id"],
                    pagination: {
                        chunk: 10,
                        dropdown: false,
                        nav: "scroll"
                    },
                    perPage: 10,
                    perPageValues: [10, 25, 50, 100],
                    headings: {
                        id: this.$t('id'),
                        unit_id: this.$t('Unit'),
                        device_id: this.$t('Device'),
                        vehicle_id: this.$t('Vehicle'),
                        status: this.$t('Status'),
                        actions: this.$t('action'),
                    },
    
                    texts: {
                        filterBy: this.$t('Filter'),
                        defaultOption: this.$t('Select'),
                    },
                    listColumns: {
    
                    },
                    templates: {
                        unit_id: function (h, row, index) {
                            return row.unit?.name?? ''
                        },
                        device_id: function (h, row, index) {
                            return row.device?.serial_number?? ''
                        },
                        vehicle_id: function (h, row, index) {
                            return row.vehicle?.name?? ''
                        },
                    },
                    requestFunction: function (data) {
                        let requestData = {};
    
                       
    
                        let query = this.$parent.$parent.$parent.query(data.query);
                        requestData.params = data;
                        requestData.params.query = "";
                        requestData.params.with = ["unit","device","vehicle"];
    
                        return axios.get(this.url + "?" + query, requestData).catch(
                            function (e) {
                                this.dispatch("error", e);
                            }.bind(this)
                        );
                    },
                },
                endPoint: "deviceactivations",
                dataForm: {},
            };
        },
        components: {
            BaseCrud,
        },
        computed: {
            options() {
                const headings = {
                        id: this.$t('id'),
                        unit_id: this.$t('Unit'),
                        device_id: this.$t('Device'),
                        vehicle_id: this.$t('Vehicle'),
                        status: this.$t('Status'),
                        actions: this.$t('action'),
                }
    
                const texts = {
                    filterBy: this.$t('Filter'),
                    defaultOption: this.$t('Select'),
                }
                return {
                    ...this.optionsOrigin,
                    texts,
                    headings,
                }
    
            }
    
        },
        methods: {
    
            query: function (query) {
                let columns = {
                    id: "id",
                    name: "name",
    
                };
                let filters = "";
                $.each(query, function (index, value) {
                    filters += columns[index] + "=" + value + "&";
                });
                return filters;
            },
    
        },
    };
    </script>
    
    <style scoped>
    .VuePagination {
        text-align: center;
    }
    
    .uploading-image {
        display: flex;
        border-radius: 10px;
        width: 100px;
        height: 100px;
    }
    </style>
    