var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationObserver',{ref:"formObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(validate)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Central","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Central"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Central'))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.centers,"reduce":function (center) { return center.id; },"label":'name'},on:{"input":_vm.changetUnitsCenter},model:{value:(_vm.device.center_id),callback:function ($$v) {_vm.$set(_vm.device, "center_id", $$v)},expression:"device.center_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Unit"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Unit'))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.filteredDeviceUnits,"reduce":function (unit) { return unit.id; },"label":'name'},model:{value:(_vm.device.unit_id),callback:function ($$v) {_vm.$set(_vm.device, "unit_id", $$v)},expression:"device.unit_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Supplier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Supplier"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Supplier'))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.suppliers,"reduce":function (supplier) { return supplier.id; },"label":'name'},model:{value:(_vm.device.supplier_id),callback:function ($$v) {_vm.$set(_vm.device, "supplier_id", $$v)},expression:"device.supplier_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Device Model","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Devices-Model"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Devices Model'))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.filteredDeviceModels,"reduce":function (deviceModel) { return deviceModel.id; },"label":'name'},model:{value:(_vm.device.device_model_id),callback:function ($$v) {_vm.$set(_vm.device, "device_model_id", $$v)},expression:"device.device_model_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group input-group-sm col-md-6"},[_c('ValidationProvider',{attrs:{"name":"IMEI","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"operator"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('IMEI'))+": ")]),_c('b-form-input',{attrs:{"type":"text","id":"imei","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.device.imei),callback:function ($$v) {_vm.$set(_vm.device, "imei", $$v)},expression:"device.imei"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"Chip"}},[_vm._v(" "+_vm._s(_vm.$t('Chip'))+": ")]),_c('v-select',{staticClass:"vselect",attrs:{"options":_vm.chips,"reduce":function (chip) { return chip.id; },"label":'iccid'},model:{value:(_vm.device.chip_id),callback:function ($$v) {_vm.$set(_vm.device, "chip_id", $$v)},expression:"device.chip_id"}})],1),_c('div',{staticClass:"form-group input-group-sm col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Firmware","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Firmware"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Firmware'))+": ")]),_c('b-form-input',{attrs:{"type":"text","id":"firmware","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.device.firmware),callback:function ($$v) {_vm.$set(_vm.device, "firmware", $$v)},expression:"device.firmware"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group input-group-sm col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Serial Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"SerialNumber"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Serial Number'))+": ")]),_c('b-form-input',{attrs:{"type":"text","id":"serial_number","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.device.serial_number),callback:function ($$v) {_vm.$set(_vm.device, "serial_number", $$v)},expression:"device.serial_number"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group input-group-sm col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Acquisition Date","rules":"required|date_format"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Acquisition"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('acquisition date'))+": ")]),_c('b-form-input',{attrs:{"type":"date","id":"acquisition_date","state":errors.length ? false : null,"placeholder":""},model:{value:(_vm.device.acquisition_date),callback:function ($$v) {_vm.$set(_vm.device, "acquisition_date", $$v)},expression:"device.acquisition_date"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group input-group-sm col-md-12"},[_c('b-form-group',{attrs:{"label":_vm.$t('Description')}},[_c('b-form-input',{attrs:{"type":"text","id":"description","placeholder":""},model:{value:(_vm.device.description),callback:function ($$v) {_vm.$set(_vm.device, "description", $$v)},expression:"device.description"}})],1)],1)]),_c('span',{staticClass:"required_fields"},[_c('span',{staticClass:"required"},[_vm._v("*")]),_c('strong',[_vm._v(" "+_vm._s(_vm.$t('Required Fields')))])]),_c('div',{staticClass:"block text-right"},[_c('router-link',{staticClass:"btn btn-back",attrs:{"to":"/devices"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t('To Go Back'))+" ")]),_c('b-button',{staticClass:"ml-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" "),_c('i',{staticClass:"fa fa-save"})])],1)])]}}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }