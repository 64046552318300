<template>
<div>
    <!-- v-model="address" -->
    <gmap-autocomplete :options="autocompleteOptions" @place_changed="onPlaceChanged" :placeholder="$t(placeholder)" v-model="autocompleteValueProp" :autocomplete="'new-password'" @input="handleInput"  class="form-control" ></gmap-autocomplete>

</div>
</template>

<script>
export default {
    data() {
        return {
            address: '',
            placeEvent: {},
            autocompleteOptions: {

            },
            addressSystem: {
                address: '',
                address_geometry: '',
                country: {
                    name: '',
                    sigla: '',
                },
                state: {
                    name: '',
                    sigla: '',
                    country_id: '',
                },
                municipality: {
                    name: '',
                    code: '',
                    state_id: '',
                },
                neighborhood: {
                    name: '',
                    code: '',
                    state_id: '',
                    municipality_id: '',
                },
            },
        };
    },
    props: {
        placeholder: {
            type: String,
            default: 'Enter an address',
        },
        autocompleteValueProp: {
            type: String,
            default: '',
        },
    },
    computed: {
      

    },
    methods: {
        async getData(controller, data) {
        if (!data.name) {
            return data;
        }
        
        try {
            const api = `${this.$store.state.api}${controller}/name/${data.name}`;
            const response = await axios.get(api);
            
            if (response.data.data.length > 0) {
                return response.data.data[0];
            }
        } catch (error) {
                    this.$error(error);
        }
        
        return null;
    },
    async save(controller, data) {
        let newData = await this.getData(controller, data);
        
        if (newData) {
            return newData;
        }
        
        try {
            const api = `${this.$store.state.api}${controller}`;
            const response = await axios.post(api, data);
            
            newData = response.data;
        } catch (error) {
                    this.$error(error);
        }
        
        return newData ? newData.id : null;
    },
    async saveCountry() {
        this.addressSystem.state.country_id = (await this.save('countries', this.addressSystem.country))?.id ?? 0;
        await this.saveState();
    },
    async saveState() {
        this.addressSystem.municipality.state_id = (await this.save('states', this.addressSystem.state))?.id ?? 0;
        this.addressSystem.neighborhood.state_id = this.addressSystem.municipality.state_id;
        await this.saveMunicipality();
    },
    async saveMunicipality() {
       
        this.addressSystem.neighborhood.municipality_id = (await this.save('municipalities', this.addressSystem.municipality))?.id ?? 0;
        await this.saveNeighborhood();
     
    
    },
    async saveNeighborhood() {
        this.placeEvent.address_components.forEach(component => {
            let types = component.types;
            if (types.includes('neighborhood')) {
                this.addressSystem.neighborhood.name = component.long_name;
                this.addressSystem.neighborhood.code = component.short_name;
            }
        });
        this.addressSystem.neighborhood.id = (await this.save('neighborhoods', this.addressSystem.neighborhood))?.id ?? 0;
        this.$emit('placeSelected', this.addressSystem);
    },
    handleInput(event) {
        this.address = event.target.value;
        // Perform any additional logic based on input changes (optional)
        this.$emit('placeChange', event.target.value);
    },
    onPlaceChanged(place) {
        // Este método é chamado quando um lugar é selecionado no autocompletar
            if (place.formatted_address && 
            this.placeEvent.formatted_address && 
            place.formatted_address == this.placeEvent.formatted_address 
            ) {
                return this.$emit('placeSelected', this.addressSystem);
            }
         this.placeEvent = place;
            this.addressSystem.address = place.formatted_address;
            const geometry = place.geometry.location
            if (geometry){

                this.addressSystem.address_geometry =  {
                    lat: geometry.lat(),
                    lng: geometry.lng()
                }
            }

            place.address_components.forEach(component => {
                let types = component.types;

                if (types.includes('country')) {
                    this.addressSystem.country.name = component.long_name;
                    this.addressSystem.country.sigla = component.short_name;
                } else if (types.includes('administrative_area_level_1')) {
                    this.addressSystem.state.name = component.long_name;
                    this.addressSystem.state.sigla = component.short_name;
                } else if (types.includes('administrative_area_level_2')) {
                    this.addressSystem.municipality.name = component.long_name;
                    this.addressSystem.municipality.code = component.short_name;
                } else if (types.includes('sublocality_level_1')) {
                    this.addressSystem.neighborhood.name = component.long_name;
                    this.addressSystem.neighborhood.code = component.short_name;
                }
            });

            this.saveCountry();
        },
        onMounted() {
            this.address = this.autocompleteValueProp
        

        },
    },
    mounted: function () {
      this.onMounted();
    }
};
</script>

<style scoped>
/* Estilos opcionais do componente */
</style>
