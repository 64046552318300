
import Crud from '../views/Devices/Crud'
import Entity from '../views/Devices/Add'

const routes = {
    path: '/devices',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/devices/add',
            name: 'Newdevices',
            component: Entity,
            meta: {
                pageTitle: 'Device',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/devices'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/devices/edit/:id?',
            name: 'Editdevices',
            component: Entity,
            meta: {
                pageTitle: 'Device',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/devices'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/devices',
            name: 'list-devices',
            component: Crud,
            meta: {
                pageTitle: 'Devices',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
